import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';

// Function to interpolate between two colors
const lerpColor = (color1, color2, factor) => {
  const parseColor = (color) => color.match(/\w\w/g).map((c) => parseInt(c, 16));
  const colorToHex = (color) => `#${color.map((c) => c.toString(16).padStart(2, '0')).join('')}`;

  const c1 = parseColor(color1);
  const c2 = parseColor(color2);
  const result = c1.map((c, i) => Math.round(c + (c2[i] - c) * factor));
  return colorToHex(result);
};

// Function to normalize a value within a given range
const normalize = (value, min, max) => (max - min !== 0 ? (value - min) / (max - min) : 0);

// Function to format date range as MM/DD - MM/DD
const formatDateRange = (dateRange) => {
  const [startDate, endDate] = dateRange.split(' - ');
  const options = { month: '2-digit', day: '2-digit' };
  const formattedStartDate = new Date(startDate).toLocaleDateString('en-US', options);
  const formattedEndDate = new Date(endDate).toLocaleDateString('en-US', options);
  return `${formattedStartDate} - ${formattedEndDate}`;
};

const WeeklyStatsTable = () => {
  const [weeklyStats, setWeeklyStats] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [codes, setCodes] = useState([]);
  const [newCode, setNewCode] = useState('');

  const fetchWeeklyStats = async (codes) => {
    try {
      const response = await axios.get('/api/weekly-stats', {
        params: { codes: codes.join(',') }
      });
      console.log('Data from backend:', response.data); // Log the data from the backend
      const sortedStats = response.data.sort((a, b) => b.week - a.week);
      setWeeklyStats(sortedStats);
    } catch (error) {
      console.error('Error fetching weekly stats:', error);
    }
  };

  useEffect(() => {
    fetchWeeklyStats(codes);
  }, [codes]);

  useEffect(() => {
    const role = localStorage.getItem('userRole');
    setUserRole(role);
  }, []);

  const addCode = () => {
    if (newCode && !codes.includes(newCode)) {
      setCodes([...codes, newCode]);
      setNewCode('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      addCode();
    }
  };

  const removeCode = (code) => {
    setCodes(codes.filter(c => c !== code));
  };

  const colorRanges = {
    cpp: ['#FFDF80', '#FFBF00'],
    cpm: ['#D7A276', '#b35900'],
    ctr: ['#80E27E', '#4CAF50'],
    cpc: ['#B3E5FC', '#03A9F4'],
    epc: ['#CDEBAC', '#649130'],
    aov: ['#D7A276', '#b35900'],
    cr: ['#FFD180', '#cc8400'],
    spend: ['#FF9E9E', '#FF4C4C'],
    total_revenue: ['#cdebf9', '#89CFF0'],
    roas: ['#FF9980', '#FF4500'],
    profit: ['#80E27E', '#4CAF50']
  };

  const findMinMax = (key) => {
    const values = weeklyStats.map((stat) => Number(stat[key]) || 0);
    return [Math.min(...values), Math.max(...values)];
  };

  const formatCurrency = (value) => {
    const num = Number(value);
    if (isNaN(num)) {
      return 'N/A';
    }
    const formattedValue = num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    if (num < 0) {
      return `-$${formattedValue.slice(1)}`;
    }
    return `$${formattedValue}`;
  };

  const formatNumber = (value) => {
    const num = Number(value);
    return !isNaN(num) ? num.toFixed(2) : 'N/A';
  };

  const totalSpend = weeklyStats.reduce((sum, stat) => sum + Number(stat.spend), 0);
  const totalRevenue = weeklyStats.reduce((sum, stat) => sum + Number(stat.total_revenue), 0);
  const totalOrders = weeklyStats.reduce((sum, stat) => sum + Number(stat.orders_count), 0);
  const totalClicks = weeklyStats.reduce((sum, stat) => sum + Number(stat.clicks), 0);
  const totalImpressions = weeklyStats.reduce((sum, stat) => sum + Number(stat.impressions), 0);
  const totalProfit = weeklyStats.reduce((sum, stat) => sum + Number(stat.profit), 0);

  const grandTotals = {
    cpp: totalSpend / totalOrders,
    cpc: totalSpend / totalClicks,
    cpm: (totalSpend / totalImpressions) * 1000,
    ctr: (totalClicks / totalImpressions) * 100,
    epc: totalRevenue / totalClicks,
    aov: totalRevenue / totalOrders,
    cr: (totalOrders / totalClicks) * 100,
    roas: totalRevenue / totalSpend,
  };

  return (
    <div>
      <Header userRole={userRole} />
      <div className="5/6 overflow-x-auto px-4 py-6 mx-auto">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">Weekly Stats</h2>
        <div className="mb-6">
          <input
            type="text"
            value={newCode}
            onChange={(e) => setNewCode(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Enter code"
            className="px-4 py-2 border rounded mr-2"
          />
          <button onClick={addCode} className="px-4 py-2 bg-blue-500 text-white rounded">Add Code</button>
          <div className="mt-4 active-filters flex flex-wrap items-center mb-4">
            {codes.map((code, index) => (
              <div key={index} className="flex items-center px-4 py-2 rounded-full bg-gray-100 text-gray-800 space-x-2 mr-2 mb-2">
                <span>{code}</span>
                <button
                  onClick={() => removeCode(code)}
                  className="ml-2 p-1 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        </div>
        <table className="w-full bg-white text-center">
          <thead className="bg-gray-800 text-white uppercase text-sm leading-normal">
            <tr>
              <th className="px-6 py-3">Week</th>
              <th className="px-6 py-3">Date</th>
              <th className="px-6 py-3">PUR</th>
              <th className="px-6 py-3">CPP</th>
              <th className="px-6 py-3">CPM</th>
              <th className="px-6 py-3">CTR</th>
              <th className="px-6 py-3">CPC</th>
              <th className="px-6 py-3">EPC</th>
              <th className="px-6 py-3">AOV</th>
              <th className="px-6 py-3">CR</th>
              <th className="px-6 py-3">Spend</th>
              <th className="px-6 py-3">Revenue</th>
              <th className="px-6 py-3">Profit</th>
              <th className="px-6 py-3">ROAS</th>
            </tr>
          </thead>
          <tbody className="text-black text-sm">
            {weeklyStats.map((stat, index) => {
              const minMax = {};
              const backgroundColors = {};

              Object.keys(colorRanges).forEach((key) => {
                const [min, max] = findMinMax(key);
                minMax[key] = [min, max];
                const normalizedValue = normalize(stat[key], min, max);
                backgroundColors[key] = index === 0 && ['profit', 'spend', 'total_revenue'].includes(key)
                  ? 'transparent'
                  : lerpColor(colorRanges[key][0], colorRanges[key][1], normalizedValue);
              });

              return (
                <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                  <td className="px-6 py-3">{stat.week}</td>
                  <td className="px-6 py-3">{formatDateRange(stat.date)}</td>
                  <td className="px-6 py-3">{stat.orders_count ?? 'N/A'}</td>
                  <td className="px-6 py-3">{formatCurrency(stat.cpp)}</td>
                  <td className="px-6 py-3" style={{ backgroundColor: backgroundColors.cpm }}>{formatCurrency(stat.cpm)}</td>
                  <td className="px-6 py-3" style={{ backgroundColor: backgroundColors.ctr }}>{formatNumber(stat.ctr)}%</td>
                  <td className="px-6 py-3" style={{ backgroundColor: backgroundColors.cpc }}>{formatCurrency(stat.cpc)}</td>
                  <td className="px-6 py-3" style={{ backgroundColor: backgroundColors.epc }}>{formatCurrency(stat.epc)}</td>
                  <td className="px-6 py-3" style={{ backgroundColor: backgroundColors.aov }}>{formatCurrency(stat.aov)}</td>
                  <td className="px-6 py-3" style={{ backgroundColor: backgroundColors.cr }}>{formatNumber(stat.cr)}%</td>
                  <td className="px-6 py-3" style={{ backgroundColor: backgroundColors.spend }}>{formatCurrency(stat.spend)}</td>
                  <td className="px-6 py-3" style={{ backgroundColor: backgroundColors.total_revenue }}>{formatCurrency(stat.total_revenue)}</td>
                  <td className="px-6 py-3" style={{ backgroundColor: backgroundColors.profit }}>{formatCurrency(stat.profit)}</td>
                  <td className="px-6 py-3" style={{ backgroundColor: backgroundColors.roas }}>{formatNumber(stat.roas)}</td>
                </tr>
              );
            })}

            {/* Grand Total Row */}
            <tr className="bg-gray-100 font-bold">
              <td className="px-6 py-3">Grand Total</td>
              <td className="px-6 py-3"></td> {/* Blank week */}
              <td className="px-6 py-3">{totalOrders ?? 'N/A'}</td>
              <td className="px-6 py-3">{formatCurrency(grandTotals.cpp)}</td>
              <td className="px-6 py-3">{formatCurrency(grandTotals.cpm)}</td>
              <td className="px-6 py-3">{formatNumber(grandTotals.ctr)}%</td>
              <td className="px-6 py-3">{formatCurrency(grandTotals.cpc)}</td>
              <td className="px-6 py-3">{formatCurrency(grandTotals.epc)}</td>
              <td className="px-6 py-3">{formatCurrency(grandTotals.aov)}</td>
              <td className="px-6 py-3">{formatNumber(grandTotals.cr)}%</td>
              <td className="px-6 py-3">{formatCurrency(totalSpend)}</td>
              <td className="px-6 py-3">{formatCurrency(totalRevenue)}</td>
              <td className="px-6 py-3">{formatCurrency(totalProfit)}</td>
              <td className="px-6 py-3">{formatNumber(grandTotals.roas)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WeeklyStatsTable;
