import React, { useState, useEffect, useMemo, useRef } from 'react'; // Import useRef
import axios from 'axios';
import Spinner from './spinner'; // Import the Spinner component
import './fb.css'
import SummaryCardsContainer from './FacebookSalesSummary';
import DailyDataBreakdown from './DailyDataBreakdown'; // Import the new component
import AllBreakdown from './AllBreakdown';
import VendorSummary from './VendorSalesSummary';
import Offers from './Offers';

const DataTable = ({ data, title, type, activeTable, setSelectedItems, selectedItems, dailyData}) => {
  const [showAccountId, setShowAccountId] = useState(false); 
  const includeInputRef = useRef(null);
  const excludeInputRef = useRef(null);
  const [checkedRows, setCheckedRows] = useState([]);
  const [allSelectedDailyData, setAllSelectedDailyData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const filterInputRef = useRef(null);
  const [filterPresets, setFilterPresets] = useState([]);
  const [showSaveFilterModal, setShowSaveFilterModal] = useState(false);
  const [newFilterLabel, setNewFilterLabel] = useState('');
  const [filterToDelete, setFilterToDelete] = useState('');
  const [showSavedFilterDropdown, setShowSavedFilterDropdown] = useState(false); // Separate state for saved filters dropdown
  const [activeFilters, setActiveFilters] = useState([]);

  const toggleFilterOptions = () => {
    setShowFilterOptions(!showFilterOptions); // Toggle the main filter options visibility
  };

  const toggleSavedFilterDropdown = () => {
    setShowSavedFilterDropdown(!showSavedFilterDropdown); // Toggle the saved filters dropdown visibility
  };
  const [newFilter, setNewFilter] = useState({
    column: '',
    condition: '',
    value: ''
  });
  
    // Load filter presets from backend
    useEffect(() => {
      const fetchFilterPresets = async () => {
          try {
              const { data } = await axios.get('/api/load-filters');
              setFilterPresets(data);
          } catch (error) {
              console.error("Failed to load filter presets:", error);
          }
      };
  
      fetchFilterPresets();
  }, []);
  
  // Function to handle saving filters
  const handleSaveFilters = async () => {
    // Here we are preparing the filters to be saved including their relations
    const filtersWithRelations = {
      activeFilters,  // This will save all active filters along with their relations
      includeFilters, // Legacy filters to maintain backward compatibility
      excludeFilters,
      valueFilters,
      status: selectedStatus
    };
  
    try {
      const response = await axios.post('/api/save-filter', {
        label: newFilterLabel,
        filters: filtersWithRelations
      });
      setFilterPresets([...filterPresets, response.data]);
      setShowSaveFilterModal(false);  // Close the modal after saving
      setNewFilterLabel('');  // Reset the label input
    } catch (error) {
      console.error('Failed to save filter preset:', error);
    }
  };
  


const uniqueStatuses = useMemo(() => {
  const statusSet = new Set(data.map(item => item.effective_status));
  return Array.from(statusSet);
}, [data]);

const applySavedFilter = (presetId) => {
  const preset = filterPresets.find(p => p.id.toString() === presetId.toString());
  if (preset) {
    console.log("Applying preset:", preset);
    // Set all filters and relations
    setActiveFilters(preset.filters.activeFilters || []);
    setIncludeFilters(preset.filters.include || []);
    setExcludeFilters(preset.filters.exclude || []);
    setSelectedStatus(preset.filters.status || '');
    setValueFilters(preset.filters.valueFilters || []); // Ensure this key matches what's in the preset
    console.log('Filters applied:', {
      activeFilters: preset.filters.activeFilters,
      include: preset.filters.include,
      exclude: preset.filters.exclude,
      valueFilters: preset.filters.valueFilters,
      status: preset.filters.status
    });
  } else {
    console.log("Preset not found:", presetId);
  }
};


const deleteFilter = async (filterId) => {
  try {
    const response = await fetch(`/api/delete-filter/${filterId}`, {
      method: 'DELETE',
    });
    if (response.ok) {
      console.log('Filter deleted successfully');
      // Additional logic to update the UI after deletion
    } else {
      console.error('Error deleting filter');
    }
  } catch (error) {
    console.error('Error during deletion:', error);
  }
};


const [includeFilters, setIncludeFilters] = useState(
  JSON.parse(localStorage.getItem(`${type}_includeFilters`)) || []
);
const [excludeFilters, setExcludeFilters] = useState(
  JSON.parse(localStorage.getItem(`${type}_excludeFilters`)) || []
);
const [searchTerm, setSearchTerm] = useState(
  localStorage.getItem(`${type}_searchTerm`) || ''
);
const [sortConfig, setSortConfig] = useState(
  JSON.parse(localStorage.getItem(`${type}_sortConfig`)) || { key: 'total_spend', direction: 'descending' }
);
const [valueFilters, setValueFilters] = useState(
  JSON.parse(localStorage.getItem(`${type}_valueFilters`)) || []
);


const [showFilterOptions, setShowFilterOptions] = useState(
  JSON.parse(localStorage.getItem(`${type}_showFilterOptions`)) || false
);


    const isStickyColumn = (columnName) => {
        return ['campaign_name', 'adset_name', 'ad_name'].includes(columnName);
      };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const accountNames = {
      '798134188164544': '10FC_03_US',
      '489501699514603': '10FC_05_US',
      '1826497317736494': '10FC_06_US',
      '1123140815707820': '10FC_16_USD',
      '301544125879114': '10FC_17_USD',
      '302953189454101': '10FC_18_USD',
      '757236649683743': '10FC_19_AUS',
    
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };




    const isRowSelected = (row) => {
      let rowId;
      switch (type) {
        case 'ads':
          rowId = row.ad_id;
          break;
        case 'adset':
          rowId = row.adset_id;
          break;
        case 'campaign':
          rowId = row.campaign_id;
          break;
        case 'account':
          rowId = row.account_id;
          break;
        default:
          rowId = row.id;
      }
      return selectedItems.some(item => item.id === rowId && item.type === type);
};

        

const sortedAndFilteredData = useMemo(() => {
  let sortableItems = [...data];

  // Filter items based on search terms, splitting by comma or space
  if (searchTerm) {
    const searchTerms = searchTerm.split(/[, ]+/).map(term => term.trim().toLowerCase());
    sortableItems = sortableItems.filter(item => {
      return Object.values(item).some(value =>
        searchTerms.some(term => term && String(value).toLowerCase().includes(term))
      );
    });
  }

  // Sorting based on sortConfig settings
  if (sortConfig.key !== null) {
    sortableItems.sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];
      aValue = (typeof aValue === 'string' && !isNaN(aValue)) ? parseFloat(aValue) : aValue;
      bValue = (typeof bValue === 'string' && !isNaN(bValue)) ? parseFloat(bValue) : bValue;

      return aValue < bValue ? (sortConfig.direction === 'ascending' ? -1 : 1) :
        aValue > bValue ? (sortConfig.direction === 'ascending' ? 1 : -1) : 0;
    });
  }

  // Sorting to move rows with 0 total_spend to the end
  sortableItems.sort((a, b) => {
    const aValue = parseFloat(a['total_spend']);
    const bValue = parseFloat(b['total_spend']);
    if (aValue === 0 && bValue !== 0) return 1;
    if (aValue !== 0 && bValue === 0) return -1;
    return 0;
  });

  // Advanced filtering using activeFilters
  if (activeFilters.length > 0) {
    sortableItems = sortableItems.filter(item => {
      let result = true;  // Start with an assumption that the item passes
      let currentRelation = 'AND';  // Default relation

      for (let i = 0; i < activeFilters.length; i++) {
        const filter = activeFilters[i];
        let filterResult = false;  // Assume the filter condition fails by default

        switch (filter.type) {
          case 'include':
            filterResult = JSON.stringify(item).toLowerCase().includes(filter.value.toLowerCase());
            break;
          case 'exclude':
            filterResult = !JSON.stringify(item).toLowerCase().includes(filter.value.toLowerCase());
            break;
          case 'valueFilter':
            const itemValue = parseFloat(item[filter.value.column]);
            if (filter.value.condition === 'greater') {
              filterResult = itemValue > filter.value.value;
            } else if (filter.value.condition === 'smaller') {
              filterResult = itemValue < filter.value.value;
            }
            break;
          case 'status':
            filterResult = item.effective_status === filter.value;
            break;
          default:
            filterResult = false;  // Unknown filter types are ignored (or considered as failing the condition)
        }

        // Combining results based on the relation
        if (currentRelation === 'AND') {
          result = result && filterResult;
        } else if (currentRelation === 'OR') {
          result = result || filterResult;
        }

        // Update currentRelation to the next filter's relation if exists
        currentRelation = filter.relation || 'AND';
      }

      return result;  // The final result for this item after all filters
    });
  }

  return sortableItems;
}, [data, sortConfig, searchTerm, activeFilters]);  // Ensure all dependencies are listed correctly

const addActiveFilter = (filter, filterType) => {
  setActiveFilters(prev => [...prev, { type: filterType, value: filter, relation: 'AND' }]);
};

// Utility function to remove a filter from the activeFilters state
const removeActiveFilter = (filter, filterType) => {
  setActiveFilters(prev => prev.filter(f => f.value !== filter || f.type !== filterType));
};

const toggleFilterRelation = (index) => {
  setActiveFilters(current => 
    current.map((filter, idx) => 
      idx === index ? {...filter, relation: filter.relation === 'AND' ? 'OR' : 'AND'} : filter
    )
  );
};



const addFilter = (filter, type) => {
  if (filter.trim() === '') {
    alert('Please enter a filter term.');
    return;
  }
  
  if (type === 'include') {
    setIncludeFilters(prevFilters => [...prevFilters, filter]);
    addActiveFilter(filter, 'include');
  } else if (type === 'exclude') {
    setExcludeFilters(prevFilters => [...prevFilters, filter]);
    addActiveFilter(filter, 'exclude');
  }
  filterInputRef.current.value = ''; // Clear input field after adding a filter
};

// Modified removeFilter function to also update activeFilters
const removeFilter = (filter, type) => {
  if (type === 'include') {
    setIncludeFilters(includeFilters.filter(f => f !== filter));
    removeActiveFilter(filter, 'include');
  } else if (type === 'exclude') {
    setExcludeFilters(excludeFilters.filter(f => f !== filter));
    removeActiveFilter(filter, 'exclude');
  }
};


const addValueFilter = (newFilter) => {
  if (newFilter.column && newFilter.condition && newFilter.value !== null) {
    setValueFilters(prevFilters => [...prevFilters, newFilter]);
    addActiveFilter(newFilter, 'valueFilter');
  } else {
    alert("Please complete the filter fields.");
  }
};

// Utility function to remove a value filter from the activeFilters state
const removeValueFilter = (index) => {
  const filterToRemove = valueFilters[index];
  setValueFilters(prevFilters => prevFilters.filter((_, idx) => idx !== index));
  removeActiveFilter(filterToRemove, 'valueFilter');
};

const handleStatusChange = (newStatus) => {
  setSelectedStatus(newStatus);
  if (selectedStatus !== newStatus) {
    // Remove old status if it exists
    if (selectedStatus) {
      removeActiveFilter({ value: selectedStatus, type: 'status' }, 'status');
    }
    // Add new status to active filters if not empty
    if (newStatus) {
      addActiveFilter({ value: newStatus, type: 'status' }, 'status');
    }
  }
};

    const formatValue = (key, value) => {
        // Handle null values
        if (value === null) {
            return 'N/A'; // Placeholder for null values
        }

        if (key === 'effective_status') {
          if (value === 'Unknown') {
              return 'Inactive'; // Return 'Inactive' when the value is undefined
          }
          // Just return the status text without any color indicator
          return value;
      }

        if (key === 'account_id') {
          return showAccountId ? (
              <span className="hover:underline cursor-pointer" onClick={() => setShowAccountId(false)}>
                  {value}
              </span>
          ) : (
              <span className="hover:underline cursor-pointer" onClick={() => setShowAccountId(true)}>
                  {accountNames[value] || value}
              </span>
          );
      }
        const unformattedFields = ['account_id', 'campaign_id', 'adset_id', 'ad_id'];
        if (unformattedFields.includes(key)) {
            return value;
        }

        // Convert string to number if it's a numeric string
        let numericValue = (typeof value === 'string' && !isNaN(value)) ? parseFloat(value) : value;

        // Check if numericValue is actually a number
        if (typeof numericValue !== 'number') {
            return value; // Return original value if it's not a number
        }
        // Define the formatter for currency
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    
        // Define the formatter for percentages
        const percentageFormatter = new Intl.NumberFormat('en-US', {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    
        // Define which keys correspond to which types of data
        const currencyFields = ['total_spend', 'total_revenue', 'profit', 'cpa', 'aov', 'epc', 'cpc', 'cpm', 'costPer3SecVideoViews'];
        const percentageFields = ['ctr', 'cvr', 'profit_margin', 'videoHoldRate', 'thumbStopRatio'];
        const wholeNumberFields = ['total_impressions', 'unique_clicks', 'order_count'];
        
    if (currencyFields.includes(key)) {
        return currencyFormatter.format(numericValue);
    } else if (percentageFields.includes(key)) {
        // Convert the decimal to a percentage and format
        return percentageFormatter.format(numericValue / 100);
    } else if (wholeNumberFields.includes(key)) {
        // Format whole numbers without decimal places
        return Math.round(numericValue).toLocaleString();
    } else {
        // For other numerical values, limit to 2 decimal places
        return numericValue.toFixed(2);
    }
    
};

useEffect(() => {
  localStorage.setItem(`${type}_includeFilters`, JSON.stringify(includeFilters));
}, [includeFilters, type]);

useEffect(() => {
  localStorage.setItem(`${type}_excludeFilters`, JSON.stringify(excludeFilters));
}, [excludeFilters, type]);

useEffect(() => {
  localStorage.setItem(`${type}_searchTerm`, searchTerm);
}, [searchTerm, type]);

useEffect(() => {
  localStorage.setItem(`${type}_sortConfig`, JSON.stringify(sortConfig));
}, [sortConfig, type]);

useEffect(() => {
  localStorage.setItem(`${type}_valueFilters`, JSON.stringify(valueFilters));
}, [valueFilters, type]);



useEffect(() => {
  localStorage.setItem(`${type}_showFilterOptions`, JSON.stringify(showFilterOptions));
}, [showFilterOptions, type]);


const getColumnConfig = (type) => {
  switch (type) {
    case 'account':
      return [
        { key: 'account_id', label: 'Acc Name' },
        { key: 'total_spend', label: 'Spend' },
        { key: 'roas', label: 'ROAS' },
        { key: 'profit', label: 'Profit' },
        { key: 'profit_margin', label: 'Profit %' },
        { key: 'cpa', label: 'CPA' },
        { key: 'aov', label: 'AOV' },
        { key: 'cvr', label: 'CVR' },
        { key: 'epc', label: 'EPC' },
        { key: 'cpc', label: 'CPC' },
        { key: 'ctr', label: 'CTR' },
        { key: 'cpm', label: 'CPM' },
        { key: 'unique_clicks', label: 'Clicks' },
        { key: 'order_count', label: 'PUR' },
        { key: 'total_revenue', label: 'Revenue' },
        { key: 'thumbStopRatio', label: 'TSR' },
        { key: 'videoHoldRate', label: 'VHR' },
        { key: 'costPer3SecVideoViews', label: 'Cost_3sec' },
        // Add other columns specific to 'account'
      ];
    case 'campaign':
      return [
        { key: 'campaign_name', label: 'Campaign Name' },
        { key: 'effective_status', label: 'Status' },
        { key: 'total_spend', label: 'Spend' },
        { key: 'roas', label: 'ROAS' },
        { key: 'profit', label: 'Profit' },
        { key: 'profit_margin', label: 'Profit %' },
        { key: 'campaign_id', label: 'Campaign ID' },
        { key: 'cpa', label: 'CPA' },
        { key: 'aov', label: 'AOV' },
        { key: 'cvr', label: 'CVR' },
        { key: 'epc', label: 'EPC' },
        { key: 'cpc', label: 'CPC' },
        { key: 'ctr', label: 'CTR' },
        { key: 'cpm', label: 'CPM' },
        { key: 'thumbStopRatio', label: 'TSR' },
        { key: 'videoHoldRate', label: 'VHR' },
        { key: 'costPer3SecVideoViews', label: 'Cost_3sec' },
        { key: 'unique_clicks', label: 'Clicks' },
        { key: 'order_count', label: 'PUR' },
        { key: 'total_revenue', label: 'Revenue' },
        { key: 'account_id', label: 'Acc Name' },

        // Add other columns specific to 'campaign'
      ];
    case 'adset':
      return [
        { key: 'adset_name', label: 'Adset Name' },
        { key: 'effective_status', label: 'Status' },
        { key: 'total_spend', label: 'Spend' },
        { key: 'roas', label: 'ROAS' },
        { key: 'profit', label: 'Profit' },
        { key: 'profit_margin', label: 'Profit %' },
        { key: 'adset_id', label: 'Adset ID' },
        { key: 'cpa', label: 'CPA' },
        { key: 'aov', label: 'AOV' },
        { key: 'cvr', label: 'CVR' },
        { key: 'epc', label: 'EPC' },
        { key: 'cpc', label: 'CPC' },
        { key: 'ctr', label: 'CTR' },
        { key: 'cpm', label: 'CPM' },
        { key: 'thumbStopRatio', label: 'TSR' },
        { key: 'videoHoldRate', label: 'VHR' },
        { key: 'costPer3SecVideoViews', label: 'Cost_3sec' },
        { key: 'unique_clicks', label: 'Clicks' },
        { key: 'order_count', label: 'PUR' },
        { key: 'total_revenue', label: 'Revenue' },
        { key: 'account_id', label: 'Acc Name' },

        // Add other columns specific to 'adset'
      ];
    case 'ads':
      return [
        { key: 'ad_name', label: 'Ads Name' },
        { key: 'effective_status', label: 'Status' },
        { key: 'total_spend', label: 'Spend' },
        { key: 'roas', label: 'ROAS' },
        { key: 'profit', label: 'Profit' },
        { key: 'profit_margin', label: 'Profit %' },
        { key: 'ad_id', label: 'Ads ID' },
        { key: 'cpa', label: 'CPA' },
        { key: 'aov', label: 'AOV' },
        { key: 'cvr', label: 'CVR' },
        { key: 'epc', label: 'EPC' },
        { key: 'cpc', label: 'CPC' },
        { key: 'ctr', label: 'CTR' },
        { key: 'cpm', label: 'CPM' },
        { key: 'thumbStopRatio', label: 'TSR' },
        { key: 'videoHoldRate', label: 'VHR' },
        { key: 'costPer3SecVideoViews', label: 'Cost_3sec' },
        { key: 'avgWatchTimeInSeconds', label: 'AWT' },
        { key: 'unique_clicks', label: 'Clicks' },
        { key: 'order_count', label: 'PUR' },
        { key: 'total_revenue', label: 'Revenue' },

        // Add other columns specific to 'ads'
      ];
    default:
      return []; // Default case
  }
};


const getChangeIndicator = (key, currentValue, previousValue) => {
  if (!previousValue || !currentValue) return null; // No data for comparison
  if (currentValue > previousValue) return '↑';
  if (currentValue < previousValue) return '↓';
  return null; // No change
};


const calculatePercentiles = (data, field) => {
  const values = data.map(item => parseFloat(item[field])).filter(item => !isNaN(item));
  values.sort((a, b) => a - b);
  
  const percentiles = {
    min: Math.min(...values),
    max: Math.max(...values)
  };
  
  return percentiles;
};

// Calculate percentiles for each field
const roasPercentiles = useMemo(() => calculatePercentiles(data, 'roas'), [data]);
const cpcPercentiles = useMemo(() => calculatePercentiles(data, 'cpc'), [data]);

const getColorForValueLog = (value, percentiles, isLowerBetter = false) => {
  // Ensure percentiles are passed as a parameter
  const logValue = Math.log(value + 1); // Adding 1 to avoid log(0)
  const normalizedValue = (logValue - Math.log(percentiles.min + 1)) / (Math.log(percentiles.max + 1) - Math.log(percentiles.min + 1));
  
  // Check if lower values are better, then invert the color scale
  if (isLowerBetter) {
    if (normalizedValue <= 0.2) return '#008000'; // Green
    if (normalizedValue <= 0.4) return '#9ACD32'; // Light Green
    if (normalizedValue <= 0.6) return '#FFFF00'; // Yellow
    if (normalizedValue <= 0.8) return '#f1807e'; // Light Red
    return '#FF4500'; // Red
  } else {
    if (normalizedValue <= 0.2) return '#FF4500'; // Red
    if (normalizedValue <= 0.4) return '#f1807e'; // Light Red
    if (normalizedValue <= 0.6) return '#FFFF00'; // Yellow
    if (normalizedValue <= 0.8) return '#9ACD32'; // Light Green
    return '#008000'; // Green
  }
};

const columns = useMemo(() => getColumnConfig(type), [type, roasPercentiles /* Add other percentiles here too */]);

const handleCheckboxChange = (row) => {

  let id;
  switch (type) {
    case 'ads':
      id = row.ad_id;
      break;
    case 'adset':
      id = row.adset_id;
      break;
    case 'campaign':
      id = row.campaign_id;
      break;
    case 'account':
      id = row.account_id;
      break;
    default:
      id = row.id; // Fallback if type is not specified
  }


  // console.log("Checkbox clicked:", row);
  setCheckedRows(prev => {
    const rowIndex = prev.findIndex(item => item.id === id && item.type === type);
    let newCheckedRows;
    if (rowIndex > -1) {
      newCheckedRows = prev.filter((_, i) => i !== rowIndex);
    } else {
      newCheckedRows = [...prev, { id: id, type: type }];
    }
    // console.log("New checkedRows:", newCheckedRows);
    return newCheckedRows;
  });
};




const applySelection = () => {
  setSelectedItems(checkedRows);
  setCheckedRows([]); // Optionally clear the checked rows after applying
};

const typeToIdMapping = {
  'account': 'account_id',
  'campaign': 'campaign_id',
  'adset': 'adset_id',
  'ads': 'ad_id',
};

const isAnyFilterActive = () => {
  // This checks if any of the filter arrays or status have entries/values that might affect filtering
  return includeFilters.length > 0 || excludeFilters.length > 0 || selectedStatus !== '' || valueFilters.length > 0;
};

const [visibleBreakdowns, setVisibleBreakdowns] = useState({});
const handleShowBreakdown = (row, type) => {

  const idKey = typeToIdMapping[type];
  const rowId = row[idKey];  // Get the unique identifier for the row
  if (!dailyData) { // Check if dailyData is undefined or null
    console.error("dailyData is undefined or null.");
    // Display a message to the user
    alert("Pick a date range to see daily data of the object");
    return; // Exit the function to prevent further execution
  }

  if (row) {
    // console.log("Selected Row:", row);
    // console.log("Daily Data: ", dailyData);

    const dailyDataForSelectedRow = dailyData.filter(dailyItem => dailyItem[idKey] === rowId);
    // console.log("Selected Daily Data for Row:", dailyDataForSelectedRow);

    setVisibleBreakdowns(prev => ({
      ...prev,
      [`${type}_${rowId}`]: {
        isVisible: !prev[`${type}_${rowId}`]?.isVisible,
        data: dailyDataForSelectedRow,
      },
    }));
  }
};

const handleShowAllBreakdowns = (checkedRows) => {
  const typeToIdMapping = {
    'account': 'account_id',
    'campaign': 'campaign_id',
    'adset': 'adset_id',
    'ads': 'ad_id',
  };

  if (!dailyData) {
    console.error("dailyData is undefined or null.");
    alert("Pick a date range to see daily data of the object");
    return;
  }

  let aggregatedDataByDate = {};

  checkedRows.forEach((checkedRow) => {
    const { id, type } = checkedRow;
    const idKey = typeToIdMapping[type];

    if (!idKey) {
      console.error(`Invalid type provided: ${type}`);
      return;
    }

    if (!id) {
      console.error(`Row ID is undefined for type: ${type}. Checked Row:`, checkedRow);
      return;
    }

    const dailyDataForSelectedRow = dailyData.filter(dailyItem => dailyItem[idKey] === id);
    
    dailyDataForSelectedRow.forEach(data => {
      const date = data.created_at.split('T')[0]; // Assuming 'created_at' is your date field and stripping time part
      if (!aggregatedDataByDate[date]) {
        // Initialize with the first record for the date
        aggregatedDataByDate[date] = { ...data, date: date };
        // Convert string values to numbers for summing up later
        aggregatedDataByDate[date].total_spend = parseFloat(data.total_spend) || 0;
        aggregatedDataByDate[date].total_revenue = parseFloat(data.total_revenue) || 0;
        aggregatedDataByDate[date].unique_clicks = parseInt(data.unique_clicks) || 0;
        aggregatedDataByDate[date].order_count = parseInt(data.order_count) || 0;
        aggregatedDataByDate[date].total_impressions = parseInt(data.total_impressions) || 0;
        aggregatedDataByDate[date].profit = parseFloat(data.profit) || 0;
      } else {
        // Aggregate the data by summing up the relevant metrics
        const metricsToSum = ['total_spend', 'total_revenue', 'unique_clicks', 'order_count', 'total_impressions', 'profit'];
        metricsToSum.forEach(metric => {
          aggregatedDataByDate[date][metric] += parseFloat(data[metric]) || 0;
        });
      }
    });
  });

  // Calculate derived metrics for each date
  Object.keys(aggregatedDataByDate).forEach(date => {
    const data = aggregatedDataByDate[date];
    data.roas = data.total_spend ? (data.total_revenue / data.total_spend) : 0;
    data.profit_percent = data.total_revenue ? (data.profit / data.total_revenue) * 100 : 0;
    data.cpa = data.order_count ? data.total_spend / data.order_count : 0;
    data.aov = data.order_count ? data.total_revenue / data.order_count : 0;
    data.cvr = data.unique_clicks ? (data.order_count / data.unique_clicks) * 100 : 0; // Percentage
    data.epc = data.unique_clicks ? data.total_revenue / data.unique_clicks : 0;
    data.cpc = data.unique_clicks ? data.total_spend / data.unique_clicks : 0;
    data.ctr = data.total_impressions ? (data.unique_clicks / data.total_impressions) * 100 : 0; // Percentage
    data.cpm = data.total_impressions ? (data.total_spend / data.total_impressions) * 1000 : 0; // Cost per thousand impressions
  });

  // Convert the aggregated object into an array for rendering
  const aggregatedDataArray = Object.entries(aggregatedDataByDate).map(([date, data]) => ({ date, ...data }));

  // Set state here to store the aggregated data for rendering
  setAllSelectedDailyData(aggregatedDataArray);
};




const handleSelectAllClick = () => {
  setSelectAll(!selectAll); // Toggle the selectAll state
  
  if (!selectAll) {
    // If currently not all are selected, select all
    const newCheckedRows = sortedAndFilteredData.map(row => {
      return { id: row[typeToIdMapping[type]], type }; // Adjust according to your data structure
    });
    setCheckedRows(newCheckedRows);
  } else {
    // If currently all are selected, clear the selection
    setCheckedRows([]);
  }
};

const getLabelByKey = (key) => {
  const column = columnOptions.find(col => col.key === key);
  return column ? column.label : key; // Fallback to key if no label is found
};




const getCsvCongif = (type) => {
  switch (type) {
    case 'account':
      return [
        { key: 'created_at', label: 'Date' },
        { key: 'account_id', label: 'Acc Name' },
        { key: 'total_spend', label: 'Spend' },
        { key: 'roas', label: 'ROAS' },
        { key: 'profit', label: 'Profit' },
        { key: 'profit_margin', label: 'Profit %' },
        { key: 'cpa', label: 'CPA' },
        { key: 'aov', label: 'AOV' },
        { key: 'cvr', label: 'CVR' },
        { key: 'epc', label: 'EPC' },
        { key: 'cpc', label: 'CPC' },
        { key: 'ctr', label: 'CTR' },
        { key: 'cpm', label: 'CPM' },
        { key: 'thumbStopRatio', label: 'TSR' },
        { key: 'videoHoldRate', label: 'VHR' },
        { key: 'costPer3SecVideoViews', label: 'Cost_3sec' },
        { key: 'avgWatchTimeInSeconds', label: 'AWT' },
        { key: 'unique_clicks', label: 'Clicks' },
        { key: 'order_count', label: 'PUR' },
        { key: 'total_revenue', label: 'Revenue' }
        // Add other columns specific to 'account'
      ];
    case 'campaign':
      return [
        { key: 'created_at', label: 'Date' },
        { key: 'campaign_name', label: 'Campaign Name' },
        { key: 'total_spend', label: 'Spend' },
        { key: 'roas', label: 'ROAS' },
        { key: 'profit', label: 'Profit' },
        { key: 'profit_margin', label: 'Profit %' },
        { key: 'cpa', label: 'CPA' },
        { key: 'aov', label: 'AOV' },
        { key: 'cvr', label: 'CVR' },
        { key: 'epc', label: 'EPC' },
        { key: 'cpc', label: 'CPC' },
        { key: 'ctr', label: 'CTR' },
        { key: 'cpm', label: 'CPM' },
        { key: 'thumbStopRatio', label: 'TSR' },
        { key: 'videoHoldRate', label: 'VHR' },
        { key: 'costPer3SecVideoViews', label: 'Cost_3sec' },
        { key: 'avgWatchTimeInSeconds', label: 'AWT' },
        { key: 'unique_clicks', label: 'Clicks' },
        { key: 'order_count', label: 'PUR' },
        { key: 'total_revenue', label: 'Revenue' },
        { key: 'account_id', label: 'Acc Name' },

        // Add other columns specific to 'campaign'
      ];
    case 'adset':
      return [
        { key: 'created_at', label: 'Date' },
        { key: 'adset_name', label: 'Adset Name' },
        { key: 'total_spend', label: 'Spend' },
        { key: 'roas', label: 'ROAS' },
        { key: 'profit', label: 'Profit' },
        { key: 'adset_id', label: 'Adset ID' },
        { key: 'profit_margin', label: 'Profit %' },
        { key: 'cpa', label: 'CPA' },
        { key: 'aov', label: 'AOV' },
        { key: 'cvr', label: 'CVR' },
        { key: 'epc', label: 'EPC' },
        { key: 'cpc', label: 'CPC' },
        { key: 'ctr', label: 'CTR' },
        { key: 'cpm', label: 'CPM' },
        { key: 'thumbStopRatio', label: 'TSR' },
        { key: 'videoHoldRate', label: 'VHR' },
        { key: 'costPer3SecVideoViews', label: 'Cost_3sec' },
        { key: 'avgWatchTimeInSeconds', label: 'AWT' },
        { key: 'unique_clicks', label: 'Clicks' },
        { key: 'order_count', label: 'PUR' },
        { key: 'total_revenue', label: 'Revenue' },
        { key: 'account_id', label: 'Acc Name' },

        // Add other columns specific to 'adset'
      ];
    case 'ads':
      return [
        { key: 'created_at', label: 'Date' },
        { key: 'ad_name', label: 'Ads Name' },
        { key: 'total_spend', label: 'Spend' },
        { key: 'roas', label: 'ROAS' },
        { key: 'profit', label: 'Profit' },
        { key: 'ad_id', label: 'Ads ID' },
        { key: 'profit_margin', label: 'Profit %' },
        { key: 'cpa', label: 'CPA' },
        { key: 'aov', label: 'AOV' },
        { key: 'cvr', label: 'CVR' },
        { key: 'epc', label: 'EPC' },
        { key: 'cpc', label: 'CPC' },
        { key: 'ctr', label: 'CTR' },
        { key: 'cpm', label: 'CPM' },
        { key: 'thumbStopRatio', label: 'TSR' },
        { key: 'videoHoldRate', label: 'VHR' },
        { key: 'costPer3SecVideoViews', label: 'Cost_3sec' },
        { key: 'avgWatchTimeInSeconds', label: 'AWT' },
        { key: 'unique_clicks', label: 'Clicks' },
        { key: 'order_count', label: 'PUR' },
        { key: 'total_revenue', label: 'Revenue' },
        { key: 'campaign_id', label: 'Campaign ID' },
        { key: 'adset_id', label: 'Adset ID' },

        // Add other columns specific to 'ads'
      ];
    default:
      return []; // Default case
  }
};



const downloadCSV = () => {
  if (!dailyData || !Array.isArray(dailyData)) {
    console.error("dailyData is undefined or not an array.");
    alert("No data available to download.");
    return;
  }

  const typeToIdMapping = {
    'account': 'account_id',
    'campaign': 'campaign_id',
    'adset': 'adset_id',
    'ads': 'ad_id',
  };



  // Prepare the data for each selected object
  let dataForCSV = [];
  checkedRows.forEach(checkedRow => {
    const { id, type } = checkedRow;
    const idKey = typeToIdMapping[type];
    const objectData = dailyData.filter(item => item[idKey] === id);

    if (objectData && objectData.length > 0) {
      dataForCSV = [...dataForCSV, ...objectData];
    }
  });

  if (dataForCSV.length === 0) {
    console.error("No data found for the selected rows.");
    alert("No data found for the selected rows.");
    return;
  }

  dataForCSV.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  // Get column configuration based on the type
  const columnConfig = getCsvCongif(checkedRows[0].type); // Assuming all rows have the same type

  // Generate CSV header using the column configuration
  const csvHeader = columnConfig.map(column => column.label).join(',');


  // Generate CSV rows
  const csvRows = dataForCSV.map(row => {
    return columnConfig.map(column => {
      let value = row[column.key];

      
      // Date formatting
      const dateFields = ['created_at', 'date']; // Add other date fields if needed
      if (dateFields.includes(column.key)) {
        const date = new Date(value);
        value = date.toISOString().split('T')[0]; // Format the date as YYYY-MM-DD
      } else if (value !== null && value !== undefined && !isNaN(Number(value))) {
        value = Number(value);
        
        // Format as a percentage if the key is 'cvr' or 'profit_margin'
        if (column.key === 'cvr' || column.key === 'profit_margin' || column.key === 'thumbStopRatio' || column.key === 'videoHoldRate') {
          value = (value * 100).toFixed(2) + '%'; // Convert to percentage and limit to 2 decimal places
        } else if (!Number.isInteger(value)) {
          value = value.toFixed(2); // Limit to 2 decimal places for other numerical values with fractions
        }
        // If the value is an integer, it's left as-is (whole number)
      }

      return value;
    }).join(',');
  });

  // Combine header and rows
  const csvString = [csvHeader, ...csvRows].join('\n');

  // Create a Blob from the CSV string
  const blob = new Blob([csvString], { type: 'text/csv' });

  // Create a link and trigger the download
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `daily-data.csv`; // Name of the downloaded file
  link.click();

  // Cleanup
  window.URL.revokeObjectURL(url);
};


const columnOptions = [
  { key: 'total_spend', label: 'Spend' },
  { key: 'roas', label: 'ROAS' },
  { key: 'profit', label: 'Profit' },
  { key: 'profit_margin', label: 'Profit %' },
  { key: 'cpa', label: 'CPA' },
  { key: 'aov', label: 'AOV' },
  { key: 'cvr', label: 'CVR' },
  { key: 'epc', label: 'EPC' },
  { key: 'cpc', label: 'CPC' },
  { key: 'ctr', label: 'CTR' },
  { key: 'cpm', label: 'CPM' },
  { key: 'thumbStopRatio', label: 'TSR' },
  { key: 'videoHoldRate', label: 'VHR' },
  { key: 'costPer3SecVideoViews', label: 'Cost_3sec' },
  { key: 'avgWatchTimeInSeconds', label: 'AWT' },
  { key: 'unique_clicks', label: 'Clicks' },
  { key: 'order_count', label: 'PUR' },
  { key: 'total_revenue', label: 'Revenue' },
];


const totals = useMemo(() => {
  const initialTotals = {
      totalSpend: 0,
      totalRevenue: 0,
      profit: 0,
      totalImpressions: 0,
      totalClicks: 0,
      totalOrders: 0,
      total3sec: 0,
      totalThruPlays: 0,
};

  // Sum up the necessary fields
  sortedAndFilteredData.forEach(item => {
      initialTotals.totalSpend += parseFloat(item.total_spend || 0);
      initialTotals.totalRevenue += parseFloat(item.total_revenue || 0);
      initialTotals.profit += parseFloat(item.profit || 0);
      initialTotals.totalImpressions += parseFloat(item.total_impressions || 0);
      initialTotals.totalClicks += parseFloat(item.unique_clicks || 0);
      initialTotals.totalOrders += parseFloat(item.order_count || 0);
      initialTotals.total3sec += parseFloat(item.unique_3_sec_watch || 0);
      initialTotals.totalThruPlays += parseFloat(item.thru_play || 0);
  });

  // Calculate complex metrics
  return {
      ...initialTotals,
      roas: initialTotals.totalSpend ? initialTotals.totalRevenue / initialTotals.totalSpend : 0,
      profit_margin: initialTotals.totalRevenue ? (initialTotals.profit / initialTotals.totalRevenue) * 100 : 0,
      cpa: initialTotals.totalOrders ? initialTotals.totalSpend / initialTotals.totalOrders : 0,
      aov: initialTotals.totalOrders ? initialTotals.totalRevenue / initialTotals.totalOrders : 0,
      cvr: initialTotals.totalClicks ? (initialTotals.totalOrders / initialTotals.totalClicks) * 100 : 0,
      epc: initialTotals.totalClicks ? initialTotals.totalRevenue / initialTotals.totalClicks : 0,
      cpc: initialTotals.totalClicks ? initialTotals.totalSpend / initialTotals.totalClicks : 0,
      ctr: initialTotals.totalImpressions ? (initialTotals.totalClicks / initialTotals.totalImpressions) * 100 : 0,
      cpm: initialTotals.totalImpressions ? (initialTotals.totalSpend / initialTotals.totalImpressions) * 1000 : 0,
      unique_clicks: initialTotals.totalClicks || 0,
      order_count: initialTotals.totalOrders || 0,
      total_revenue: initialTotals.totalRevenue || 0,
      total_spend: initialTotals.totalSpend || 0,
      thumbStopRatio: initialTotals.totalImpressions ? (initialTotals.total3sec / initialTotals.totalImpressions) * 100 : 0,
      videoHoldRate: initialTotals.total3sec ? (initialTotals.totalThruPlays / initialTotals.total3sec) * 100 : 0,
      costPer3SecVideoViews: initialTotals.total3sec ? (initialTotals.totalSpend / initialTotals.total3sec) : 0,
  
  };
}, [sortedAndFilteredData]);




return (
  <div className="relative sm:rounded-lg">
    <div className="flex justify-between mb-4">
      <div>
        <h1 className="text-2xl font-semibold">{title}</h1>

      </div>
    </div>
    <div className="flex justify-between items-center w-full">
  
  {/* Left side: Buttons */}
  <div className="flex items-center">
    {/* Filter Button */}
    <button 
      onClick={applySelection}
      className="px-4 py-2 text-gray-700 hover:underline transition ease-in-out duration-350"
    >
      Filter
    </button>

    {/* Vertical line */}
    <div className="border-r border-gray-400 mx-2 h-4"></div>

    {/* Daily View Button */}
    <button 
      onClick={() => handleShowAllBreakdowns(checkedRows)}
      className="px-4 py-2 text-gray-700 hover:underline transition ease-in-out duration-350"
    >
      Daily View
    </button>

    {/* Vertical line */}
    <div className="border-r border-gray-400 mx-2 h-4"></div>

    {/* CSV Button */}
    <button 
      onClick={downloadCSV}
      className="px-4 py-2 text-gray-700 hover:underline transition ease-in-out duration-350"
    >
      CSV
    </button>

    <div className="flex items-center space-x-3">
  {isAnyFilterActive() && (
    showSaveFilterModal ? (
      <React.Fragment>
        <input
          type="text"
          value={newFilterLabel}
          onChange={(e) => setNewFilterLabel(e.target.value)}
          placeholder="Enter filter label"
          className="border p-1 rounded w-auto flex-grow"
          autoFocus
        />
        <button
        onClick={() => {
          handleSaveFilters();
        }}         
   className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          aria-label="Confirm filter label"
        >
          <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
          </svg>
        </button>
        <button
          onClick={() => setShowSaveFilterModal(false)}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          aria-label="Cancel filter label"
        >
          <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </React.Fragment>
    ) : (
      <button
        onClick={() => setShowSaveFilterModal(true)}
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
      >
        Save Filters
      </button>
    )
  )}
</div>

  </div>


  {/* Right side: Filters */}
  <div className="relative flex items-center space-x-2">
    


    {/* Existing Filter button and its conditional rendering */}
    <button 
    onClick={() => setShowFilterOptions(!showFilterOptions)}
    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
>
    {showFilterOptions ? (
        // Render 'X' when filter options are visible
        <svg className="w-4 h-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      ) : (
        // Render 'Filter' text when filter options are not visible
        'Filter'
      )}
</button>
{showFilterOptions && (
          <div className="absolute z-50 w-56 mt-2 bg-white rounded-lg shadow-lg">
            <div className="p-4">
              {/* Close Button */}
              <div className="flex pb-1 justify-end">
                <button
                  onClick={toggleFilterOptions}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>

              {/* Dropdown for Saved Filters */}
              <div className="relative mb-4">
                <button
                  onClick={toggleSavedFilterDropdown}
                  className="w-full p-1 border border-gray-300 rounded flex items-center justify-between"
                >
                  <span>Saved Filters</span>
                  <svg
                    className={`w-4 h-4 transition-transform duration-200 ${
                      showSavedFilterDropdown ? 'rotate-180' : ''
                    }`}
                    fill="none"
                    strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"
                  >
                    <path d="M19 9l-7 7-7-7"></path>
                  </svg>
                </button>

                {showSavedFilterDropdown && (
                  <div className="absolute z-50 w-full bg-white rounded-lg shadow-lg mt-2">
                    <ul className="divide-y divide-gray-200">
                      {filterPresets.map((preset, index) => (
                        <li key={index} className="flex items-center p-2 hover:bg-gray-100">
                          <span
                            className="flex-1 cursor-pointer"
                            onClick={() => {
                              applySavedFilter(preset.id);
                              setShowSavedFilterDropdown(false); // Close dropdown after selection
                            }}
                          >
                            {preset.label}
                          </span>
                          <button
                            onClick={() => {
                              const confirmed = window.confirm("Are you sure you want to delete this filter preset?");
                              if (confirmed) {
                                deleteFilter(preset.id).then(() => {
                                  setFilterPresets((prevPresets) => prevPresets.filter(p => p.id !== preset.id));
                                });
                              }
                            }}
                            className="text-red-500 hover:text-red-600"
                          >
                            <svg className="w-4 h-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                              <path d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

      {/* Additional Filter Inputs */}
      <div>
        <input
          type="text"
          placeholder="Enter filter term..."
          ref={filterInputRef}
          className="p-2 w-full border border-gray-300 rounded mb-2"
        />
        <div className="flex justify-between">
          <button
            onClick={() => addFilter(filterInputRef.current.value, 'include')}
            className="bg-blue-500 hover:bg-blue-600 text-white text-sm py-2 px-4 rounded"
          >
            Include
          </button>
          <button
            onClick={() => addFilter(filterInputRef.current.value, 'exclude')}
            className="bg-red-500 hover:bg-red-600 text-white text-sm py-2 px-4 rounded"
          >
            Exclude
          </button>
        </div>
      </div>

      {/* Column and Condition Selection */}
      <div className="mt-4">
        <select
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
          className="p-2 w-full border border-gray-300 rounded mb-2"
        >
          <option value="">Status</option>
          {uniqueStatuses.map(status => (
            <option key={status} value={status}>{status}</option>
          ))}
        </select>

        <select
          value={newFilter.column}
          onChange={(e) => setNewFilter({ ...newFilter, column: e.target.value })}
          className="p-2 w-full border border-gray-300 rounded mb-2"
        >
          <option value="">Select a column</option>
          {columnOptions.map(col => (
            <option key={col.key} value={col.key}>{col.label}</option>
          ))}
        </select>

        <select
          value={newFilter.condition}
          onChange={(e) => setNewFilter({ ...newFilter, condition: e.target.value })}
          className="p-2 w-full border border-gray-300 rounded"
        >
          <option value="">Select a condition</option>
          <option value="greater">Greater than</option>
          <option value="smaller">Smaller than</option>
        </select>

        <input
          type-attribute="number"
          onChange={(e) => setNewFilter({ ...newFilter, value: parseFloat(e.target.value) })}
          className="p-2 w-full border border-gray-300 rounded mb-2"
          placeholder="Enter a value..."
        />

        <button
          onClick={() => addValueFilter(newFilter)}
          className="bg-blue-500 hover:bg-blue-600 text-white text-sm py-2 px-4 rounded"
        >
          Add Filter
        </button>
      </div>
    </div>
  </div>
)}





    {/* Search Input */}
    <input
      type="text"
      placeholder="Search..."
      value={searchTerm}
      onChange={handleSearchChange}
      className="p-2 border rounded"
    />
  </div>
</div>


    {/* Display Active Filters */}
    <div className="active-filters flex flex-wrap items-center mb-4">
  {activeFilters.map((filter, index) => (
    <React.Fragment key={index}>
      <div className="flex items-center px-4 py-2 rounded-full bg-gray-100 text-gray-800 space-x-2">
        <span>
          {filter.type}: {typeof filter.value === 'object' ? `${filter.value.column} ${filter.value.condition} ${filter.value.value}` : filter.value}
        </span>
        <button 
          onClick={() => removeActiveFilter(filter.value, filter.type)}
          className="ml-2 p-1 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      {index < activeFilters.length - 1 && (
        <span 
          onClick={() => toggleFilterRelation(index)}
          className="mx-2 text-sm font-bold cursor-pointer"
        >
          {filter.relation}
        </span>
      )}
    </React.Fragment>
  ))}
</div>


    <div className="overflow-x-auto" style={{ maxHeight: `600px` }}>
        <table className="w-full text-sm text-left text-white ">
          <thead className="bg-gray-700 shadow sticky top-0 z-10">
            <tr>
              {/* Additional header for checkboxes */}
              <th
                className="py-3 px-6 sticky left-0 bg-gray-700 cursor-pointer"
                onClick={handleSelectAllClick}
              >
                Select
              </th>
              {/* Existing header rendering */}
              {columns.map(({ key, label }) => (
                <th
                  key={key}
                  onClick={() => requestSort(key)}
                  className={`py-3 px-6 cursor-pointer ${
                    ['campaign_name', 'adset_name', 'ad_name'].includes(key) ? "text-left" : "text-center"
                  }`}
                >
                  <div className="flex justify-center items-center space-x-1">
                    <span>{label}</span>
                    {sortConfig.key === key && (
                      <span>{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                    )}
                  </div>
                </th>
              ))}
              <th className="py-3 px-2 sticky left-0 bg-gray-700"></th>
            </tr>
          </thead>
          <tbody>
  {sortedAndFilteredData.map((row, idx) => {
    let rowId;
    switch (type) {
      case 'campaign':
        rowId = row.campaign_id;
        break;
      case 'adset':
        rowId = row.adset_id;
        break;
      case 'ads':
        rowId = row.ad_id;
        break;
      case 'account':
        rowId = row.account_id;
        break;
      default:
        rowId = row.id;
    }



    return (
      <React.Fragment key={idx}>
        <tr className={`${isRowSelected(row) ? 'selected-rows ' : ''} ${idx % 2 === 0 ? 'bg-gray-400' : 'bg-gray-300 '}`}>
          {/* Checkbox cell */}
          <td className={`${idx % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'} px-6 py-4 text-center sticky left-0`}>
          <input
              type="checkbox"
              checked={selectAll || checkedRows.some(item => item.id === rowId && item.type === type)}
              onChange={() => handleCheckboxChange(row)}
              onClick={(e) => e.stopPropagation()}
              style={{ transform: "scale(1.3)" }}
            />

          </td>
          {/* Data cells */}
          {columns.map(({ key }) => {
            const val = parseFloat(row[key]);
            let cellStyle = {};

            if (['spend', 'roas', 'cpc'].includes(key)) {
              let color;
              if (key === 'roas') color = getColorForValueLog(val, roasPercentiles);
              else if (key === 'cpc') color = getColorForValueLog(val, cpcPercentiles, true);

              cellStyle = { backgroundColor: color, color: 'black', borderTop: '0.1px solid #000000' };
            }

            return (
              <td key={key} style={cellStyle} className={`py-4 px-8 ${isStickyColumn(key) ? "text-left" : "text-center"} ${idx % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'}`}>
                {formatValue(key, row[key])}
              </td>
            );
          })}
                              {/* Breakdown button cell */}
          <td className={`py-4 px-2 text-center sticky right-0 w-4 whitespace-nowrap ${idx % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'}`}> {/* Narrow width */}
            <button onClick={() => handleShowBreakdown(row, type)} className="flex justify-center items-center">
              {visibleBreakdowns[`${type}_${rowId}`] && visibleBreakdowns[`${type}_${rowId}`].isVisible
                ? <span className="text-white">▼</span> // Down arrow for 'Hide'
                : <span className="text-white">◄</span> // Right arrow for 'Show'
              }
            </button>
          </td>
        </tr>
        {/* Daily Data Breakdown */}
        {visibleBreakdowns[`${type}_${rowId}`]?.isVisible && (  // Correctly use row[idKey] to access the unique identifier
  <tr>
    <td colSpan={columns.length + 2}>
      <DailyDataBreakdown
        dailyData={visibleBreakdowns[`${type}_${rowId}`].data || []}  // Access the data propert
        type={type}
      />
    </td>
  </tr>
        )}
     </React.Fragment>
    );
  })}

  {/* Totals Row */}
  <tr className="sticky bottom-0 bg-gray-700 text-white text-center">
    <td className="px-6 py-4 sticky z-10 bg-gray-700 left-0">Totals</td>
    {columns.map(({ key }, index) => {
      // Check if it's the second column; index is 0-based, so the second column is index 
      if (['effective_status', 'campaign_name', 'adset_name', 'account_id', 'campaign_id', 'adset_id', 'ad_id'].includes(key)) {
        return <td key={key} className="py-4 px-8 sticky bottom-0 bg-gray-700"></td>;
      }
      const val = totals[key] || 0;  // Ensure you have a fallback of 0
      let formattedValue = formatValue(key, val);  // Format each value as necessary
      let cellStyle = {};

      if (['spend', 'roas', 'cpc'].includes(key)) {
        let color;
        if (key === 'roas') color = getColorForValueLog(val, roasPercentiles);
        else if (key === 'cpc') color = getColorForValueLog(val, cpcPercentiles, true);

        cellStyle = { backgroundColor: color, color: 'black', borderTop: '0.1px solid #000000' };
      }

      return (
        <td key={key} style={cellStyle} className={`py-4 px-8 sticky bottom-0 ${isStickyColumn(key) ? "text-left" : "text-center"}`}>
          {formattedValue}
        </td>
      );
    })}
    <td className="py-4 px-2 sticky right-0 bottom-0 w-4 bg-gray-700"></td> {/* Empty cell for the breakdown button column, also sticky */}
  </tr>
</tbody>
      </table>
    </div>
    <div>
  {allSelectedDailyData.length > 0 && (
    <AllBreakdown
      dailyData={allSelectedDailyData}
      type={type} // You might want to manage how to determine the type for AllBreakdown
    />
  )}
</div>
  </div>
);
};



const Fb = ({ data, offersData, effectiveStatus, vendorData }) => {
  const [selectedItem, setSelectedItem] = useState({ id: null, type: null });
  const [selectedItems, setSelectedItems] = useState([]);
  const [activeTable, setActiveTable] = useState(null);
  console.log('offers Fb', offersData); // Log to check what's received


  const adsStatusLookup = useMemo(() => effectiveStatus.ads.reduce((acc, item) => ({ ...acc, [item.id]: item.effective_status }), {}), [effectiveStatus.ads]);
  const campaignsStatusLookup = useMemo(() => effectiveStatus.campaigns.reduce((acc, item) => ({ ...acc, [item.id]: item.effective_status }), {}), [effectiveStatus.campaigns]);
  const adsetsStatusLookup = useMemo(() => effectiveStatus.adsets.reduce((acc, item) => ({ ...acc, [item.id]: item.effective_status }), {}), [effectiveStatus.adsets]);
  


  const handleSetActiveTable = (type) => {
      setActiveTable(type);
  };

      const handleRowClick = (id, type) => {

        if (selectedItem.id === id && selectedItem.type === type) {
          // If the same row is clicked again, reset the selection
          setSelectedItem({ id: null, type: null });
        } else {
          // Set the selected item
          setSelectedItem({ id, type });
          handleSetActiveTable(type);
        }
        // console.log("Toggled Item:", { id, type });
      };

      // console.log("Data received in Fb component:", data);
      // console.log("Effective Status", effectiveStatus);


      const filteredAccountsData = useMemo(() => {
        if (selectedItems.length > 0) {
          // Filter based on multiple selected items
          return data.adaccountsData.filter(account => 
            selectedItems.some(item => {
              if (item.type === 'account') {
                return account.account_id === item.id;
              } else if (item.type === 'campaign') {
                const accountId = data.campaignsData.find(c => c.campaign_id === item.id)?.account_id;
                return accountId === account.account_id;
              } else if (item.type === 'adset') {
                const accountId = data.adsetsData.find(a => a.adset_id === item.id)?.account_id;
                return accountId === account.account_id;
              } else if (item.type === 'ads') {
                const accountId = data.adsData.find(a => a.ad_id === item.id)?.account_id;
                return accountId === account.account_id;
              }
              return false;
            })
          );
        } else {
          // No selection, show all data
          return data.adaccountsData;
        }
      }, [selectedItems, data.adaccountsData, data.campaignsData, data.adsetsData, data.adsData]);
      
      
      const filteredCampaignsData = useMemo(() => {
        if (selectedItems.length > 0) {
          return data.campaignsData.filter(campaign =>
            selectedItems.some(item => {
              if (item.type === 'campaign') {
                return campaign.campaign_id === item.id;
              } else if (item.type === 'account') {
                return campaign.account_id === item.id;
              } else if (item.type === 'adset') {
                const campaignIdForAdset = data.adsetsData.find(adset => adset.adset_id === item.id)?.campaign_id;
                return campaignIdForAdset === campaign.campaign_id;
              } else if (item.type === 'ads') {
                const adsetForAd = data.adsData.find(ad => ad.ad_id === item.id)?.adset_id;
                const campaignIdForAd = data.adsetsData.find(adset => adset.adset_id === adsetForAd)?.campaign_id;
                return campaignIdForAd === campaign.campaign_id;
              }
              return false;
            })
          );
        } else {
          return data.campaignsData;
        }
        
      }, [selectedItems, selectedItem, data.campaignsData, data.adsetsData, data.adsData]);
      
      
      const filteredAdsetsData = useMemo(() => {
        if (selectedItems.length > 0) {
          return data.adsetsData.filter(adset =>
            selectedItems.some(item => {
              if (item.type === 'adset') {
                return adset.adset_id === item.id;
              } else if (item.type === 'account') {
                return adset.account_id === item.id;
              } else if (item.type === 'campaign') {
                return adset.campaign_id === item.id;
              } else if (item.type === 'ads') {
                // Find the adset_id for the selected ad
                const adsetForAd = data.adsData.find(ad => ad.ad_id === item.id)?.adset_id;
                return adset.adset_id === adsetForAd;
              }
              return false;
            })
          );
        } else {
          switch (selectedItem.type) {
            case 'account':
              return data.adsetsData.filter(adset => adset.account_id === selectedItem.id);
            case 'campaign':
              return data.adsetsData.filter(adset => adset.campaign_id === selectedItem.id);
            case 'ads':
              // Find the adset_id for the selected ad and filter adsets based on that
              const adsetForSelectedAd = data.adsData.find(ad => ad.ad_id === selectedItem.id)?.adset_id;
              return data.adsetsData.filter(adset => adset.adset_id === adsetForSelectedAd);
            default:
              return data.adsetsData;
          }
        }
      }, [selectedItems, selectedItem, data.adsetsData, data.adsData]);
      
      
      const filteredAdsData = useMemo(() => {
        if (selectedItems.length > 0) {
          return data.adsData.filter(ad =>
            selectedItems.some(item => {
              if (item.type === 'ads') {
                return ad.ad_id === item.id;
              } else if (item.type === 'account') {
                return ad.account_id === item.id;
                } else if (item.type === 'campaign') {
                const adsetIdsInCampaign = data.adsetsData.filter(adset => adset.campaign_id === item.id).map(adset => adset.adset_id);
                return adsetIdsInCampaign.includes(ad.adset_id);
                } else if (item.type === 'adset') {
                return ad.adset_id === item.id;
                }
                return false;
                })
                );
                } else {
                switch (selectedItem.type) {
                case 'account':
                return data.adsData.filter(ad => ad.account_id === selectedItem.id);
                case 'campaign':
                const adsetsInCampaign = data.adsetsData.filter(adset => adset.campaign_id === selectedItem.id);
                return data.adsData.filter(ad => adsetsInCampaign.some(adset => adset.adset_id === ad.adset_id));
                case 'adset':
                return data.adsData.filter(ad => ad.adset_id === selectedItem.id);
                default:
                return data.adsData;
                }
                }
                }, [selectedItems, selectedItem, data.adsData, data.adsetsData]);

      


      const filteredData = useMemo(() => {
        if (!selectedItem.id) return data; // Show all data if no selection
      
        switch (selectedItem.type) {
          case 'campaign':
            return data.adsetsData.filter(item => item.campaign_id === selectedItem.id);
          case 'adset':
            return data.adsData.filter(item => item.adset_id === selectedItem.id);
          case 'account':
            return data.campaignsData.filter(item => item.account_id === selectedItem.id);
          default:
            return data;
        }
      }, [selectedItem, data]);
      

      const { 
        adsData, 
        campaignsData, 
        adsetsData, 
        adaccountsData,
        dailyAdsData,           // Add this line
        dailyCampaignsData,     // Add this line
        dailyAdsetsData,        // Add this line
        dailyAccountsData,      // Add this line
        isLoading,
        totalProfit
    } = data;

    const adsetToCampaignLookup = useMemo(() => {
      const lookup = {};
      adsetsData.forEach(adset => {
        lookup[adset.adset_id] = adset.campaign_id;
      });
      return lookup;
    }, [adsetsData]);
    
    const mergedAdsCampaignIdDailyData = useMemo(() => {
      return dailyAdsData && Array.isArray(dailyAdsData) 
        ? dailyAdsData.map(item => {
            if (item.adset_id) {
              return {
                ...item,
                campaign_id: adsetToCampaignLookup[item.adset_id] || 'N/A'
              };
            }
            return item;
          })
        : []; // Return an empty array if dailyAdsData is undefined or not an array
    }, [dailyAdsData, adsetToCampaignLookup]);
    
//     console.log('dailyAdsData', dailyAdsData)   
// console.log('campaingids', mergedAdsCampaignIdDailyData)
  // Merge effectiveStatus with fbData

  const mergedFilteredAdsData = useMemo(() => {
    return filteredAdsData.map(ad => ({
      ...ad,
      effective_status: adsStatusLookup[ad.ad_id] || 'Unknown',
    }));
  }, [filteredAdsData, adsStatusLookup]);
  
  const mergedFilteredCampaignsData = useMemo(() => {
    return filteredCampaignsData.map(campaign => ({
      ...campaign,
      effective_status: campaignsStatusLookup[campaign.campaign_id] || 'Unknown',
    }));
  }, [filteredCampaignsData, campaignsStatusLookup]);
  
  const mergedFilteredAdsetsData = useMemo(() => {
    return filteredAdsetsData.map(adset => ({
      ...adset,
      effective_status: adsetsStatusLookup[adset.adset_id] || 'Unknown',
    }));
  }, [filteredAdsetsData, adsetsStatusLookup]);

//   useEffect(() => {
//     // console.log('Effective Control:', effectiveStatus);
//     // console.log('Ads Status Lookup:', adsStatusLookup);
//     // console.log('Campaigns Status Lookup:', campaignsStatusLookup);
//     // console.log('Adsets Status Lookup:', adsetsStatusLookup);
//     // console.log('Merged Ads Data:', mergedFilteredAdsData);
//     // console.log('Merged Campaigns Data:', mergedFilteredCampaignsData);
//     // console.log('Merged Adsets Data:', mergedFilteredAdsetsData);
// }, [effectiveStatus, adsStatusLookup, campaignsStatusLookup, adsetsStatusLookup, mergedFilteredAdsData, mergedFilteredCampaignsData, mergedFilteredAdsetsData]);
       
        if (isLoading) {
            return <Spinner />;
          }

          return (
            <div>
          <SummaryCardsContainer adaccountsData={adaccountsData} totalProfit={totalProfit}/>
          <VendorSummary vendorData={vendorData} />
              <div className="container mx-auto p-4">
              <Offers offersData={offersData} />
                <div className="my-4">
                  <DataTable 
                    data={filteredAccountsData} 
                    title="Ad Accounts Data" 
                    onRowClick={handleRowClick} 
                    type="account" 
                    selectedItem={selectedItem}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    dailyData={dailyAccountsData}


                  />
                </div>
          
                <div className="my-4">
                  <DataTable 
                    data={mergedFilteredCampaignsData} 
                    title="Campaigns Data" 
                    onRowClick={handleRowClick} 
                    type="campaign" 
                    selectedItem={selectedItem}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    dailyData={dailyCampaignsData}

                  />
                </div>
          
                <div className="my-4">
                  <DataTable 
                    data={mergedFilteredAdsetsData} 
                    title="Ad Sets Data" 
                    onRowClick={handleRowClick} 
                    type="adset" 
                    selectedItem={selectedItem}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    dailyData={dailyAdsetsData}


                  />
                </div>
          
                <div className="my-4">
                  <DataTable 
                    data={mergedFilteredAdsData} 
                    title="Ads Data" 
                    onRowClick={handleRowClick} 
                    type="ads" 
                    selectedItem={selectedItem}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    dailyData={mergedAdsCampaignIdDailyData}

                  />
                </div>
              </div>

        </div>
    );
};

export default Fb;
