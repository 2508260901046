"use client";
import React, { useEffect, useContext, useState } from "react";
import { ReportAnimatedTooltip} from './reportprofilerows'
import ReportContext from './ReportContext'; // Ensure correct path


function ReportAnimatedTooltipPreview() {
    // Initialize state for editor profiles
    const [editorProfiles, setEditorProfiles] = useState([]);
    const { setSelectedEditor } = useContext(ReportContext);

    const selectEditor = (editor) => {
      setSelectedEditor(editor);
    };

    // Fetch editor profiles and update state
    useEffect(() => {
      const fetchEditorProfiles = async () => {
        try {
    const response = await fetch('https://roasbooster.com/api/editor-profiles');
          const data = await response.json();
          setEditorProfiles(data.map(profile => ({
            id: profile.id,
            name: profile.name,
            image: profile.image_url,
            // Add other properties as needed
          })));
        } catch (error) {
          console.error('Failed to fetch editor profiles:', error);
        }
      };
    
      fetchEditorProfiles();
    }, []);
  
    // Pass editorProfiles as items prop to AnimatedTooltip
    return (
      <div className="flex flex-row items-center justify-center mt-20 w-full">
        <ReportAnimatedTooltip items={editorProfiles} onSelectEditor={selectEditor} />
      </div>
    );
  }
  
  export default ReportAnimatedTooltipPreview;
