import React, { useContext, useState } from "react";
import ReportContext from "./ReportContext"; // Adjust the import path as necessary
import WeekSelector from "./WeekSelector";
import { CountUpStats } from "./CountUpStats";
import TabsFeatures from "./ReportTable";
import ShuffleCards from "./CardEditors";
import ReportAnimatedTooltipPreview from "./ReportAnimatedProfileRows";
import EditorTable from "./reportEditorTable";
import Loader from "./Loader";
export const Report = () => {
    const { data } = useContext(ReportContext); 
    const [selectedProfileIndex, setSelectedProfileIndex] = useState(null);

    // Ensure data.summaryTotals exists before rendering CountUpStats
    if (!data.summaryTotals) {
      return <> <Loader /> </> // Or any other loading state representation
    }
  
    return (
      <>
        <WeekSelector />
        <CountUpStats 
          totalSpend={data.summaryTotals.total_spend || 0} // Provide fallbacks
          roas={data.summaryTotals.total_roas || 0} 
          numWinners={data.totalWinnerCount || 0} // Use the new totalWinnerCount property
        />
      <TabsFeatures customPeriodData={data.customPeriodData} decodedInfoData={data.decodedInfoData} adsInfoData={data.adsInfoData} editorProfiles={data.editorProfiles}/> 
      <ReportAnimatedTooltipPreview />
      <ShuffleCards decodedInfoData={data.decodedInfoData} editorProfiles={data.editorProfiles} />
      <EditorTable decodedInfoData={data.decodedInfoData} editorProfiles={data.editorProfiles} />
</>
    );
  };
  
  
export default Report;
