import React, { useEffect } from 'react';
import moment from 'moment-timezone';

const DateSelectorEditorTop10 = ({ setStartDate, setEndDate }) => {
  const timezone = 'America/Los_Angeles';

  useEffect(() => {
    setLast7Days(); // Set default to last 7 days when the component mounts
  }, []);

  const setLast7Days = () => {
    const start = moment().tz(timezone).subtract(6, 'days').startOf('day').toDate();
    const end = moment().tz(timezone).endOf('day').toDate();
    setStartDate(start);
    setEndDate(end);
  };

  const setLast14Days = () => {
    const start = moment().tz(timezone).subtract(13, 'days').startOf('day').toDate();
    const end = moment().tz(timezone).endOf('day').toDate();
    setStartDate(start);
    setEndDate(end);
  };

  const setLast30Days = () => {
    const start = moment().tz(timezone).subtract(29, 'days').startOf('day').toDate();
    const end = moment().tz(timezone).endOf('day').toDate();
    setStartDate(start);
    setEndDate(end);
  };

  const setLast90Days = () => {
    const start = moment().tz(timezone).subtract(89, 'days').startOf('day').toDate();
    const end = moment().tz(timezone).endOf('day').toDate();
    setStartDate(start);
    setEndDate(end);
  };

  const handleSelectChange = (event) => {
    switch (event.target.value) {
      case 'last7Days':
        setLast7Days();
        break;
      case 'last14Days':
        setLast14Days();
        break;
      case 'last30Days':
        setLast30Days();
        break;
      case 'last90Days':
        setLast90Days();
        break;
      default:
        break;
    }
  };

  return (
    <div className="relative w-64">
      <select
        onChange={handleSelectChange}
        className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded-lg shadow leading-tight focus:outline-none focus:ring-blue-300 focus:border-blue-300"
      >
        <option value="last7Days">Last 7 Days</option>
        <option value="last14Days">Last 14 Days</option>
        <option value="last30Days">Last 30 Days</option>

        <option value="last90Days">Last 90 Days</option>
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M5.3 7.7L10 12.4l4.7-4.7 1.4 1.4-6.1 6.1-6.1-6.1z"/>
        </svg>
      </div>
    </div>
  );
};

export default DateSelectorEditorTop10;
