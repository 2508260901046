import { FiSearch, FiLoader } from "react-icons/fi";
import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, subDays } from "date-fns";
import { animate } from "framer-motion";
import Header from "./Header";
const Jeremy = () => {
    const [startDate, setStartDate] = useState(subDays(new Date(), 8)); // Default to last 7 days
    const [subheading, setSubheading] = useState("Spent on FB Jeremy for last 7 days");
    const [endDate, setEndDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [totalSpend, setTotalSpend] = useState(0);
    const [userRole, setUserRole] = useState('');
    const [loadingButton, setLoadingButton] = useState(null); // State to track the loading button
    const [isLoading, setIsLoading] = useState(false); // State to track loading

    useEffect(() => {
        const fetchDefaultData = async () => {
          const formattedStart = format(subDays(new Date(), 8), 'yyyy-MM-dd');
          const formattedEnd = format(new Date(), 'yyyy-MM-dd');
          await fetchData(formattedStart, formattedEnd);
        };
        fetchDefaultData();
      }, []);
      

    useEffect(() => {
        const role = localStorage.getItem('userRole');
        setUserRole(role);
    }, []);

  
    const fetchData = async (start, end) => {
        setIsLoading(true); // Set loading to true when fetching starts
        try {
          const response = await fetch(`/api/jeremy?startDate=${start}&endDate=${end}`);
          const result = await response.json();
          console.log(result);
          setData(result);
      
          // Calculate the total spend
          const total = result.reduce((sum, item) => sum + (parseFloat(item.total_spend) || 0), 0);
          setTotalSpend(total);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setIsLoading(false); // Set loading to false when fetching ends
        }
      };
      
  
      const setPresetDate = async (days) => {
        setLoadingButton(days); // Set the loading button
        const end = subDays(new Date(), 1); // Setting end to yesterday
        const start = days === 1 ? end : subDays(end, days - 1); // Adjust start date for yesterday
        setStartDate(start);
        setEndDate(end);
        const formattedStart = format(start, 'yyyy-MM-dd');
        const formattedEnd = format(end, 'yyyy-MM-dd');
        await fetchData(formattedStart, formattedEnd);
        // Update subheading based on the selected preset date range
        if (days === 1) {
            setSubheading("Spent on FB Jeremy for yesterday");
        } else if (days === 8) {
            setSubheading("Spent on FB Jeremy for last 7 days");
        } else if (days === 31) {
            setSubheading("Spent on FB Jeremy for last 30 days");
        }
        setLoadingButton(null); // Reset the loading button
    };
    
    const handleFetch = () => {
        setLoadingButton('fetch'); // Set the loading button for fetch
        const formattedStart = format(startDate, 'yyyy-MM-dd');
        const formattedEnd = format(endDate, 'yyyy-MM-dd');
        fetchData(formattedStart, formattedEnd);
        setSubheading(`Spent on FB Jeremy from ${formattedStart} to ${formattedEnd}`);
        setLoadingButton(null); // Reset the loading button
    };
    
      const columns = [
        { key: 'total_spend', label: 'Spend' },
        // Add other columns as needed
      ];
      return (
        <div>
         <Header userRole={userRole} />
        <section className="p-4">
          <div className="my-4 flex justify-center">
          <JeremyTotal totalSpend={totalSpend} subheading={subheading} />
          </div>
      
          <div className="mx-auto max-w-[1600px]">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 mb-4">
  <div className="flex flex-wrap items-center justify-center space-x-2 md:space-x-4">
    <button
      onClick={() => setPresetDate(1)}
      className="relative px-4 py-2 text-sm font-medium bg-indigo-500 text-white transition-all shadow-[3px_3px_0px_black] hover:shadow-none hover:translate-x-[3px] hover:translate-y-[3px] flex items-center justify-center"
      disabled={isLoading} // Disable the button when loading
    >
      <span className="inline-block" style={{ visibility: loadingButton === 1 ? 'hidden' : 'visible' }}>Yesterday</span>
      {loadingButton === 1 && <FiLoader className="animate-spin absolute" />}
    </button>
    <button
      onClick={() => setPresetDate(8)}
      className="relative px-4 py-2 text-sm font-medium bg-indigo-500 text-white transition-all shadow-[3px_3px_0px_black] hover:shadow-none hover:translate-x-[3px] hover:translate-y-[3px] flex items-center justify-center"
      disabled={isLoading} // Disable the button when loading
    >
      <span className="inline-block" style={{ visibility: loadingButton === 8 ? 'hidden' : 'visible' }}>Last 7 Days</span>
      {loadingButton === 8 && <FiLoader className="animate-spin absolute" />}
    </button>
    <button
      onClick={() => setPresetDate(31)}
      className="relative px-4 py-2 text-sm font-medium bg-indigo-500 text-white transition-all shadow-[3px_3px_0px_black] hover:shadow-none hover:translate-x-[3px] hover:translate-y-[3px] flex items-center justify-center"
      disabled={isLoading} // Disable the button when loading
    >
      <span className="inline-block" style={{ visibility: loadingButton === 31 ? 'hidden' : 'visible' }}>Last 30 Days</span>
      {loadingButton === 31 && <FiLoader className="animate-spin absolute" />}
    </button>
  </div>
  <div className="hidden md:flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 md:flex-end">
    <DatePicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      className="text-black bg-white rounded-md shadow-sm border border-gray-300 w-32"
      dateFormat="yyyy-MM-dd"
    />
    <DatePicker
      selected={endDate}
      onChange={(date) => setEndDate(date)}
      className="text-black bg-white rounded-md shadow-sm border border-gray-300 w-32"
      dateFormat="yyyy-MM-dd"
    />
    <button
      onClick={handleFetch}
      className="relative px-4 py-2 text-sm font-medium bg-indigo-500 text-white transition-all shadow-[3px_3px_0px_black] hover:shadow-none hover:translate-x-[3px] hover:translate-y-[3px] flex items-center justify-center"
      disabled={isLoading} // Disable the button when loading
    >
      <span className="inline-block" style={{ visibility: loadingButton === 'fetch' ? 'hidden' : 'visible' }}>Fetch</span>
      {loadingButton === 'fetch' && <FiLoader className="animate-spin absolute" />}
    </button>
  </div>
</div>




      
            <AnimatePresence mode="wait">
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                key={0}
              >
                <ExampleFeature
                  Icon={FiSearch}
                  dataToShow={data}
                  columns={columns}
                />
              </motion.div>
            </AnimatePresence>
          </div>
        </section>
        </div>

      );
      };
      
      

      const getFormattedValue = (item, column) => {
        const value = item[column.key];
        if (value === undefined) {
          return 'N/A'; // or any placeholder you prefer
        }
        if (column.key === 'total_spend') {
          return `$${parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        }
        return column.formatter ? column.formatter(value) : value;
      };
      
      const ExampleFeature = ({ Icon, dataToShow, columns }) => {
        // Sort the data by total_spend in descending order
        const sortedData = [...dataToShow].sort((a, b) => parseFloat(b.total_spend) - parseFloat(a.total_spend));
      
        return (
          <div className="w-full px-2 py-4 md:px-8 lg:py-6">
            <div className="relative w-full rounded-xl bg-slate-800 shadow-xl">
              <div className="flex w-full gap-1.5 rounded-t-xl bg-slate-900 p-3">
                <div className="h-3 w-3 rounded-full bg-red-500" />
                <div className="h-3 w-3 rounded-full bg-yellow-500" />
                <div className="h-3 w-3 rounded-full bg-green-500" />
              </div>
              <div className="overflow-auto p-2" style={{ maxHeight: '500px' }}>
                {sortedData.length > 0 ? (
                  <table className="w-full text-sm text-left text-white">
                    <thead className="text-xs text-white uppercase bg-slate-700">
                      <tr>
                        <th scope="col" className="px-2 py-3 md:px-7 text-left">Ad Name</th>
                        {columns.map((column) => (
                          <th key={column.key} scope="col" className="px-2 py-3 md:px-7 text-center">{column.label}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {sortedData.map((item, index) => (
                        <tr key={index} className={`border-b border-slate-700 ${index % 2 === 0 ? 'bg-slate-700' : 'bg-slate-600'}`}>
                          <td className="px-2 py-4 md:px-7 text-left">{item.ad_name}</td>
                          {columns.map((column) => (
                            <td key={column.key} className="px-2 py-4 md:px-7 text-center">{getFormattedValue(item, column)}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className="text-white">No data available</p>
                )}
              </div>
            </div>
          </div>
        );
      };      

// Correct placement of Stat component
const Stat = ({ num, suffix, decimals = 0, subheading, isCurrency = false }) => {
  const ref = useRef(null);

  useEffect(() => {
    const numericValue = parseFloat(num) || 0;
    const formatter = isCurrency
      ? new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        })
      : {
          format: (value) => value.toFixed(decimals),
        };

    if (ref.current) {
      const controls = animate(0, numericValue, {
        duration: 0.5,
        onUpdate(value) {
          if (ref.current) {
            ref.current.textContent = formatter.format(value) + (suffix && !isCurrency ? suffix : '');
          }
        },
      });

      return () => controls.stop();
    }
  }, [num, decimals, suffix, isCurrency]);

  return (
    <div className="flex w-72 flex-col items-center py-4 sm:py-0">
      <p className="mb-2 text-center text-7xl font-semibold sm:text-6xl">
        <span ref={ref}></span>
      </p>
      <p className="text-center text-neutral-600">{subheading}</p>
    </div>
  );
};

export const JeremyTotal = ({ totalSpend, subheading }) => {
    return (
      <div className="mx-auto max-w-3xl px-4 py-10 md:py-12">
        <div className="flex flex-col items-center justify-center">
          <Stat
            num={totalSpend}
            isCurrency={true} // Indicate that this stat should be formatted as currency
            subheading={subheading} // Use the dynamic subheading prop
          />
        </div>
      </div>
    );
  };
  

export default Jeremy;
