import React, { useEffect, useState } from "react";
import './Youtube.css'
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-plugin-annotation';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


const Youtube = ({ data }) => {
    const [editorsAds, setEditorsAds] = useState({});
    const [editorsTopAds, setEditorsTopAds] = useState({});

    const nameMapping = {
        'MRL': 'Marlin',
        'PHL': 'Phil',
        'ELN': 'Eileen',
        'ALF': 'Alfred',
        'OMR': 'Omar',
        'CHR': 'Charles',
        'CRL': 'Carl',
        'NA': 'Unknown',
        'JSH': 'Joshua',
        'ALL': 'Alladin',
        'ALM': 'Almeer',
        'MAT': 'Mat'
    };

    console.log(data)
    useEffect(() => {
        if (data && Array.isArray(data.adsData) && data.adsData.length > 0) {
            const editors = data.adsData.reduce((acc, ad) => {
                if (ad.ad_name && typeof ad.ad_name === 'string') {
                    const parts = ad.ad_name.split('_');
                    const abbreviation = parts.length > 4 ? parts[4] : 'Unknown';
                    const editorName = nameMapping[abbreviation] || abbreviation;
    
                    const adIdentifier = parts[0]; // Extract the unique identifier
    
                    if (!acc[editorName]) {
                        acc[editorName] = {
                            ads: [],
                            totalSpend: 0,
                            totalProfit: 0,
                            totalRevenue: 0,
                            totalROAS: 0,
                        };
                    }
    
                    acc[editorName].ads.push({
                        ...ad,
                        adIdentifier // Add the identifier to the ad object
                    });
    
                    acc[editorName].totalSpend += parseFloat(ad.total_spend) || 0;
                    acc[editorName].totalProfit += parseFloat(ad.profit) || 0;
                    acc[editorName].totalRevenue += parseFloat(ad.total_revenue) || 0;
                }
                return acc;
            }, {});
    
            // Calculate Total ROAS for each editor
            Object.entries(editors).forEach(([editorName, editorData]) => {
                if (editorData.totalSpend !== 0) {
                    editorData.totalROAS = (editorData.totalRevenue / editorData.totalSpend).toFixed(2) || 0;
                } else {
                    editorData.totalROAS = 0; // Avoid division by zero
                }
            });
    
            setEditorsAds(editors);
        }
    }, [data]);


    useEffect(() => {
        if (data && Array.isArray(data.adsData) && data.adsData.length > 0) {
            const editors = data.adsData.reduce((acc, ad) => {
                const parts = ad.ad_name.split('_');
                const abbreviation = parts.length > 4 ? parts[4] : 'Unknown';
                const editorName = nameMapping[abbreviation] || abbreviation;
    
                if (!acc[editorName]) {
                    acc[editorName] = [];
                }
    
                const adIdentifier = parts[0]; // Extract the unique identifier ADXXXXvX
                acc[editorName].push({
                    ...ad,
                    adIdentifier, // Add the identifier to the ad object
                    totalSpend: parseFloat(ad.total_spend),
                    roas: parseFloat(ad.roas)
                });
                return acc;
            }, {});
    
            const editorsTopAds = Object.fromEntries(
                Object.entries(editors).map(([editorName, ads]) => {
                    // Sort ads by totalSpend and roas to find the top performing ads
                    const sortedBySpend = [...ads].sort((a, b) => b.totalSpend - a.totalSpend);
                    const sortedByRoas = [...ads].sort((a, b) => b.roas - a.roas);
    
                    // Take the top ad from each sorting method, ensuring they are unique
                    const topSpendAd = sortedBySpend[0];
                    const topRoasAd = sortedByRoas.find(ad => ad.ad_id !== topSpendAd.ad_id) || sortedByRoas[0];
    
                    return [editorName, [topSpendAd, topRoasAd]];
                })
            );
    
            setEditorsTopAds(editorsTopAds);
        }
    }, [data]);    

    if (data.isLoading) {
        return <div>Loading...</div>;
    }

    if (!data.adsData || data.adsData.length === 0) {
        return <div>No ads data available</div>;
    }

    const summary = Object.entries(editorsAds).map(([editor, editorData]) => ({
        name: editor,
        count: editorData.ads.length,
        totalSpend: editorData.totalSpend.toFixed(2),
        totalProfit: editorData.totalProfit.toFixed(2),
        totalROAS: editorData.totalROAS, // Display the total ROAS here
    }));

    const chartOptions = {
        scales: {
            y: {
                min: 0,
                max: 1000,
                ticks: {
                    color: (context) => {
                        // Check if the tick value is 1000
                        if (context.tick.value === 1000) {
                            return 'gold'; // Gold color for the 1000 tick
                        }
                        return '#666'; // Default color for other ticks
                    },
                    font: {
                        size: 12, // Set default font size for all ticks
                        weight: 'normal', // Set default font weight for all ticks
                    }
                },
                afterBuildTicks: (scale) => {
                    // Loop through all ticks and customize the one that's 1000
                    scale.ticks.forEach(tick => {
                        if (tick.value === 1000) {
                            tick.font = {
                                weight: 'bold', // Bold for the 1000 tick
                                size: 16 // Larger font size for the 1000 tick
                            };
                        }
                    });
                }
            }
        },
        plugins: {
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        yMin: 1000,
                        yMax: 1000,
                        borderColor: 'rgb(255, 99, 132)',
                        borderWidth: 2,
                        label: {
                            content: 'Goal: 1000',
                            enabled: true,
                            position: 'end'
                        }
                    }
                }
            }
        },
        maintainAspectRatio: true 
    };

    const generateChartData = () => {
        const labels = Object.keys(editorsTopAds);
        const data = {
            labels,
            datasets: [{
                label: 'Spend',
                data: labels.map(label => editorsTopAds[label][0]?.totalSpend || 0), // Use optional chaining and fallback to 0
                backgroundColor: 'rgb(60, 130, 246)',
            }],
        };
        return data;
    };
    

    function getOrdinal(n) {
        const s = ["th", "st", "nd", "rd"],
              v = n % 100;
        return s[(v - 20) % 10] || s[v] || s[0];
    }

    function calculateProgress(value, target) {
        return (value / target * 100).toFixed(2);
    }
    
    
return (
<div className="container mx-auto mt-10 p-5">
    <div className="flex flex-wrap -mx-3">
        {/* Leaderboard Section */}
        <div className="w-full md:w-1/3 lg:w-1/4 bg-white rounded-lg ">
            <h2 className="text-2xl font-bold text-center sticky top-0 bg-white p-2 z-10">Highest Spend</h2>
            <div className="overflow-y-auto" style={{ height: 'calc(100px * 4 + 1rem)' }}>
                <div className="space-y-4 p-4">
                    {Object.entries(editorsTopAds).sort((a, b) => b[1][0].totalSpend - a[1][0].totalSpend).map(([editorName, ads], index) => (
                        <div key={index} className="bg-gray-100 p-4 rounded-lg shadow flex items-center">
                            <span className={`text-lg font-semibold mr-4 ${index === 0 ? 'text-gold' : index === 1 ? 'text-silver' : index === 2 ? 'text-bronze' : ''}`}>
                                {`${index + 1}${getOrdinal(index + 1)}`}
                            </span>
                            <div className="flex-grow">
                                <div className="flex justify-between items-center">
                                    <span className="text-lg font-semibold">{editorName}</span>
                                </div>
                                <div className="w-full bg-gray-300 rounded-full h-2.5 dark:bg-gray-700 mt-2 overflow-hidden">
                                    <div
                                        className="bg-blue-500 h-2.5 rounded-full"
                                        style={{ width: `${calculateProgress(ads[0].totalSpend, 1000)}%` }}
                                    ></div>
                                </div>
                                <div className="flex justify-between mt-2 text-sm font-semibold">
                                    <span className="truncate max-w-[calc(100%-100px)]">{ads[0].adIdentifier}</span>
                                    <span>${ads[0].totalSpend.toFixed(2)} spent</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

            {/* Performance Comparison Chart */}
        <div className="w-full md:w-2/3 lg:w-3/4 px-3">
            <h2 className="text-2xl font-bold text-center mb-5">Editors Performance Comparison</h2>
<Bar data={generateChartData()} options={chartOptions} />
        </div>
     </div>

            {/* Editors and Ads Table */}
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {Object.keys(editorsAds).map((editorName, index) => (
            <div key={index} style={{ flex: '1 1 30%', padding: '10px', boxSizing: 'border-box' }}>
                <h2 className="text-lg font-bold">{editorName}</h2>
                <div className="overflow-x-auto" style={{ maxHeight: '360px', overflowY: 'auto' }}>
                    <table className="min-w-full border bg-white">
                        <thead>
                            <tr>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Ad ID</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Total Spend</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">ROAS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[...editorsAds[editorName].ads]
                                .sort((a, b) => b.total_spend - a.total_spend)
                                .map((ad, adIndex) => (
                                    <tr key={adIndex} className="text-gray-700">
                                        <td className="text-left py-3 px-4">{ad.adIdentifier}</td>
                                        <td className="text-left py-3 px-4">{ad.total_spend}</td>
                                        <td className="text-left py-3 px-4" 
                                            style={{ color: ad.roas >= 2 ? 'green' : 'red' }}>
                                            {Number(ad.roas).toFixed(2)}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        ))}
    </div>
        </div>
    );
};

export default Youtube;
