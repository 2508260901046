import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
import Users from './Users';
import Login from './Login';
import AdminDashboard from './AdminDashboard';
import Fb from './fb';
import Together from './together';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component
import YT from './YT';
import Stats from './Stats';
import VideoEditors from './VideoEditors';
import AnimatedTooltipPreview from './AnimatedProfileRows';
import EditorProfile from './EditorProfile';
import { EditorsProvider } from './EditorsDataContext';
import Report from './Report';
import ReportProvider from './ReportProvider';
import EditorsManage from './EditorsManage';
import WeeklyStatsTable from './Weekly';
import Voluum from './Voluum';
import Jeremy from './Jeremy';
import NativeFb from './NativeFb';
import QueryForm from './QueryForm';
import ReportManager from './ReportManager';

function App() {
  return (
    <Router>
     <EditorsProvider>
 <Routes>
        <Route path='/stats' element={<ProtectedRoute allowedRoles={['admin', 'user', 'zainab']}><Stats /></ProtectedRoute>} />
        <Route path="/" element={<ProtectedRoute allowedRoles={['admin', 'user', 'zainab', 'jeremy']}><Together/></ProtectedRoute>} />
        <Route path="/videoeditors" element={<ProtectedRoute allowedRoles={['videoeditor', 'admin', 'user', 'zainab']}><VideoEditors /></ProtectedRoute>} />
        <Route path="/profile/:editorName" element={<ProtectedRoute allowedRoles={['videoeditor', 'admin', 'user', 'zainab']}><EditorProfile /></ProtectedRoute>} />
        <Route path="/users" element={<ProtectedRoute allowedRoles={['admin']}><Users/></ProtectedRoute>} />
        <Route path="/login" element={<Login />} />
        <Route path="/native" element={<ProtectedRoute allowedRoles={['admin']}><Voluum /></ProtectedRoute>} />
        <Route path="/nativefb" element={<ProtectedRoute allowedRoles={['admin']}><NativeFb /></ProtectedRoute>} />
        <Route path="/jeremy" element={<ProtectedRoute allowedRoles={['jeremy', 'admin']}><Jeremy /></ProtectedRoute>} />
        <Route path="/nativesheet" element={<ProtectedRoute allowedRoles={['admin']}><QueryForm /></ProtectedRoute>} />
        <Route path="/admin-dashboard" element={<ProtectedRoute allowedRoles={['admin']}><AdminDashboard /></ProtectedRoute>} />
        <Route path="/manage-editors" element={<ProtectedRoute allowedRoles={['admin', 'zainab']}><EditorsManage/></ProtectedRoute>} />
        <Route path="/reportmanager" element={<ProtectedRoute allowedRoles={['admin']}><ReportManager /></ProtectedRoute>} />
        <Route path="/weekly" element={<ProtectedRoute allowedRoles={['admin']}><WeeklyStatsTable/></ProtectedRoute>} />
        <Route path='/report' element={
            <ProtectedRoute allowedRoles={['admin', 'user', 'videoeditor', 'zainab']}>
              <ReportProvider>
                <Report />
              </ReportProvider>
            </ProtectedRoute>
          } />
      </Routes>
    </EditorsProvider>
    </Router>
  );
}

export default App;
