import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'tailwindcss/tailwind.css';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Header from './Header';
import { subDays } from 'date-fns';

const Voluum = () => {
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [newCode, setNewCode] = useState('');
    const [newKeyword, setNewKeyword] = useState('');
    const [codeType, setCodeType] = useState('product');
    const [productColors, setProductColors] = useState({});
    const [angleColors, setAngleColors] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userRole, setUserRole] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

    const predefinedProductColors = [
        'bg-red-100', 'bg-blue-100', 'bg-green-100', 'bg-yellow-100',
        'bg-purple-100', 'bg-pink-100', 'bg-indigo-100', 'bg-gray-100',
        'bg-teal-100', 'bg-orange-100'
    ];

    const predefinedAngleColors = [
        'bg-cyan-100', 'bg-amber-100', 'bg-lime-100', 'bg-emerald-100',
        'bg-rose-100', 'bg-violet-100', 'bg-fuchsia-100', 'bg-teal-100',
        'bg-sky-100', 'bg-mint-100'
    ];


    const fetchCodes = async () => {
        try {
            const productResponse = await axios.get('/api/product-codes');
            const angleResponse = await axios.get('/api/angle-codes');
            const productColors = {};
            const angleColors = {};

            productResponse.data.forEach((product, index) => {
                productColors[product.code] = predefinedProductColors[index % predefinedProductColors.length];
            });

            angleResponse.data.forEach((angle, index) => {
                angleColors[angle.code] = predefinedAngleColors[index % predefinedAngleColors.length];
            });

            setProductColors(productColors);
            setAngleColors(angleColors);
        } catch (error) {
            console.error('Error fetching codes', error);
        }
    };

    const fetchData = async (start, end) => {
        if (!start || !end) {
            return;
        }
    
        try {
            const response = await axios.get('/api/voluum', {
                params: { startDate: start.toISOString().split('T')[0], endDate: end.toISOString().split('T')[0] },
            });
            setData(response.data);
            console.log(data)
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        const savedStartDate = sessionStorage.getItem('startDate');
        const savedEndDate = sessionStorage.getItem('endDate');
        const today = new Date();
    
        const initialStartDate = savedStartDate ? new Date(savedStartDate) : today;
        const initialEndDate = savedEndDate ? new Date(savedEndDate) : today;
    
        setStartDate(initialStartDate);
        setEndDate(initialEndDate);
        fetchCodes();
        fetchData(initialStartDate, initialEndDate);
    }, []);

    const handleFetchData = () => {
        sessionStorage.setItem('startDate', startDate.toISOString().split('T')[0]);
        sessionStorage.setItem('endDate', endDate.toISOString().split('T')[0]);
        fetchData(startDate, endDate);
    };

    const addCode = async () => {
        try {
            const endpoint = codeType === 'product' ? '/api/product-codes' : '/api/angle-codes';
            await axios.post(endpoint, { code: newCode, keyword: newKeyword });
            setNewCode('');
            setNewKeyword('');
            setIsModalOpen(false);

            // Update colors immediately
            const newColor = codeType === 'product'
                ? predefinedProductColors[Object.keys(productColors).length % predefinedProductColors.length]
                : predefinedAngleColors[Object.keys(angleColors).length % predefinedAngleColors.length];

            if (codeType === 'product') {
                setProductColors({ ...productColors, [newCode]: newColor });
            } else {
                setAngleColors({ ...angleColors, [newCode]: newColor });
            }

            // Fetch updated codes and data
            await fetchCodes();
            await fetchData(startDate, endDate);
        } catch (error) {
            console.error('Error adding code', error);
        }
    };

    const parseValue = (value) => {
        if (typeof value === 'string') {
            if (value.includes('$')) {
                return parseFloat(value.replace(/[^0-9.-]+/g, ""));
            }
            if (value.includes('%')) {
                return parseFloat(value.replace(/[^0-9.-]+/g, "")) / 100;
            }
        }
        return parseFloat(value);
    };
    
    const groupAndSortData = (data) => {
        if (sortConfig.key) {
            return [...data].sort((a, b) => {
                let aValue = parseValue(a[sortConfig.key]);
                let bValue = parseValue(b[sortConfig.key]);

                if (isNaN(aValue)) {
                    aValue = 0;  // or another value you consider appropriate
                }
                if (isNaN(bValue)) {
                    bValue = 0;  // or another value you consider appropriate
                }
    
                if (sortConfig.direction === 'ascending') {
                    return aValue > bValue ? 1 : -1;
                }
                return aValue < bValue ? 1 : -1;
            });
        }
    
        const groupedData = data.reduce((acc, item) => {
            if (!acc[item.product_code]) {
                acc[item.product_code] = [];
            }
            acc[item.product_code].push(item);
            return acc;
        }, {});
    
        const sortedGroups = Object.keys(groupedData).sort((a, b) => {
            return groupedData[b].length - groupedData[a].length;
        }).map(productCode => {
            const angleGroupedData = groupedData[productCode].reduce((acc, item) => {
                const key = `${item.product_code}-${item.angle_code}`;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(item);
                return acc;
            }, {});
    
            const sortedAngleGroups = Object.values(angleGroupedData).sort((a, b) => b.length - a.length);
            return sortedAngleGroups.flat();
        });
    
        return sortedGroups.flat();
    };

    const openModal = (type) => {
        setCodeType(type);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setNewCode('');
        setNewKeyword('');
    };

    const formatCurrency = (value) => {
        const num = Number(value);
        if (isNaN(num)) {
            return 'N/A';
        }
        const formattedValue = num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        if (num < 0) {
            return `-$${formattedValue.slice(1)}`;
        }
        return `$${formattedValue}`;
    };

    const formatPercentage = (value) => {
        const number = Number(value) || 0;
        return `${number.toFixed(2)}%`;
    };

    const formatNumber = (value) => {
        const num = Number(value);
        return !isNaN(num) ? num.toLocaleString('en-US', { maximumFractionDigits: 2 }) : 'N/A';
    };

    

    const handleSort = (key) => {
        let direction = 'descending'; // Default to descending on the first click
        if (sortConfig.key === key) {
            if (sortConfig.direction === 'descending') {
                direction = 'ascending'; // Switch to ascending on the second click
            } else if (sortConfig.direction === 'ascending') {
                direction = null; // Remove sorting on the third click
                key = null;
            }
        }
        setSortConfig({ key, direction });
    };

    const getSortIndicator = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '↑' : '↓';
        }
        return '';
    };


    const calculateTotals = () => {
        const totals = {
            unique_visits: 0,
            unique_clicks: 0,
            conversions: 0,
            revenue: 0,
            cost: 0,
            ctr: 0,
            cpa: 0,
            aov: 0,
            cr: 0,
            profits: {},
            profitPercentage: 0
        };
    
        data.forEach(row => {
            totals.unique_visits += parseFloat(row.unique_visits) || 0;
            totals.unique_clicks += parseFloat(row.unique_clicks) || 0;
            totals.conversions += parseFloat(row.conversions) || 0;
            totals.revenue += parseFloat(row.revenue) || 0;
            totals.cost += parseFloat(row.cost) || 0;
    
            const { profit } = calculateProfitAndPercentage(row.product_code, parseFloat(row.revenue) || 0, parseFloat(row.cost) || 0);
    
            if (!totals.profits[row.product_code]) {
                totals.profits[row.product_code] = 0;
            }
    
            totals.profits[row.product_code] += profit;
        });
    
        totals.ctr = (totals.unique_clicks / totals.unique_visits) * 100;
        totals.cpa = totals.cost / totals.conversions;
        totals.aov = totals.revenue / totals.conversions;
        totals.cr = (totals.conversions / totals.unique_visits) * 100;
        totals.profit = Object.values(totals.profits).reduce((acc, curr) => acc + curr, 0);
        totals.profitPercentage = (totals.profit / totals.revenue) * 100;
    
        return totals;
    };
    
    const calculateProfitAndPercentage = (productCode, revenue, cost) => {
        let profit = 0;
        let profitPercentage = 0;
    
        switch (productCode) {
            case 'LAC':
                profit = (revenue * 0.62) - cost;
                break;
            case 'FTM':
                profit = (revenue * 0.77) - cost;
                break;
            case 'WBM':
                profit = (revenue * 0.7) - cost;
                break;
            case 'HNM':
                profit = (revenue * 0.57) - cost;
                break;
            default:
                profit = revenue - cost;
                break;
        }
    
        profitPercentage = profit / revenue;
        return { profit, profitPercentage };
    };
    
    const totals = calculateTotals();

    const presetOptions = [
        { label: 'Today', start: new Date(), end: new Date() },
        { label: 'Yesterday', start: subDays(new Date(), 1), end: subDays(new Date(), 1) },
        { label: 'Last 7 Days', start: subDays(new Date(), 7), end: new Date() },
        { label: 'Last 30 Days', start: subDays(new Date(), 30), end: new Date() },
        { label: 'This Month', start: new Date(new Date().getFullYear(), new Date().getMonth(), 1), end: new Date() },
        { label: 'Last Month', start: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1), end: new Date(new Date().getFullYear(), new Date().getMonth(), 0) }
    ];

    const handlePresetClick = (preset) => {
        setStartDate(preset.start);
        setEndDate(preset.end);
        fetchData(preset.start, preset.end);
    };

    return (
        <div>
            <Header userRole={userRole} />
            <div className="w-3/4 overflow-x-auto overflow-y-scroll h-[calc(100vh-100px)] px-4 mx-auto">
                <h1 className="text-2xl font-bold mb-4">Voluum Dashboard</h1>
                <div className="mb-4 flex items-center">
                    <div className="mr-4">
                        <label className="mr-2">Start Date:</label>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            className="border p-2"
                        />
                    </div>
                    <div className="mr-4">
                        <label className="mr-2">End Date:</label>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            className="border p-2"
                        />
                    </div>
                    <button
                        onClick={handleFetchData}
                        className="bg-blue-500 text-white p-2 rounded"
                    >
                        Fetch Data
                    </button>
                </div>
                <div className="mb-4 flex">
                    {presetOptions.map((preset) => (
                        <button
                            key={preset.label}
                            onClick={() => handlePresetClick(preset)}
                            className="bg-gray-200 text-gray-700 p-2 rounded mr-2"
                        >
                            {preset.label}
                        </button>
                    ))}
                </div>

                <table className="table-auto w-full">
                    <thead>
                        <tr>
                            <th className="px-4 py-2">Product Code</th>
                            <th className="px-4 py-2">Angle Code</th>
                            <th className="px-4 py-2">Lander Name</th>
                            <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('unique_visits')}>Unique Visits {getSortIndicator('unique_visits')}</th>
                            <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('unique_clicks')}>Unique Clicks {getSortIndicator('unique_clicks')}</th>
                            <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('conversions')}>Conversions {getSortIndicator('conversions')}</th>
                            <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('revenue')}>Revenue {getSortIndicator('revenue')}</th>
                            <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('cost')}>Cost {getSortIndicator('cost')}</th>
                            <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('ctr')}>CTR {getSortIndicator('ctr')}</th>
                            <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('cpa')}>CPA {getSortIndicator('cpa')}</th>
                            <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('aov')}>AOV {getSortIndicator('aov')}</th>
                            <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('cr')}>CR {getSortIndicator('cr')}</th>
                            <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('profit')}>Profit {getSortIndicator('profit')}</th>
                            <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('profitPercentage')}>Profit % {getSortIndicator('profitPercentage')}</th>
                        </tr>
                    </thead>
                    <tbody className="text-black text-sm">
                        {groupAndSortData(data).map((row, index) => (
                            <tr key={row.lander_id} className={`h-8 ${index % 2 === 0 ? 'bg-gray-100' : ''}`}>
                                <td
                                    className={`border px-2 py-1 text-center ${productColors[row.product_code] || ''}`}
                                    onClick={() => openModal('product')}
                                >
                                    {row.product_code || (
                                        <button
                                            className="p-1 rounded"
                                        >
                                            +
                                        </button>
                                    )}
                                </td>
                                <td
                                    className={`border px-2 py-1 text-center ${angleColors[row.angle_code] || ''}`}
                                    onClick={() => openModal('angle')}
                                >
                                    {row.angle_code || (
                                        <button
                                            className="p-1 rounded"
                                        >
                                            +
                                        </button>
                                    )}
                                </td>
                                <td className="border px-2 py-1 text-center">
                                    <a href={row.lander_url} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                                        {row.lander_name}
                                    </a>
                                </td>
                                <td className="border px-2 py-1 text-center">{formatNumber(row.unique_visits)}</td>
                                <td className="border px-2 py-1 text-center">{formatNumber(row.unique_clicks)}</td>
                                <td className="border px-2 py-1 text-center">{formatNumber(row.conversions)}</td>
                                <td className="border px-2 py-1 text-center">{formatCurrency(row.revenue)}</td>
                                <td className="border px-2 py-1 text-center">{formatCurrency(row.cost)}</td>
                                <td className="border px-2 py-1 text-center">{formatPercentage(row.ctr || 0)}</td>
                                <td className="border px-2 py-1 text-center">{formatCurrency(row.cpa || 0)}</td>
                                <td className="border px-2 py-1 text-center">{formatCurrency(row.aov || 0)}</td>
                                <td className="border px-2 py-1 text-center">{formatPercentage(row.cr || 0)}</td>
                                <td className={`border px-2 py-1 text-center ${parseFloat(row.profit) > 0 ? 'text-green-500' : parseFloat(row.profit) < 0 ? 'text-red-500' : 'text-black'}`}>
                                    {formatCurrency(row.profit || 0)}
                                </td>
                                <td className={`border px-2 py-1 text-center ${parseFloat(row.profitPercentage) > 0 ? 'text-green-500' : parseFloat(row.profitPercentage) < 0 ? 'text-red-500' : 'text-black'}`}>
                                    {formatPercentage((row.profitPercentage || 0) * 100)}
                                </td>
                            </tr>
                        ))}
                            <tr className="sticky bottom-0 bg-white font-bold" style={{ marginBottom: 0 }}>
                                <td className="border px-2 py-1 text-center" style={{ borderBottom: 'none' }}>Totals</td>
                                <td className="border px-2 py-1 text-center" style={{ borderBottom: 'none' }}></td>
                                <td className="border px-2 py-1 text-center" style={{ borderBottom: 'none' }}></td>
                                <td className="border px-2 py-1 text-center" style={{ borderBottom: 'none' }}>{formatNumber(totals.unique_visits)}</td>
                                <td className="border px-2 py-1 text-center" style={{ borderBottom: 'none' }}>{formatNumber(totals.unique_clicks)}</td>
                                <td className="border px-2 py-1 text-center" style={{ borderBottom: 'none' }}>{formatNumber(totals.conversions)}</td>
                                <td className="border px-2 py-1 text-center" style={{ borderBottom: 'none' }}>{formatCurrency(totals.revenue)}</td>
                                <td className="border px-2 py-1 text-center" style={{ borderBottom: 'none' }}>{formatCurrency(totals.cost)}</td>
                                <td className="border px-2 py-1 text-center" style={{ borderBottom: 'none' }}>{formatPercentage(totals.ctr)}</td>
                                <td className="border px-2 py-1 text-center" style={{ borderBottom: 'none' }}>{formatCurrency(totals.cpa)}</td>
                                <td className="border px-2 py-1 text-center" style={{ borderBottom: 'none' }}>{formatCurrency(totals.aov)}</td>
                                <td className="border px-2 py-1 text-center" style={{ borderBottom: 'none' }}>{formatPercentage(totals.cr)}</td>
                                <td className={`border px-2 py-1 text-center ${totals.profit >= 0 ? 'text-green-500' : 'text-red-500'}`} style={{ borderBottom: 'none' }}>
                                    {formatCurrency(totals.profit)}
                                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-black text-white text-xs rounded py-1 px-2 z-10 w-64">
                                        {Object.entries(totals.profits)
                                            .sort((a, b) => b[1] - a[1])
                                            .map(([code, profit]) => (
                                                <div key={code}>{code}: {formatCurrency(profit)}</div>
                                            ))}
                                    </div>
                                </td>
                                <td className={`border px-2 py-1 text-center ${totals.profitPercentage >= 0 ? 'text-green-500' : 'text-red-500'}`} style={{ borderBottom: 'none' }}>
                                    {formatPercentage(totals.profitPercentage)}
                                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-black text-white text-xs rounded py-1 px-2 z-10 w-64">
                                        {Object.entries(totals.profits)
                                            .sort((a, b) => b[1] - a[1])
                                            .map(([code, profit]) => {
                                                const profitPercentage = (profit / (totals.revenue || 1)) * 100;
                                                return (
                                                    <div key={code}>{code}: {formatPercentage(profitPercentage)}</div>
                                                );
                                            })}
                                    </div>
                                </td>
                            </tr>

                    </tbody>
                </table>

                {isModalOpen && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-6 rounded shadow-lg w-1/2">
                            <h2 className="text-xl font-bold mb-2">Add New {codeType === 'product' ? 'Product' : 'Angle'} Code</h2>
                            <div className="mb-4">
                                <input
                                    type="text"
                                    placeholder="Code"
                                    value={newCode}
                                    onChange={(e) => setNewCode(e.target.value)}
                                    className="border p-2 w-full mb-2"
                                />
                                <input
                                    type="text"
                                    placeholder="Keyword"
                                    value={newKeyword}
                                    onChange={(e) => setNewKeyword(e.target.value)}
                                    className="border p-2 w-full mb-2"
                                />
                            </div>
                            <div className="flex justify-end">
                                <button
                                    onClick={closeModal}
                                    className="bg-red-500 text-white p-2 rounded mr-2"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={addCode}
                                    className="bg-green-500 text-white p-2 rounded"
                                >
                                    Add Code
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Voluum;
