import React, { useContext, useState, useEffect } from 'react';
import ReportContext from './ReportContext';
import moment from 'moment';

const WeekSelector = () => {
  const { setWeek, selectedWeek: contextSelectedWeek } = useContext(ReportContext);

  // Convert context week to expected format
  const contextWeekFormatted = moment(contextSelectedWeek).format('YYYY-[W]WW');
  
  const [selectedWeek, setSelectedWeek] = useState(contextWeekFormatted);

  useEffect(() => {
    // Update local state if context week changes
    setSelectedWeek(contextWeekFormatted);
  }, [contextSelectedWeek]); // Listen for changes in contextSelectedWeek

  const handleChange = (event) => {
    const newSelectedWeek = event.target.value;
    setSelectedWeek(newSelectedWeek);
    const momentWeek = moment(newSelectedWeek + '-1', 'YYYY-W-E');
    setWeek({
      start: momentWeek.startOf('isoWeek').format('YYYY-MM-DD'),
      end: momentWeek.endOf('isoWeek').format('YYYY-MM-DD'),
    });
  };

  return (
    <form style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <label>
        <div style={{ width: '100%', maxWidth: '250px' }}>
          <input
            type="week"
            value={selectedWeek}
            onChange={handleChange}
            style={{ width: '100%' }}
          />
        </div>
      </label>
    </form>
  );
};

export default WeekSelector;
