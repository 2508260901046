import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEditors } from './EditorsDataContext';
import AnimatedTooltipPreview from './AnimatedProfileRows';
import DateSelectorEditorTop10 from './DatesEditorTop10';
import Header from './Header';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell } from 'recharts';

const EditorProfile = () => {
  let { editorName } = useParams();
  const {
    userRole,
    setStartDate,
    setEndDate,
    editorsGroupData,
    editorProfiles,
    startDate,
    endDate,
    updateGroupDataByDate,
  } = useEditors();
  const [editorData, setEditorData] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState('roas'); // Default selected metric

  const normalizeName = name => name.toLowerCase().replace(/\s+/g, ' ').trim();

  useEffect(() => {
    if (editorsGroupData && editorName && editorProfiles) {
      const normalizedEditorName = normalizeName(editorName);
      const profile = editorProfiles.find(p => normalizeName(p.name).startsWith(normalizedEditorName));

      if (profile) {
        const editorKey = Object.keys(editorsGroupData).find(key => normalizeName(key) === normalizeName(profile.name));
        const data = editorsGroupData[editorKey];
        if (data) {
          setEditorData({ ...data, profile });
        }
      }
    }
  }, [editorsGroupData, editorName, editorProfiles]);


  useEffect(() => {
    updateGroupDataByDate(startDate, endDate);
  }, [startDate, endDate, updateGroupDataByDate]);

  console.log(editorData, editorName, editorProfiles)
  if (!editorData) {
    return <div>Profile not found or no data available for this editor.</div>;
  }

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  };




  const aggregateEditorsDataForMetric = (metric) => {
    // First, filter out entries for "Unknown Editor" or "Unknown"
    return Object.entries(editorsGroupData)
      .filter(([editorName]) => editorName !== "Unknown Editor" && editorName !== "Unknown")
      .map(([editorName, data]) => {
        // Determine the key to use based on the metric
        let metricKey;
        switch (metric) {
          case 'spend':
            metricKey = 'total_spend';
            break;
          case 'revenue':
            metricKey = 'total_revenue';
            break;
          case 'impressions':
            metricKey = 'total_impressions';
            break;
          default:
            metricKey = metric;
        }
        // Dynamically access the selected metric and format it
        const metricValue = data[metricKey] ? parseFloat(data[metricKey]).toFixed(2) : '0.00';
        return {
          name: editorName, // Use the editor's name on the X-axis
          [metric]: metricValue // Use the formatted value
        };
      });
  };
  

  // Assuming `dailyStats` is available in `editorData` similar to the first component
  const sortedDates = Object.keys(editorData.dailyStats).sort((a, b) => new Date(a) - new Date(b));
  const chartData = sortedDates.map(date => {
    const formattedDate = new Date(date).toLocaleDateString('en-GB', { // 'en-GB' to get day-month order
      day: 'numeric', // Numeric day
      month: 'numeric', // Numeric month
      year: undefined // Omit the year
    }).replace(/\//g, '-'); // Replace slashes with dashes
  
    return {
      date: formattedDate, 
      roas: parseFloat(editorData.dailyStats[date].roas.toFixed(2)),
      spend: parseFloat(editorData.dailyStats[date].spend.toFixed(2)),
      profit: parseFloat(editorData.dailyStats[date].profit.toFixed(2)),
      revenue: parseFloat(editorData.dailyStats[date].revenue.toFixed(2)),
      impressions: parseFloat(editorData.dailyStats[date].impressions.toFixed(2)),
    };
  });

  const handleMetricChange = (e) => {
    setSelectedMetric(e.target.value);
  };

  const sortedAdsData = Object.entries(editorData.ads).sort((a, b) => b[1].total_spend - a[1].total_spend);
  const editorMetricData = aggregateEditorsDataForMetric(selectedMetric);
  const normalizedEditorName = editorName.toLowerCase().split(' ')[0];
  const maxYValue = editorMetricData.reduce((max, data) => Math.max(max, parseFloat(data[selectedMetric])), 0) * 1.1; // Adding 10% buffer

console.log('editorsgroupdata', editorsGroupData);

console.log(editorData);
  return (
<div>
  <Header userRole={userRole} />
  <div className='container mx-auto px-6 py-4'>
    <div className='sticky top-0 pt-2 pb-2 bg-transparent z-20'>
      <div className='flex flex-wrap justify-between md:justify-end max-w-6xl gap-3 mt-4 mb-4'>
        <DateSelectorEditorTop10 setStartDate={setStartDate} setEndDate={setEndDate} />
      </div>
    </div>
    <AnimatedTooltipPreview />
    <div className='editor-profile mt-8'>
      <div className='flex flex-col md:flex-row items-center'>
        <img src={editorData.profile.image_url} alt={editorData.profile.name} className='w-32 h-32 md:w-48 md:h-48 rounded-full mx-auto md:mx-0 md:mr-8' />
        <div>
          <h2 className='text-xl md:text-3xl font-semibold'>{editorData.profile.name}</h2>
          <p className='text-md mt-2'>{editorData.profile.bio}</p>
            <div className='mt-4 flex flex-wrap justify-around rounded-lg items-center shadow-lg gap-4'>
                <div className={`bg-white overflow-hidden rounded-lg `}>
                    <div className="px-4 py-5 sm:p-6 text-center">
                    <dt className="text-sm font-medium text-indigo-400 truncate">ROAS</dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900">{formatCurrency(editorData.roas)}</dd>
                    </div>
                </div>
                <div className={`bg-white overflow-hidden rounded-lg `}>
                    <div className="px-4 py-5 sm:p-6 text-center">
                    <dt className="text-sm font-medium text-indigo-400 truncate">Index</dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900">{editorData.customIndex.toFixed(2)}</dd>
                    </div>
                </div>
                <div className={`bg-white overflow-hidden rounded-lg `}>
                    <div className="px-4 py-5 sm:p-6 text-center">
                    <dt className="text-sm font-medium text-indigo-400 truncate">Profit</dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900">{formatCurrency(editorData.profit)}</dd>
                    </div>
                </div>
                <div className={`bg-white overflow-hidden rounded-lg `}>
                    <div className="px-4 py-5 sm:p-6 text-center">
                    <dt className="text-sm font-medium text-indigo-400 truncate">Spend</dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900">{formatCurrency(editorData.total_spend)}</dd>
                    </div>
                </div>
                <div className={`bg-white overflow-hidden rounded-lg `}>
                    <div className="px-4 py-5 sm:p-6 text-center">
                    <dt className="text-sm font-medium text-indigo-400 truncate">Revenue</dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900">{formatCurrency(editorData.total_revenue)}</dd>
                    </div>
                </div>
                <div className={`bg-white overflow-hidden rounded-lg `}>
                    <div className="px-4 py-5 sm:p-6 text-center">
                    <dt className="text-sm font-medium text-indigo-400 truncate">Views</dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900">
                        {new Intl.NumberFormat('en-US').format(editorData.total3sec)}
                    </dd>                   
                    </div>
                </div>
                </div>


        </div>
      </div>
    </div>
    <div className='ads-data-table mt-8'>
      {/* Table for Ads Data */}
      <div className='overflow-x-auto'>
      <div className='inline-block min-w-full overflow-hidden align-middle border-b rounded-lg border-gray-200 shadow sm:rounded-lg'>
  {/* Title Header */}
  <div className='text-lg leading-6 font-medium text-gray-900 py-4 px-6 bg-white'>
    {editorData.profile.name}'s Ads:
  </div>
  {/* Scrollable Table Body */}
  <div className='overflow-y-auto h-96'>
    <table className='min-w-full'>
      <thead>
        <tr className='bg-gray-50 sticky top-0 z-10 text-center'>
          <th className='px-6 py-3 text-xs leading-4 font-bold text-indigo-400 uppercase font-bold tracking-wider'>Ad ID</th>
          <th className='px-6 py-3 text-xs leading-4 font-bold text-indigo-400 uppercase tracking-wider'>Views</th>
          <th className='px-6 py-3 text-xs leading-4 font-bold text-indigo-400 uppercase tracking-wider'>Spend</th>
          <th className='px-6 py-3 text-xs leading-4 font-bold text-indigo-400 uppercase tracking-wider'>Revenue</th>
          <th className='px-6 py-3 text-xs leading-4 font-bold text-indigo-400 uppercase tracking-wider'>Profit</th>
          <th className='px-6 py-3 text-xs leading-4 font-bold text-indigo-400 uppercase tracking-wider'>Average Watch Time</th>
          <th className='px-6 py-3 text-xs leading-4 font-bold text-indigo-400 uppercase tracking-wider'>Thumb Stop Ratio</th>
          <th className='px-6 py-3 text-xs leading-4 font-bold text-indigo-400 uppercase tracking-wider'>Video Hold Rate</th>
          <th className='px-6 py-3 text-xs leading-4 font-bold text-indigo-400 uppercase tracking-wider'>Live Days within selected date range</th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {sortedAdsData.map(([adId, adData], index) => (
          <tr key={adId} className={`text-center ${index % 2 === 0 ? 'bg-white' : 'bg-white'}`}>
              <td className='px-6 py-4 whitespace-no-wrap text-sm'>{adId}</td>
              <td className='px-6 py-4 whitespace-no-wrap text-sm '>{new Intl.NumberFormat('en-US').format(adData.total_impressions)}</td>
              <td className='px-6 py-4 whitespace-no-wrap text-sm '>{formatCurrency(adData.total_spend)}</td>
              <td className='px-6 py-4 whitespace-no-wrap text-sm '>{formatCurrency(adData.total_revenue)}</td>
              <td className='px-6 py-4 whitespace-no-wrap text-sm '>{formatCurrency(adData.profit)}</td>
              <td className='px-6 py-4 whitespace-no-wrap text-sm '>{adData.avgWatchTime.toFixed(2) || 0}s</td>
              <td className='px-6 py-4 whitespace-no-wrap text-sm '>{typeof adData.thumbStopRatio === 'number' ? adData.thumbStopRatio.toFixed(2) : 0}%</td>
              <td className='px-6 py-4 whitespace-no-wrap text-sm '>{typeof adData.videoHoldRate === 'number' ? adData.videoHoldRate.toFixed(2) : 0}%</td>
              <td className='px-6 py-4 whitespace-no-wrap text-sm '>{adData.liveDays || 0}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>

    </div>
    </div>
    <div className="flex justify-center mt-8">
      <div className="metric-selector mb-4">
        <select value={selectedMetric} onChange={handleMetricChange} className="border border-gray-300 rounded p-2">
          <option value="roas">ROAS</option>
          <option value="spend">Spend</option>
          <option value="profit">Profit</option>
          <option value="revenue">Revenue</option>
          <option value="impressions">Impressions</option>
          {/* Add more options based on the metrics you want to include */}
        </select>
      </div>
    </div>

    {/* Charts Container */}
    <div className='flex justify-center mt-4 gap-10'>
      {/* Line Chart Container */}
      <div className="w-1/2">
        <LineChart width={600} height={300} data={chartData}>
          <XAxis dataKey="date" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey={selectedMetric} stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </div>

      {/* Bar Chart Container */}
      <div className="w-1/2">
          <BarChart width={600} height={300} data={editorMetricData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" height={60} angle={-45} textAnchor="end" />
            <YAxis domain={[0, maxYValue]} />
            <Tooltip />
            <Legend />
            <Bar dataKey={selectedMetric} fill="#8884d8">
              {editorMetricData.map((entry, index) => {
                // Normalize the entry name for comparison and color coding
                const normalizedEntryName = entry.name.toLowerCase().split(' ')[0];
                return (
                  <Cell key={`cell-${index}`} fill={normalizedEntryName === normalizedEditorName ? '#00c260' : '#8884d8'} />
                );
              })}
            </Bar>
          </BarChart>
        </div>
        </div>
        </div>
        </div>

  );
};

export default EditorProfile;
