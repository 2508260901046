import React, { useEffect, useState } from "react";
import './Leaderboard.css';
import AnimatedTooltipPreview from './AnimatedProfileRows';
import EditorCard from "./EditorCard";
import { useNavigate } from 'react-router-dom'; // Make sure useNavigate is imported

const Leaderboard = ({ data, editorProfiles }) => {
  const [sortedOrder, setSortedOrder] = useState([]); // Store the last sorted order
  const [changes, setChanges] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    console.log('Editors:', data);
    console.log('Profiles:', editorProfiles);
  }, [data, editorProfiles]);

  const borderStyles = {
    0: 'border-4 border-diamond shine-diamond', // First editor, diamond
    1: 'border-4 border-platinum shine-platinum', // Second editor, platinum
    2: 'border-4 border-gold shine-gold', // Third editor, gold
    3: 'border-4 border-silver shine-silver', // Fourth editor, silver
  };

  // Filter out specific editors and unknowns before sorting
  const filteredData = Object.entries(data || {})
    .filter(([editor]) => editor !== 'Unknown' && editor !== 'Unknown Editor'
      && editor !== '7daysEditors' && editor !== '14daysEditors'
      && editor !== '30daysEditors' && editor !== '90daysEditors')
    .sort(([, detailsA], [, detailsB]) => detailsB.customIndex - detailsA.customIndex);

  const editorProfileMap = editorProfiles.reduce((acc, profile) => {
    const firstName = profile.name.split(' ')[0].toLowerCase();
    acc[firstName] = profile;
    return acc;
  }, {});

  const enhancedFilteredData = filteredData.map(([editorName, details]) => {
    const firstName = editorName.split(' ')[0].toLowerCase();
    const profile = editorProfileMap[firstName];
    if (profile) {
      return [editorName, { ...details, profile }];
    }
    return [editorName, details];
  });

  useEffect(() => {
    const newOrderSnapshot = enhancedFilteredData.map(([editorName]) => editorName);
    let localChanges = [];

    // Compare with the previous order, but only consider changes for non-ignored entries
    const prevOrderSnapshot = sortedOrder.map(([editorName]) => editorName).filter(name => 
      !['7daysEditors', '14daysEditors', '30daysEditors', '90daysEditors'].includes(name));

    newOrderSnapshot.forEach((editorName, newIndex) => {
      const prevIndex = prevOrderSnapshot.indexOf(editorName);
      if (prevIndex < 0) {
        localChanges.push({ editorName, change: 'added', newIndex });
      } else if (prevIndex !== newIndex) {
        localChanges.push({
          editorName,
          change: prevIndex > newIndex ? 'moved up' : 'moved down',
          from: prevIndex,
          to: newIndex,
        });
      }
    });

    if (localChanges.length > 0) {
      console.log('Order changes:', localChanges);
      setSortedOrder(enhancedFilteredData);
      setChanges(localChanges); // Update the changes state
    }
  }, [enhancedFilteredData, sortedOrder]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  };

  const handleNavigate = (editorName) => {
    navigate(`/profile/${editorName}`);
  };


  const podiumData = enhancedFilteredData.slice(0, 3);
  const tableData = enhancedFilteredData.slice(3);
  // Proceed with rendering using enhancedFilteredData or filteredData
  return (
  <div>
  <div className="mx-auto bg-white/30 backdrop-blur-md p-10 rounded-lg border border-gray-200 shadow-md" style={{ maxWidth: '1300px' }}>
  {/* Podium UI Here */}
  <div className="flex justify-center items-end space-x-6">
    {podiumData.length === 3 && [
      /* Design for 2nd place */
      <div key={podiumData[1][0]} className="group relative text-center border-2nd cursor-pointer" onClick={() => handleNavigate(podiumData[1][1].name)}>
      <img src={podiumData[1][1].profile?.image_url} alt="2nd Place" className="object-cover object-top rounded-full h-20 w-20 border-4 border-white transition duration-500 mx-auto" />
        <div className="w-28 bg-indigo-500 h-32 rounded-lg shadow-xl mx-auto flex justify-center items-center flex-col mt-2">
          <p className="text-white text-lg font-semibold">{podiumData[1][1].name}</p>
          <p className="text-white mt-1 font-medium">Index: {Math.round(podiumData[1][1].customIndex).toLocaleString()}</p>
        </div>
        <p className="text-grey mt-2">2nd Place</p>
        {/* Hover Tooltip for Additional Metrics */}
        {/* <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 w-auto min-w-0 max-w-xs px-4 py-2 bg-black/70 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg">
          <div className="text-white text-sm text-center">
            <p>ROAS: {podiumData[1][1].roas?.toLocaleString()}</p>
            <p>Total Views: {podiumData[1][1].total3sec?.toLocaleString()}</p>
            <p>Video Hold Rate: {podiumData[1][1].videoHoldRate?.toLocaleString()}%</p>
            <p>Thumb Stop Ratio: {podiumData[1][1].thumbStopRatio?.toLocaleString()}%</p>
          </div>
        </div> */}
      </div>,

            /* Improved design for 1st place */
            <div key={podiumData[0][0]} className="group relative text-center border-1st cursor-pointer" onClick={() => handleNavigate(podiumData[0][1].name)}>
            <img src={podiumData[0][1].profile?.image_url} alt="1st Place" className="object-cover object-top rounded-full h-24 w-24 border-4 border-white transition duration-500 mx-auto" />
            <div className="w-28 bg-indigo-700 h-48 rounded-lg shadow-xl mx-auto flex justify-center items-center flex-col mt-2">
              <p className="text-white text-lg font-semibold">{podiumData[0][1].name}</p>
              <p className="text-white mt-1 font-medium">Index: {Math.round(podiumData[0][1].customIndex).toLocaleString()}</p>
            </div>
            <p className="text-yellow-500 mt-2">1st Place</p>
            {/* Hover Tooltip for Additional Metrics */}
            {/* <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 w-auto min-w-0 max-w-xs px-4 py-2 bg-black/70 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg">
              <div className="text-white text-sm text-center">
                <p>ROAS: {podiumData[0][1].roas?.toLocaleString()}</p>
                <p>Total Views: {podiumData[0][1].total3sec?.toLocaleString()}</p>
                <p>Video Hold Rate: {podiumData[0][1].videoHoldRate?.toLocaleString()}%</p>
                <p>Thumb Stop Ratio: {podiumData[0][1].thumbStopRatio?.toLocaleString()}%</p>
              </div>
            </div> */}
          </div>,

      /* Improved design for 3rd place */
      <div key={podiumData[2][0]} className="group relative text-center border-3rd cursor-pointer" onClick={() => handleNavigate(podiumData[2][1].name)}>
        <img src={podiumData[2][1].profile?.image_url} alt="3rd Place" className="object-cover object-top rounded-full h-16 w-16 border-4 border-white transition duration-500 mx-auto" />
        <div className="w-28 bg-indigo-500 h-24 rounded-lg shadow-xl mx-auto flex justify-center items-center flex-col mt-2">
          <p className="text-white text-lg font-semibold">{podiumData[2][1].name}</p>
          <p className="text-white mt-1 font-medium">Index: {Math.round(podiumData[2][1].customIndex).toLocaleString()}</p>
        </div>
        <p className="text-yellow-800 mt-2">3rd Place</p>
        {/* Hover Tooltip for Additional Metrics */}
        {/* <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 w-auto min-w-0 max-w-xs px-4 py-2 bg-black/70 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg">
        <div className="text-white text-sm text-center">
            <p>ROAS: {podiumData[2][1].roas?.toLocaleString()}</p>
            <p>Total Views: {podiumData[2][1].total3sec?.toLocaleString()}</p>
            <p>Video Hold Rate: {podiumData[2][1].videoHoldRate?.toLocaleString()}%</p>
            <p>Thumb Stop Ratio: {podiumData[2][1].thumbStopRatio?.toLocaleString()}%</p>
          </div>
        </div> */}
      </div>,
    ]}
    </div>


        {/* Dynamic Table Below */}
          <div className="mt-8">
            <table className="min-w-full table-auto rounded-lg shadow-lg">
              <thead className="bg-gray-50 rounded-t-lg">
                <tr>
                  <th className="px-4 py-2 rounded-tl-lg">Rank</th>
                  <th className="px-4 py-2">Editor</th>
                  <th className="px-4 py-2">ROAS</th>
                  <th className="px-4 py-2">Total Views</th>
                  <th className="px-4 py-2">Video Hold Rate</th>
                  <th className="px-4 py-2">Thumb Stop Ratio</th>
                  <th className="px-4 py-2">Spend</th>
                  <th className="px-4 py-2">Revenue</th>
                  <th className="px-4 py-2">Profit</th>
                  <th className="px-4 py-2 rounded-tr-lg">Rank Index</th>
                </tr>
              </thead>
              <tbody>
  {tableData.map(([editor, details], index) => {
    const change = changes.find(change => change.editorName === editor);
    let movementClass = '';
    if (change) {
      movementClass = change.change === 'moved up' ? 'movingUp' : 'movingDown';
    }

    return (
      <tr key={editor} className={`border-b ${movementClass}`}> {/* Dynamic class added based on movement */}
        <td className="text-center px-4 py-2">{index + 4}</td>
        <td className="flex items-center space-x-3 px-4 py-2 cursor-pointer" onClick={() => handleNavigate(editor)}>
          <img src={details.profile?.image_url} alt={editor} className="h-10 w-10 object-cover rounded-full"/>
          <span>{details.name}</span>
        </td>
        <td className="text-center px-4 py-2">{details.roas?.toLocaleString()}</td>
        <td className="text-center px-4 py-2">{details.total3sec?.toLocaleString()}</td>
        <td className="text-center px-4 py-2">{details.videoHoldRate?.toLocaleString()}%</td>
        <td className="text-center px-4 py-2">{details.thumbStopRatio?.toLocaleString()}%</td>
        <td className="text-center px-4 py-2">{formatCurrency(details.total_spend)}</td>
        <td className="text-center px-4 py-2">{formatCurrency(details.total_revenue)}</td>
        <td className="text-center px-4 py-2">{formatCurrency(details.profit)}</td>
        <td className="text-center px-4 py-2">{Math.round(details.customIndex).toLocaleString()}</td>
      </tr>
    );
  })}
</tbody>

            </table>
          </div>
      </div>
      <AnimatedTooltipPreview/>
      <div className="flex flex-wrap mx-auto justify-center -mx-2" style={{ maxWidth: '1900px' }}>
  {enhancedFilteredData.sort((a, b) => b[1].customIndex - a[1].customIndex).map(([editorName, editorDetails], index) => (
    // For mobile, use full width (w-full). For medium screens and up, use half width (w-1/2) to show 2 cards per row.
    <div key={editorName} className="my-2 px-2 w-full md:w-1/2"> {/* Adjusted class for responsive design */}
      <EditorCard editorData={editorDetails} rank={index + 1} />
    </div>
  ))}
</div>



    </div>
  );
};

export default Leaderboard;
