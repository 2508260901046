import React from 'react';

// Enhanced metrics definition including additional metrics
const metrics = [
    { key: 'total_impressions', label: 'Impressions', format: 'number' },
    { key: 'total_spend', label: 'Spend', format: 'currency' },
    { key: 'roas', label: 'ROAS', format: 'number' },
    { key: 'profit', label: 'Profit', format: 'currency' },
    { key: 'unique_clicks', label: 'Clicks', format: 'number' },
    { key: 'unique_3_sec_watch', label: '3 Sec Watch', format: 'number' },
    { key: 'thru_play', label: 'Thru Play', format: 'number' },
    { key: 'aov', label: 'AOV', format: 'currency' },
    { key: 'costPer3SecVideoViews', label: 'Cost_3_Sec', format: 'currency' },
    { key: 'cpa', label: 'CPA', format: 'currency' },
    { key: 'cpc', label: 'CPC', format: 'currency' },
    { key: 'cpm', label: 'CPM', format: 'currency' },
    { key: 'ctr', label: 'CTR', format: 'percentage' },
    { key: 'cvr', label: 'CVR', format: 'percentage' },
    { key: 'epc', label: 'EPC', format: 'currency' },
    { key: 'thumbStopRatio', label: 'TSR', format: 'percentage' },
    { key: 'videoHoldRate', label: 'VHR', format: 'percentage' }
];

// Helper function to format data based on type
// Helper function to format data based on type
const formatData = (value, format) => {
    if (value === null) return 'N/A';
    switch (format) {
        case 'currency':
            // Format currency with thousands separators and two decimal places.
            // Adjust negative sign placement as needed.
            const formattedCurrency = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(value);
            return value < 0 ? `-${formattedCurrency.replace(/^-/, '')}` : formattedCurrency;
        case 'percentage':
            // Format percentages to two decimal places with a percentage sign.
            return `${value.toFixed(2)}%`;
        case 'number':
        default:
            // Format numbers with up to two decimal places, adding thousands separators.
            return new Intl.NumberFormat('en-US', {
                maximumFractionDigits: 2
            }).format(value);
    }
};



const OffersTable = ({ offersData }) => {
    return (
        <div className="container mx-auto p-4">
            <div className="overflow-auto relative shadow-md sm:rounded-lg" style={{ maxHeight: '300px' }}>
                <table className="w-full text-sm text-left text-gray-500 dark:text-white">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-white">
                        <tr>
                            <th scope="col" className="py-3 px-6">Offer</th>
                            {metrics.map((metric, index) => (
                                <th key={metric.key} scope="col" className="py-3 px-6" style={{ minWidth: index < 12 ? '120px' : 'auto' }}>{metric.label}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="divide-y overflow-x-auto">
                        {Object.keys(offersData).map((offerKey) => (
                            <tr key={offerKey} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <td className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">{offerKey}</td>
                                {metrics.map(metric => (
                                    <td key={metric.key} className="py-4 px-6">
                                        {formatData(offersData[offerKey][metric.key], metric.format)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OffersTable;
