import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import { FiLoader } from 'react-icons/fi';
import Header from './Header';

const codeToCombination = {
    'FM EDE': 'Foot Massager Edema',
    'FM NRP': 'Foot Massager Neuropathy',
    'LAC EDE': 'Leg Massager Edema',
    'LAC LYM': 'Leg Massager Lymphedema',
    'WBM NRP': 'Whole Body Massager Neuropathy',
    'WBM EDE': 'Whole Body Massager Edema',
    'WBM LYM': 'Whole Body Massager Lymphedema',
};

const combinationColors = {
    'Foot Massager Edema': 'bg-red-100',
    'Foot Massager Neuropathy': 'bg-blue-100',
    'Leg Massager Edema': 'bg-green-100',
    'Leg Massager Lymphedema': 'bg-yellow-100',
    'Whole Body Massager Neuropathy': 'bg-purple-100',
    'Whole Body Massager Edema': 'bg-pink-100',
    'Whole Body Massager Lymphedema': 'bg-indigo-100',
};

const NativeFb = () => {
    const [startDate, setStartDate] = useState(() => {
        const savedStartDate = localStorage.getItem('startDate');
        return savedStartDate ? new Date(savedStartDate) : moment().subtract(8, 'days').toDate();
    });
    const [endDate, setEndDate] = useState(() => {
        const savedEndDate = localStorage.getItem('endDate');
        return savedEndDate ? new Date(savedEndDate) : moment().subtract(1, 'days').toDate();
    });
    const [voluumData, setVoluumData] = useState([]);
    const [summaryAdsData, setSummaryAdsData] = useState([]);
    const [loadingButton, setLoadingButton] = useState(null); // State to track the loading button
    const [isLoading, setIsLoading] = useState(false); // State to track loading
    const [userRole, setUserRole] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null }); // State to manage sorting
    const timezone = 'America/Los_Angeles';
    const [voluumSortConfig, setVoluumSortConfig] = useState({ key: 'profit', direction: 'descending' });
    const [summaryAdsSortConfig, setSummaryAdsSortConfig] = useState({ key: 'total_profit', direction: 'descending' });
    
    const presetOptions = [
        { label: 'Yesterday', start: moment().subtract(1, 'days').toDate(), end: moment().subtract(1, 'days').toDate() },
        { label: 'Last 7 Days', start: moment().subtract(8, 'days').toDate(), end: moment().subtract(1, 'days').toDate() },
        { label: 'Last 30 Days', start: moment().subtract(31, 'days').toDate(), end: moment().subtract(1, 'days').toDate() },
    ];

    const fetchData = async (start, end) => {
        setIsLoading(true);
        const formattedStart = moment(start).tz(timezone).format('YYYY-MM-DD');
        const formattedEnd = moment(end).tz(timezone).format('YYYY-MM-DD');

        try {
            const voluumResponse = await axios.get('/api/voluum/combinations', {
                params: { startDate: formattedStart, endDate: formattedEnd }
            });
            const formattedVoluumData = voluumResponse.data.map(item => ({
                ...item,
                combination: codeToCombination[item.combination] || item.combination
            }));
            setVoluumData(formattedVoluumData);

            const summaryAdsResponse = await axios.get('/api/summary_ads/combinations', {
                params: { startDate: formattedStart, endDate: formattedEnd }
            });
            setSummaryAdsData(summaryAdsResponse.data);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const role = localStorage.getItem('userRole');
        setUserRole(role);

        fetchData(startDate, endDate);
    }, []);

    useEffect(() => {
        localStorage.setItem('startDate', startDate);
        localStorage.setItem('endDate', endDate);
    }, [startDate, endDate]);

    const handleFetchData = () => {
        setLoadingButton('fetch');
        fetchData(startDate, endDate).then(() => setLoadingButton(null));
    };

    const handlePresetClick = (preset) => {
        setLoadingButton(preset.label);
        setStartDate(preset.start);
        setEndDate(preset.end);
        fetchData(preset.start, preset.end).then(() => setLoadingButton(null));
    };

    const handleSort = (key, table) => {
        let direction = 'ascending';
        let sortConfig = table === 'voluum' ? voluumSortConfig : summaryAdsSortConfig;
    
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        } else if (sortConfig.key === key && sortConfig.direction === 'descending') {
            direction = null;
            key = null;
        }
    
        const newSortConfig = { key, direction };
        if (table === 'voluum') {
            setVoluumSortConfig(newSortConfig);
        } else {
            setSummaryAdsSortConfig(newSortConfig);
        }
    };
    
    const getSortIndicator = (key, table) => {
        const sortConfig = table === 'voluum' ? voluumSortConfig : summaryAdsSortConfig;
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '↑' : '↓';
        }
        return '';
    };
    
    const sortData = (data, sortConfig) => {
        if (!sortConfig.key) return data;
    
        return [...data].sort((a, b) => {
            if (sortConfig.direction === 'ascending') {
                return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
            }
            return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
        });
    };
    

    const calculateTotals = (data, isNative = true) => {
        return data.reduce(
            (acc, item) => {
                const revenue = parseFloat(item.revenue || item.total_revenue || 0);
                const cost = parseFloat(item.cost || item.total_spend || 0);
                const profit = parseFloat(item.profit || item.total_profit || 0);
                acc.revenue += revenue;
                acc.cost += cost;
                acc.profit += profit;
                return acc;
            },
            { revenue: 0, cost: 0, profit: 0 }
        );
    };

    const voluumTotals = calculateTotals(voluumData);
    const summaryAdsTotals = calculateTotals(summaryAdsData, false);



    const calculateCombinedProfit = () => {
        const combinedProfit = {
            combinations: {},
            products: {},
            angles: {},
        };

        const combinations = Object.values(codeToCombination);

        combinations.forEach((combination) => {
            combinedProfit.combinations[combination] = 0;
        });

        ['Foot Massager', 'Leg Massager', 'Whole Body Massager'].forEach((product) => {
            combinedProfit.products[product] = 0;
        });

        ['Neuropathy', 'Edema', 'Lymphedema'].forEach((angle) => {
            combinedProfit.angles[angle] = 0;
        });

        [...voluumData, ...summaryAdsData].forEach((item) => {
            const combination = codeToCombination[item.combination] || item.combination;

            // Extract product and angle from the combination name
            let product, angle;
            if (combination.includes('Foot Massager')) {
                product = 'Foot Massager';
            } else if (combination.includes('Leg Massager')) {
                product = 'Leg Massager';
            } else if (combination.includes('Whole Body Massager')) {
                product = 'Whole Body Massager';
            }

            if (combination.includes('Neuropathy')) {
                angle = 'Neuropathy';
            } else if (combination.includes('Edema')) {
                angle = 'Edema';
            } else if (combination.includes('Lymphedema')) {
                angle = 'Lymphedema';
            }

            combinedProfit.combinations[combination] += parseFloat(item.profit || item.total_profit || 0);
            combinedProfit.products[product] += parseFloat(item.profit || item.total_profit || 0);
            combinedProfit.angles[angle] += parseFloat(item.profit || item.total_profit || 0);
        });

        // Sort each category by profit in descending order
        combinedProfit.combinations = Object.fromEntries(
            Object.entries(combinedProfit.combinations).sort(([, a], [, b]) => b - a)
        );

        combinedProfit.products = Object.fromEntries(
            Object.entries(combinedProfit.products).sort(([, a], [, b]) => b - a)
        );

        combinedProfit.angles = Object.fromEntries(
            Object.entries(combinedProfit.angles).sort(([, a], [, b]) => b - a)
        );

        return combinedProfit;
    };

    const combinedProfit = calculateCombinedProfit();

    const calculateTotal = (data) => {
        return Object.values(data).reduce((acc, profit) => acc + profit, 0);
    };

    
    return (
        <div>
            <Header userRole={userRole} />
            <div className="mx-auto max-w-[1600px]">
            <div className="mb-4 mt-4 flex justify-between items-center">
                    <div className="flex flex-wrap items-center space-x-2 md:space-x-4">
                        {presetOptions.map((preset) => (
                            <button
                                key={preset.label}
                                onClick={() => handlePresetClick(preset)}
                                className="relative px-4 py-2 text-sm font-medium bg-indigo-500 text-white transition-all shadow-[3px_3px_0px_black] hover:shadow-none hover:translate-x-[3px] hover:translate-y-[3px] flex items-center justify-center"
                                disabled={isLoading}
                            >
                                <span className="inline-block" style={{ visibility: loadingButton === preset.label ? 'hidden' : 'visible' }}>{preset.label}</span>
                                {loadingButton === preset.label && <FiLoader className="animate-spin absolute" />}
                            </button>
                        ))}
                    </div>
                    <div className="flex items-center">
                        <div className="mr-4">
                            <label className="mr-2">Start Date:</label>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                className="border p-2"
                            />
                        </div>
                        <div className="mr-4">
                            <label className="mr-2">End Date:</label>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                className="border p-2"
                            />
                        </div>
                        <button
                            onClick={handleFetchData}
                            className="relative px-4 py-2 text-sm font-medium bg-indigo-500 text-white transition-all shadow-[3px_3px_0px_black] hover:shadow-none hover:translate-x-[3px] hover:translate-y-[3px] flex items-center justify-center"
                            disabled={isLoading}
                        >
                            <span className="inline-block" style={{ visibility: loadingButton === 'fetch' ? 'hidden' : 'visible' }}>Fetch Data</span>
                            {loadingButton === 'fetch' && <FiLoader className="animate-spin absolute" />}
                        </button>
                    </div>
                </div>
                <div className="mt-4 p-4 rounded ">
                    <h2 className="text-2xl text-center text-red-800 font-bold mb-2">NATIVE  Voluum</h2>
                    <table className="table-auto w-full mb-4">
                        <thead>
                            <tr>
                                <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('combination', 'voluum')}>Combination {getSortIndicator('combination', 'voluum')}</th>
                                <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('revenue', 'voluum')}>Revenue {getSortIndicator('revenue', 'voluum')}</th>
                                <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('cost', 'voluum')}>Spend {getSortIndicator('cost', 'voluum')}</th>
                                <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('profit', 'voluum')}>Profit {getSortIndicator('profit', 'voluum')}</th>
                                <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('profitPercentage', 'voluum')}>Profit % {getSortIndicator('profitPercentage', 'voluum')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortData(voluumData, voluumSortConfig).map((item, index) => (
                                <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                                    <td className={`border px-4 py-2 ${combinationColors[item.combination]}`}>{item.combination}</td>
                                    <td className="border px-4 py-2">{formatCurrency(item.revenue)}</td>
                                    <td className="border px-4 py-2">{formatCurrency(item.cost)}</td>
                                    <td className={`border px-4 py-2 ${item.profit >= 0 ? 'text-green-500' : 'text-red-500'}`}>{formatCurrency(item.profit)}</td>
                                    <td className={`border px-4 py-2 ${item.profitPercentage >= 0 ? 'text-green-500' : 'text-red-500'}`}>{formatPercentage(item.profitPercentage)}</td>
                                </tr>
                            ))}
                            <tr className="font-bold">
                                <td className="border border-t-2 border-t-black px-4 py-2">Totals</td>
                                <td className="border border-t-2 border-t-black px-4 py-2">{formatCurrency(voluumTotals.revenue)}</td>
                                <td className="border border-t-2 border-t-black px-4 py-2">{formatCurrency(voluumTotals.cost)}</td>
                                <td className={`border border-t-2 border-t-black px-4 py-2 ${voluumTotals.profit >= 0 ? 'text-green-500' : 'text-red-500'}`}>{formatCurrency(voluumTotals.profit)}</td>
                                <td className={`border border-t-2 border-t-black px-4 py-2 ${voluumTotals.profit >= 0 ? 'text-green-500' : 'text-red-500'}`}>{formatPercentage((voluumTotals.profit / voluumTotals.revenue))}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="mt-4 mb-8 p-4 rounded">
                    <h2 className="text-2xl font-bold text-center text-blue-800 mb-2">FACEBOOK</h2>
                    <table className="table-auto w-full mb-4">
                        <thead>
                            <tr>
                                <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('combination', 'summaryAds')}>Combination {getSortIndicator('combination', 'summaryAds')}</th>
                                <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('total_revenue', 'summaryAds')}>Revenue {getSortIndicator('total_revenue', 'summaryAds')}</th>
                                <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('total_spend', 'summaryAds')}>Spend {getSortIndicator('total_spend', 'summaryAds')}</th>
                                <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('total_profit', 'summaryAds')}>Profit {getSortIndicator('total_profit', 'summaryAds')}</th>
                                <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('profitPercentage', 'summaryAds')}>Profit % {getSortIndicator('profitPercentage', 'summaryAds')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortData(summaryAdsData, summaryAdsSortConfig).map((item, index) => (
                                <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                                    <td className={`border px-4 py-2 ${combinationColors[item.combination]}`}>{item.combination}</td>
                                    <td className="border px-4 py-2">{formatCurrency(item.total_revenue)}</td>
                                    <td className="border px-4 py-2">{formatCurrency(item.total_spend)}</td>
                                    <td className={`border px-4 py-2 ${item.total_profit >= 0 ? 'text-green-500' : 'text-red-500'}`}>{formatCurrency(item.total_profit)}</td>
                                    <td className={`border px-4 py-2 ${item.profitPercentage >= 0 ? 'text-green-500' : 'text-red-500'}`}>{formatPercentage(item.profitPercentage)}</td>
                                </tr>
                            ))}
                            <tr className="font-bold">
                                <td className="border border-t-2 border-t-black px-4 py-2">Totals</td>
                                <td className="border border-t-2 border-t-black px-4 py-2">{formatCurrency(summaryAdsTotals.revenue)}</td>
                                <td className="border border-t-2 border-t-black px-4 py-2">{formatCurrency(summaryAdsTotals.cost)}</td>
                                <td className={`border border-t-2 border-t-black px-4 py-2 ${summaryAdsTotals.profit >= 0 ? 'text-green-500' : 'text-red-500'}`}>{formatCurrency(summaryAdsTotals.profit)}</td>
                                <td className={`border border-t-2 border-t-black px-4 py-2 ${summaryAdsTotals.profit >= 0 ? 'text-green-500' : 'text-red-500'}`}>{formatPercentage((summaryAdsTotals.profit / summaryAdsTotals.revenue))}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="mt-8 mb-4 flex flex-wrap space-x-4">
                    <div className="w-1/3">
                        <table className="table-auto w-full mb-4">
                            <thead>
                                <tr>
                                    <th className="px-4 py-2">Product</th>
                                    <th className="px-4 py-2">Combined Profit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(combinedProfit.products).map(([product, profit], index) => (
                                    <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                                        <td className="border px-4 py-2">{product}</td>
                                        <td className={`border px-4 py-2 ${profit >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                                            {formatCurrency(profit)}
                                        </td>
                                    </tr>
                                ))}
                                <tr className="font-bold">
                                    <td className="border border-t-2 border-t-black px-4 py-2">Total</td>
                                    <td className={`border border-t-2 border-t-black px-4 py-2 ${calculateTotal(combinedProfit.products) >= 0 ? 'text-green-500' : 'text-red-500'}`}>{formatCurrency(calculateTotal(combinedProfit.products))}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="w-1/3">
                        <table className="table-auto w-full mb-4">
                            <thead>
                                <tr>
                                    <th className="px-4 py-2">Combination</th>
                                    <th className="px-4 py-2">Combined Profit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(combinedProfit.combinations).map(([combination, profit], index) => (
                                    <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                                        <td className={`border px-4 py-2 ${combinationColors[combination]}`}>{combination}</td>
                                        <td className={`border px-4 py-2 ${profit >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                                            {formatCurrency(profit)}
                                        </td>
                                    </tr>
                                ))}
                                <tr className="font-bold">
                                    <td className="border border-t-2 border-t-black px-4 py-2">Total</td>
                                    <td className={`border border-t-2 border-t-black px-4 py-2 ${calculateTotal(combinedProfit.combinations) >= 0 ? 'text-green-500' : 'text-red-500'}`}>{formatCurrency(calculateTotal(combinedProfit.combinations))}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex-1">
                        <table className="table-auto w-full mb-4">
                            <thead>
                                <tr>
                                    <th className="px-4 py-2">Angle</th>
                                    <th className="px-4 py-2">Combined Profit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(combinedProfit.angles).map(([angle, profit], index) => (
                                    <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                                        <td className="border px-4 py-2">{angle}</td>
                                        <td className={`border px-4 py-2 ${profit >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                                            {formatCurrency(profit)}
                                        </td>
                                    </tr>
                                ))}
                                <tr className="font-bold">
                                    <td className="border border-t-2 border-t-black px-4 py-2">Total</td>
                                    <td className={`border border-t-2 border-t-black px-4 py-2 ${calculateTotal(combinedProfit.angles) >= 0 ? 'text-green-500' : 'text-red-500'}`}>{formatCurrency(calculateTotal(combinedProfit.angles))}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};


const formatCurrency = (value) => {
    const num = Number(value);
    if (isNaN(num)) {
        return 'N/A';
    }
    return num.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

const formatPercentage = (value) => {
    const num = Number(value) || 0;
    return `${(num * 100).toFixed(2)}%`;
};

export default NativeFb;
