import React, { useState, useEffect } from 'react';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useNavigate } from 'react-router-dom';


const EditorCard = ({ editorData, rank }) => {
    const { dailyStats, profile } = editorData;
    const [selectedMetric, setSelectedMetric] = useState('roas'); // State for the selected metric
    const navigate = useNavigate(); // Initialize useNavigate

    const sortedDates = Object.keys(dailyStats).sort((a, b) => new Date(a) - new Date(b));

    // Prepare chart data to include both roas and spend with formatted dates
    const chartData = sortedDates.map(date => {
      const formattedDate = new Date(date).toLocaleDateString('en-GB', { // 'en-GB' to get day-month order
        day: 'numeric', // Numeric day
        month: 'numeric', // Numeric month
        year: undefined // Omit the year
      }).replace(/\//g, '-'); // Replace slashes with dashes
    
      return {
        date: formattedDate,
        roas: parseFloat(dailyStats[date].roas.toFixed(2)),
        spend: parseFloat(dailyStats[date].spend.toFixed(2)), // Assuming spend is also available in dailyStats
      };
    });

const borderStyles = {
    1: 'Diamond', 
    2: 'Platinum', 
    3: 'Gold', 
    4: 'Silver' 
  };

  const cardClass = borderStyles[rank] || ''; // This will be an empty string if rank is beyond 4

  const handleMetricChange = (e) => {
    setSelectedMetric(e.target.value);
  };

  const handleClick = () => {
    navigate(`/profile/${profile?.name}`);
};


  return (
<div className="editor-card-container max-w-4xl mx-auto my-4">
  <div className={`flex flex-col sm:flex-row items-center bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out ${cardClass}`}>
  <img src={profile?.image_url} alt={profile?.name} className="w-24 h-24 sm:w-32 sm:h-32 object-cover rounded-full m-4 cursor-pointer" onClick={handleClick} />
    <div className="flex flex-col justify-between flex-grow p-4">
    <h2 className="text-black font-bold text-xl mb-2 cursor-pointer" onClick={handleClick}>{profile?.name}</h2>
      <p className="text-grey-darker text-base mb-4">{profile?.bio}</p>
    </div>
    <div className="metric-selector m-4">
      <select value={selectedMetric} onChange={handleMetricChange}>
        <option value="roas">ROAS</option>
        <option value="spend">Spend</option>
      </select>
    </div>
    <div className="editor-graph flex-grow m-4" style={{height: '200px'}}>
      <LineChart width={300} height={200} data={chartData} style={{ maxWidth: '100%' }}>
        <XAxis dataKey="date" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey={selectedMetric} stroke="#8884d8" activeDot={{ r: 8 }} />
      </LineChart>
    </div>
  </div>
</div>



  );
};

export default EditorCard;
