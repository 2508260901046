import { FaExclamation } from "react-icons/fa";
import { MdOndemandVideo } from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import { useState, useContext } from "react";
import { RiVipCrownLine } from "react-icons/ri";
import ReportContext from './ReportContext'; // Ensure correct path

const EditorTable = ({  decodedInfoData, editorProfiles }) => { // Accept customPeriodData as a prop
    const [selected, setSelected] = useState(0);
    const { selectedEditor } = useContext(ReportContext);

console.log('decodedInfoData', decodedInfoData);
console.log('Editor Profiles:', editorProfiles);
    return (
    <section className="p-4">
      <div className="mx-auto max-w-[1600px]">
        <AnimatePresence mode="wait">
          {FEATURES.map((tab, index) => {
            return selected === index ? (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                key={index}
              >
            <tab.Feature decodedInfoData={decodedInfoData} editorProfiles={editorProfiles}/> 
              </motion.div>
            ) : undefined;
          })}
        </AnimatePresence>
      </div>
    </section>
  );
};



const VideoEditorsFeature = ({ decodedInfoData, editorProfiles }) => {
    const { selectedEditor } = useContext(ReportContext);
  
    console.log('decoded Data', decodedInfoData);
    console.log('Profiles', editorProfiles);
    console.log('selected editor', selectedEditor);
  
    // Attempting to find a direct match based on the selected editor's name
    if (!selectedEditor) {
        return <div>Loading or select an editor...</div>; // or any other fallback UI
    }
  
    let adsData = {};
    Object.keys(decodedInfoData.editors).forEach(key => {
      if (key.toLowerCase().includes(selectedEditor?.name.split(' ')[0].toLowerCase())) { // Using optional chaining
        adsData = decodedInfoData.editors[key].ads;
      }
    });
  
    // Convert the adsData object into an array, sort by customIndex, and slice the top 10
    let dataToShow = Object.entries(adsData).map(([adId, adDetails]) => ({
      ad_id: adId,
      ...adDetails
    }))

    


    .sort((a, b) => b.profit - a.profit) // Assuming customIndex is a number to sort by
    .slice(0, 10)
    .map((ad, index) => ({ ...ad, rank: index + 1 })); // Add rank based on array position
  
    // Formatter Functions
    const dollarFormat = (value) => value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value) : '$0.00';
    const percentFormat = (value) => value !== undefined ? `${(value).toFixed(2)}%` : '0.00%';
    const numberFormat = (value) => value ? new Intl.NumberFormat('en-US').format(value) : '0';
    const thousandsFormat = (value) => value ? new Intl.NumberFormat('en-US', { notation: "compact" }).format(value) : '0';
    
    // Modify the getFormattedValue function to handle undefined values
    const getFormattedValue = (item, column) => {
        const value = item[column.key];
        if (value === undefined) {
            return 'N/A'; // or any placeholder you prefer
        }
        return column.formatter ? column.formatter(value) : value;
    };
    const columns = [
        { key: 'total_spend', label: 'Spend', formatter: dollarFormat },
        { key: 'roas', label: 'ROAS', formatter: (value) => value.toFixed(2) },
        { key: 'total_revenue', label: 'Revenue', formatter: dollarFormat },
        { key: 'profit', label: 'Profit', formatter: dollarFormat },
        { key: 'costPer3SecVideoViews', label: 'Cost per 3 Sec', formatter: dollarFormat },
        { key: 'cpa', label: 'CPA', formatter: dollarFormat },
        { key: 'cpc', label: 'CPC', formatter: dollarFormat },
        { key: 'ctr', label: 'CTR', formatter: percentFormat },
        { key: 'cvr', label: 'CVR', formatter: percentFormat },
        { key: 'epc', label: 'EPC', formatter: dollarFormat },
        { key: 'order_count', label: 'Order Count', formatter: numberFormat },
        { key: 'totalThruPlays', label: 'ThruPlay', formatter: numberFormat },
        { key: 'thumbStopRatio', label: 'TSR', formatter: percentFormat },
        { key: 'total3sec', label: 'Views', formatter: numberFormat },
        { key: 'total_impressions', label: 'Impressions', formatter: numberFormat },
        { key: 'unique_clicks', label: 'Clicks', formatter: numberFormat },
        { key: 'videoHoldRate', label: 'Video Hold Rate', formatter: percentFormat },
        { key: 'aov', label: 'AOV', formatter: dollarFormat },
    ];

 


    const selectedEditorName = selectedEditor?.name || 'Select and editor to see their top 10 Ads'; // Fallback if name is not available

      return (
        <div className="w-full px-0 py-8 md:px-8 lg:py-10">
                      <div className="text-center">
            <h2 className="text-2xl font-bold text-slate-700 md:text-3xl">{selectedEditorName}'s Top 10 Ads this Week!</h2>
          </div>
          <div className="relative w-full bg-slate-800 rounded-xl shadow-xl">
            <div className="flex w-full gap-1.5 rounded-t-xl bg-slate-900 p-3">
              <div className="h-3 w-3 rounded-full bg-red-500" />
              <div className="h-3 w-3 rounded-full bg-yellow-500" />
              <div className="h-3 w-3 rounded-full bg-green-500" />
            </div>
      
            <div className="overflow-auto p-2">
              <table className="w-full text-sm text-left text-slate-200">
                <thead className="text-xs text-slate-400 uppercase bg-slate-700">
                  <tr>
                    <th scope="col" className="px-7 py-3 text-center">Rank</th>
                    <th scope="col" className="px-7 py-3 text-center">Ad Name</th>
                    <th scope="col" className="px-7 py-3 text-center">Ad ID</th>
                    {columns.map((column) => (
                      <th key={column.key} scope="col" className="px-7 py-3 text-center">{column.label}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {dataToShow.map((item, index) => (
                    <tr key={index} className={`border-b border-slate-700 ${index % 2 === 0 ? 'bg-slate-700' : 'bg-slate-600'}`}>
                      <td className="px-7 py-4 text-center">{item.rank}</td>
                      <td className="px-7 py-4 text-center">{item.ad_name.split('_').slice(0, 2).join('_')}</td>
                      <td className="px-7 py-4 text-center">{item.ad_id}</td>
                      {columns.map((column) => (
                        <td key={column.key} className="px-7 py-4 text-center">{getFormattedValue(item, column)}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
      
};


export default EditorTable;


const FEATURES = [
    {
      title: "Video Editors",
      Icon: MdOndemandVideo,
      Feature: (props) => <VideoEditorsFeature Icon={MdOndemandVideo} {...props} />,
    },
  ];