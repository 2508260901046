import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Corrected import statement

function ProtectedRoute({ children, allowedRoles }) {
  const token = localStorage.getItem('token');
  if (!token) {
    // If there is no token, redirect to login
    return <Navigate to="/login" />;
  }

  // Decode the token to get user data
  const decodedToken = jwtDecode(token);
  const userRole = decodedToken.role;

  // If allowedRoles is defined and userRole is not in allowedRoles
  if (allowedRoles && !allowedRoles.includes(userRole)) {
    // Special handling for videoeditor role
    if (userRole === 'videoeditor') {
      // Redirect videoeditors to the videoeditors page
      return <Navigate to="/videoeditors" />;
    }
    // For other roles not allowed, redirect to the default page or a not authorized page
    return <Navigate to="/" />;
  }

  // Special handling for the 'jeremy' role
  if (userRole === 'jeremy' && window.location.pathname === '/') {
    return <Navigate to="/jeremy" />;
  }

  return children;
}

export default ProtectedRoute;
