// src/contexts/EditorsContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import io from 'socket.io-client';
import LZString from 'lz-string';
import moment from 'moment-timezone';

// Initialize Socket.IO outside of the component to avoid reconnecting on every render
        const socket = io('https://roasbooster.com');

const EditorsContext = createContext();

export const useEditors = () => useContext(EditorsContext);

export const EditorsProvider = ({ children }) => {
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days').toDate());
  const [endDate, setEndDate] = useState(new Date());
  const [editorsGroupData, setEditorsGroupData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [editorProfiles, setEditorProfiles] = useState([]);
  const [userRole, setUserRole] = useState(''); // Added

  useEffect(() => {
    setIsLoading(true);
    socket.on('editorsData', (data) => {
      localStorage.setItem('editorsData', LZString.compress(JSON.stringify(data)));
      setEditorsGroupData(data);
    });

    socket.on('editorProfiles', (profiles) => {
      setEditorProfiles(profiles);
    });

    return () => {
      socket.off('editorsData');
      socket.off('editorProfiles');
    };
  }, []);

  useEffect(() => {
    const storedEditorsData = localStorage.getItem('editorsData');
    if (storedEditorsData) {
      const decompressedData = LZString.decompress(storedEditorsData);
      if (decompressedData) {
        const editorsData = JSON.parse(decompressedData);
        setEditorsGroupData(editorsData);
      }
    }
    setIsLoading(false);
  }, [startDate, endDate]);

  const updateGroupDataByDate = (start, end) => {
    const days = moment(end).diff(moment(start), 'days') + 1;
    const key = `${findKeyForDays(days)}Editors`; // Assuming findKeyForDays is defined in this context
    const relevantGroupData = editorsGroupData[key];
    if (relevantGroupData) {
      setEditorsGroupData(relevantGroupData);
    }
  };

  // Helper function to find the key for days
  const findKeyForDays = (days) => {
    if (days <= 7) return '7days';
    if (days <= 14) return '14days';
    if (days <= 30) return '30days';
    return '90days';
  };

  return (
    <EditorsContext.Provider value={{
        startDate, setStartDate,
        endDate, setEndDate,
        editorsGroupData, setEditorsGroupData,
        editorProfiles, setEditorProfiles,
        isLoading, setIsLoading, // Make sure to provide these
        userRole, setUserRole, // And these
        updateGroupDataByDate
      }}>      {children}
    </EditorsContext.Provider>
  );
};
