import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ReportManager = () => {
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [reports, setReports] = useState([]);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [groupMappings, setGroupMappings] = useState([]);
  const [editMappingId, setEditMappingId] = useState(null);
  const [editMapping, setEditMapping] = useState({ field_name: '', cell: '' });
  const [newMapping, setNewMapping] = useState({ field_name: 'Orders', cell: '' });
  const [newReport, setNewReport] = useState({ name: '', url: '', type: '', group_id: null });
  const [reportUrl, setReportUrl] = useState('');

  const fieldNames = ['Orders', 'Gross sales', 'Cost', 'Returns', 'New Subs', 'New Sub Revenue'];

  useEffect(() => {
    axios.get('/api/groups').then((response) => {
      setGroups(response.data);
    });
  }, []);

  useEffect(() => {
    if (selectedGroupId) {
      axios.get(`/api/reports/${selectedGroupId}`).then((response) => {
        setReports(response.data);
        setSelectedReportId(null);
      });

      axios.get(`/api/mappings/group/${selectedGroupId}`).then((response) => {
        setGroupMappings(response.data);
      });
    }
  }, [selectedGroupId]);

  useEffect(() => {
    if (selectedReportId) {
      axios.get(`/api/report/${selectedReportId}`).then((response) => {
        setReportUrl(response.data.url);
      });
    }
  }, [selectedReportId]);

  const handleAddMapping = () => {
    const data = { ...newMapping, group_id: selectedGroupId };
    axios.post('/api/mappings', data).then((response) => {
      setGroupMappings([...groupMappings, response.data]);
      setNewMapping({ field_name: 'Orders', cell: '' });
    });
  };

  const handleAddReport = () => {
    const data = { ...newReport, group_id: selectedGroupId };
    axios.post('/api/reports', data).then((response) => {
      setReports([...reports, response.data]);
      setNewReport({ name: '', url: '', type: '', group_id: null });
    });
  };

  const handleEditMapping = (id, mapping) => {
    setEditMappingId(id);
    setEditMapping({ ...mapping });
  };

  const handleUpdateMapping = () => {
    axios.put(`/api/mappings/${editMappingId}`, editMapping).then((response) => {
      setGroupMappings(groupMappings.map(mapping => 
        mapping.id === editMappingId ? response.data : mapping
      ));
      setEditMappingId(null);
      setEditMapping({ field_name: '', cell: '' });
    });
  };

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="max-h-screen overflow-y-auto">
          <h2 className="text-xl font-bold mb-4">Report Groups</h2>
          <ul className="list-group">
            {groups.map((group) => (
              <li
                key={group.id}
                onClick={() => setSelectedGroupId(group.id)}
                className={`p-2 mb-2 cursor-pointer ${group.id === selectedGroupId ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
              >
                {group.name}
              </li>
            ))}
          </ul>
        </div>
        <div className="sticky top-0">
          {selectedGroupId && (
            <>
              <div className="bg-white p-4 shadow">
                <h2 className="text-xl font-bold mb-4">Reports</h2>
                <ul className="list-group">
                  {reports.map((report) => (
                    <li
                      key={report.id}
                      onClick={() => setSelectedReportId(report.id)}
                      className={`p-2 mb-2 cursor-pointer ${report.id === selectedReportId ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                    >
                      {report.name}
                    </li>
                  ))}
                </ul>
                <h2 className="text-xl font-bold mt-4">Cell Mappings for Group</h2>
                <ul className="list-group mt-2">
                  {groupMappings.map((mapping) => (
                    <li key={mapping.id} className="p-2 mb-2 bg-gray-200 rounded">
                      {editMappingId === mapping.id ? (
                        <>
                          <span>{mapping.field_name}: </span>
                          <input
                            type="text"
                            value={editMapping.cell}
                            onChange={(e) => setEditMapping({ ...editMapping, cell: e.target.value })}
                            className="p-1 mr-2 border"
                          />
                          <button onClick={handleUpdateMapping} className="p-1 bg-blue-500 text-white">Save</button>
                        </>
                      ) : (
                        <>
                          {mapping.field_name}: {mapping.cell}
                          <button onClick={() => handleEditMapping(mapping.id, mapping)} className="p-1 ml-2 bg-gray-300">Edit</button>
                        </>
                      )}
                    </li>
                  ))}
                </ul>
                <h2 className="text-xl font-bold mt-4">Add New Cell Mapping</h2>
                <div className="mt-2">
                  <select
                    value={newMapping.field_name}
                    onChange={(e) => setNewMapping({ ...newMapping, field_name: e.target.value })}
                    className="p-2 border mr-2"
                  >
                    {fieldNames.map((fieldName) => (
                      <option key={fieldName} value={fieldName}>
                        {fieldName}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    placeholder="Cell"
                    value={newMapping.cell}
                    onChange={(e) => setNewMapping({ ...newMapping, cell: e.target.value })}
                    className="p-2 border mr-2"
                  />
                  <button onClick={handleAddMapping} className="p-2 bg-blue-500 text-white">Add</button>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="sticky top-0">
          {selectedReportId && (
            <div className="bg-white p-4 shadow">
              <h2 className="text-xl font-bold mb-4">Report URL</h2>
              <a href={reportUrl} target="_blank" rel="noopener noreferrer" className="break-all text-blue-500 underline">
                {reportUrl}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportManager;
