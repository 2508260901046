import React, { useEffect, useRef } from "react";
import { animate, useInView } from "framer-motion";

// Correct placement of Stat component
const Stat = ({ num, suffix, decimals = 0, subheading, isCurrency = false }) => {
    const ref = useRef(null);
  
    useEffect(() => {
      const numericValue = parseFloat(num) || 0;
      const formatter = isCurrency
        ? new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
          })
        : {
            format: (value) => value.toFixed(decimals),
          };
  
      if (ref.current) {
        const controls = animate(0, numericValue, {
          duration: 2.5,
          onUpdate(value) {
            if (ref.current) {
              ref.current.textContent = formatter.format(value) + (suffix && !isCurrency ? suffix : '');
            }
          },
        });
  
        return () => controls.stop();
      }
    }, [num, decimals, suffix, isCurrency]);
  
    return (
      <div className="flex w-72 flex-col items-center py-8 sm:py-0">
        <p className="mb-2 text-center text-7xl font-semibold sm:text-6xl">
          <span ref={ref}></span>
        </p>
        <p className="text-center text-neutral-600">{subheading}</p>
      </div>
    );
  };
  export const CountUpStats = ({ totalSpend, roas, numWinners,  }) => {
    return (
      <div className="mx-auto max-w-3xl px-4 py-20 md:py-24">
  
        <div className="flex flex-col items-center justify-between sm:flex-row">
          <Stat
            num={totalSpend}
            isCurrency={true} // Indicate that this stat should be formatted as currency
            subheading="Spent on FB Ads"
          />
          {/* Use margins for spacing and dividers fixed in position */}
          <div className="mx-4 h-[1px] w-12 bg-indigo-200 sm:h-12 sm:w-[1px] sm:mx-6" />
          <Stat
            num={roas}
            decimals={2}
            suffix="x"
            subheading="ROAS"
          />
          <div className="mx-4 h-[1px] w-12 bg-indigo-200 sm:h-12 sm:w-[1px] sm:mx-6" />
          <Stat
            num={numWinners}
            suffix=""
            subheading="New Winners"
          />
        </div>
      </div>
    );
  };
  