import { FaExclamation } from "react-icons/fa";
import { MdOndemandVideo } from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { RiVipCrownLine } from "react-icons/ri";
import Loader from "./Loader";

const TabsFeatures = ({ customPeriodData, adsInfoData, decodedInfoData, editorProfiles }) => { // Accept customPeriodData as a prop
    const [selected, setSelected] = useState(0);

console.log('decodedInfoData', decodedInfoData);
console.log('adsInfoData', adsInfoData);
console.log('Editor Profiles:', editorProfiles);
    return (
    <section className="p-4">
      <div className="mx-auto max-w-[1600px]">
        <Tabs selected={selected} setSelected={setSelected} />

        <AnimatePresence mode="wait">
          {FEATURES.map((tab, index) => {
            return selected === index ? (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                key={index}
              >
            <tab.Feature customPeriodData={customPeriodData} adsInfoData={adsInfoData} decodedInfoData={decodedInfoData} editorProfiles={editorProfiles}/> 
              </motion.div>
            ) : undefined;
          })}
        </AnimatePresence>
      </div>
    </section>
  );
};

const Tabs = ({ selected, setSelected }) => {
  return (
    <div className="flex overflow-x-scroll">
      {FEATURES.map((tab, index) => {
        return (
          <Tab
            key={index}
            setSelected={setSelected}
            selected={selected === index}
            Icon={tab.Icon}
            title={tab.title}
            tabNum={index}
          />
        );
      })}
    </div>
  );
};

const Tab = ({ selected, Icon, title, setSelected, tabNum }) => {
  return (
    <div className="relative w-full">
      <button
        onClick={() => setSelected(tabNum)}
        className="relative z-0 flex w-full flex-row items-center justify-center gap-4 border-b-4 border-slate-200 bg-white p-6 transition-colors hover:bg-slate-100 md:flex-col"
      >
        <span
          className={`rounded-lg bg-gradient-to-br from-indigo-700 from-10% to-indigo-500 p-3 text-2xl text-white shadow-indigo-400 transition-all duration-300 ${
            selected
              ? "scale-100 opacity-100 shadow-lg"
              : "scale-90 opacity-50 shadow"
          }`}
        >
          <Icon />
        </span>
        <span
          className={`min-w-[150px] max-w-[200px] text-start text-xs text-slate-600 transition-opacity md:text-center ${
            selected ? "opacity-100" : "opacity-50"
          }`}
        >
          {title}
        </span>
      </button>
      {selected && (
        <motion.span
          layoutId="tabs-features-underline"
          className="absolute bottom-0 left-0 right-0 z-10 h-1 bg-indigo-600"
        />
      )}
    </div>
  );
};

const ExampleFeature = ({ Icon, customPeriodData }) => {
    console.log('ExampleFeature customPeriodData', customPeriodData);

    const [showProfit, setShowProfit] = useState(true);
  
    if (!customPeriodData) {
        return <> <Loader /> </> // Or any other loading state representation
    }
  
    const { top10ByCustomIndex, top10ByProfit } = customPeriodData;
    if (!top10ByCustomIndex || !top10ByProfit) {
      return <div>Data is not available.</div>;
    }

    const toggleData = () => setShowProfit(!showProfit);
  
    const dataToShow = showProfit ? top10ByProfit : top10ByCustomIndex;

    // Formatter Functions
    const dollarFormat = (value) => {
      if (value === null || value === undefined || isNaN(value)) {
        return 'N/A';
      }
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    };
    
    const percentFormat = (value) => {
      if (value === null || value === undefined || isNaN(value)) {
        return 'N/A';
      }
      return `${value.toFixed(2)}%`;
    };
    
    const numberFormat = (value) => {
      if (value === null || value === undefined || isNaN(value)) {
        return 'N/A';
      }
      return new Intl.NumberFormat('en-US').format(value);
    };
    
    const thousandsFormat = (value) => {
      if (value === null || value === undefined || isNaN(value)) {
        return 'N/A';
      }
      return new Intl.NumberFormat('en-US', { notation: "compact" }).format(value);
    };
    
    const roasFormat = (value) => {
      if (value === null || value === undefined || isNaN(value)) {
        return 'N/A';
      }
      return `${value.toFixed(2)}`;
    };
    
    // Modify the getFormattedValue function to handle undefined values
    const getFormattedValue = (item, column) => {
      const value = item[column.key];
      if (value === null || value === undefined) {
        return 'N/A'; // Providing a fallback for null or undefined values
      }
      // Apply the formatter function if it exists, else return the value directly
      return column.formatter ? column.formatter(value) : value;
    };
    const columns = [
        { key: 'aov', label: 'AOV', formatter: dollarFormat },
        { key: 'avgWatchTime', label: 'Avg Watch Time', formatter: (value) => `${Math.round(value)}s` },
        { key: 'costPer3SecVideoViews', label: 'Cost per 3 Sec', formatter: dollarFormat },
        { key: 'cpa', label: 'CPA', formatter: dollarFormat },
        { key: 'cpc', label: 'CPC', formatter: dollarFormat },
        { key: 'ctr', label: 'CTR', formatter: percentFormat },
        { key: 'cvr', label: 'CVR', formatter: percentFormat },
        { key: 'epc', label: 'EPC', formatter: dollarFormat },
        { key: 'order_count', label: 'Order Count', formatter: numberFormat },
        { key: 'profit', label: 'Profit', formatter: dollarFormat },
        { key: 'roas', label: 'ROAS', formatter: roasFormat },
        { key: 'thru_play', label: 'ThruPlay', formatter: numberFormat },
        { key: 'thumbStopRatio', label: 'TSR', formatter: percentFormat },
        { key: 'totalViews', label: 'Views', formatter: numberFormat },
        { key: 'total_impressions', label: 'Impressions', formatter: numberFormat },
        { key: 'total_revenue', label: 'Revenue', formatter: dollarFormat },
        { key: 'total_spend', label: 'Spend', formatter: dollarFormat },
        { key: 'unique_clicks', label: 'Clicks', formatter: numberFormat },
        { key: 'videHoldRate', label: 'Video Hold Rate', formatter: percentFormat },
    ];

 

    return (
        <div className="w-full px-0 py-8 md:px-8 lg:py-10">
          <div className="relative w-full bg-slate-800 rounded-xl shadow-xl">
            <div className="flex w-full gap-1.5 rounded-t-xl bg-slate-900 p-3">
              <div className="h-3 w-3 rounded-full bg-red-500" />
              <div className="h-3 w-3 rounded-full bg-yellow-500" />
              <div className="h-3 w-3 rounded-full bg-green-500" />
              <button onClick={toggleData} className="ml-auto rounded bg-indigo-700 px-4 py-2 text-slate-200">
                Change to {showProfit ? 'Custom Index' : 'Profit'}
              </button>
            </div>
    
            <div className="overflow-auto p-2">
                <table className="w-full text-sm text-left text-slate-200">
                    <thead className="text-xs text-slate-400 uppercase bg-slate-700">
                    <tr>
                        <th scope="col" className="px-7 py-3 text-center">Rank</th> {/* Ensure padding matches with tbody and text is centered */}
                        <th scope="col" className="px-7 py-3 text-center">Ad Name</th> {/* Adjust alignment as needed */}
                        <th scope="col" className="px-7 py-3 text-center">Ad ID</th> {/* Adjust alignment as needed */}
                        {columns.map((column) => (
                        // Ensure alignment and padding for each header matches its tbody counterpart
                        <th key={column.key} scope="col" className="px-7 py-3 text-center">{column.label}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {dataToShow.map((item, index) => (
                    <tr key={index} className={`border-b border-slate-700 ${index % 2 === 0 ? 'bg-slate-700' : 'bg-slate-600'}`}>
                        <td className="px-7 py-4 text-center">{index + 1}</td>
                        <td className="px-7 py-4 text-center">{item.ad_name.split('_').slice(0, 2).join('_')}</td>
                        <td className="px-7 py-4 text-center">{item.ad_id}</td>
                        {columns.map((column) => (
                        <td key={column.key} className="px-7 py-4 text-center">{getFormattedValue(item, column)}</td>
                        ))}
                    </tr>
                    ))}
                    </tbody>
                </table>
                </div>

          </div>
        </div>
    );
    
    
};

const WinnersFeature = ({ Icon, adsInfoData }) => {
    // Assuming adsInfoData structure and displaying relevant data
    console.log('Winners adsInfoData', adsInfoData);

    // You can include any state or effect hooks here if needed

    if (!adsInfoData) {
        return <> <Loader /> </> // Or any other loading state representation
    }

    // Assuming adsInfoData has similar structure or adjust accordingly
    const dataToShow = adsInfoData; // Adjust based on how you want to filter or select data

    // Formatter Functions
    const dollarFormat = (value) => value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value) : '$0.00';
    const percentFormat = (value) => value !== undefined ? `${(value).toFixed(2)}%` : '0.00%';
    const numberFormat = (value) => value ? new Intl.NumberFormat('en-US').format(value) : '0';
    const roasFormat = (value) => {
      // Check if the value is null or undefined first
      if (value === null || value === undefined) {
        return 'N/A'; // or '0.00' if you prefer a default value
      }
      return `${value.toFixed(2)}`; // Assuming value is a number
    };

    // Modify the getFormattedValue function to handle undefined values
    const getFormattedValue = (item, column) => {
      const value = item[column.key];
      // Handle both null and undefined values
      if (value === null || value === undefined) {
          return 'N/A'; // or any placeholder you prefer
      }
      return column.formatter ? column.formatter(value) : value;
  };
  
    const columns = [
        { key: 'winner_status', label: 'Type of Winner'},
        { key: 'aov', label: 'AOV', formatter: dollarFormat },
        { key: 'avgWatchTime', label: 'Avg Watch Time', formatter: (value) => `${Math.round(value)}s` },
        { key: 'costPer3SecVideoViews', label: 'Cost per 3 Sec', formatter: dollarFormat },
        { key: 'cpa', label: 'CPA', formatter: dollarFormat },
        { key: 'cpc', label: 'CPC', formatter: dollarFormat },
        { key: 'ctr', label: 'CTR', formatter: percentFormat },
        { key: 'cvr', label: 'CVR', formatter: percentFormat },
        { key: 'epc', label: 'EPC', formatter: dollarFormat },
        { key: 'sum_order_count', label: 'Order Count', formatter: numberFormat },
        { key: 'total_profit', label: 'Profit', formatter: dollarFormat },
        { key: 'roas', label: 'ROAS', formatter: roasFormat },
        { key: 'sum_thru_play', label: 'ThruPlay', formatter: numberFormat },
        { key: 'thumbStopRatio', label: 'TSR', formatter: percentFormat },
        { key: 'sum_unique_3_sec_watch', label: 'Views', formatter: numberFormat },
        { key: 'sum_total_impressions', label: 'Impressions', formatter: numberFormat },
        { key: 'sum_total_revenue', label: 'Revenue', formatter: dollarFormat },
        { key: 'total_spend', label: 'Spend', formatter: dollarFormat },
        { key: 'sum_unique_clicks', label: 'Clicks', formatter: numberFormat },
        { key: 'videoHoldRate', label: 'Video Hold Rate', formatter: percentFormat },
    ];

 

    return (
        <div className="w-full px-0 py-8 md:px-8 lg:py-10">
          <div className="relative w-full bg-slate-800 rounded-xl shadow-xl">
            <div className="flex w-full gap-1.5 rounded-t-xl bg-slate-900 p-3">
              <div className="h-3 w-3 rounded-full bg-red-500" />
              <div className="h-3 w-3 rounded-full bg-yellow-500" />
              <div className="h-3 w-3 rounded-full bg-green-500" />
            </div>
    
            <div className="overflow-auto p-2">
                <table className="w-full text-sm text-left text-slate-200">
                    <thead className="text-xs text-slate-400 uppercase bg-slate-700">
                    <tr>
                        <th scope="col" className="px-7 py-3 text-center">Ad Name</th> {/* Adjust alignment as needed */}
                        <th scope="col" className="px-7 py-3 text-center">Ad ID</th> {/* Adjust alignment as needed */}
                        {columns.map((column) => (
                        // Ensure alignment and padding for each header matches its tbody counterpart
                        <th key={column.key} scope="col" className="px-7 py-3 text-center">{column.label}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {dataToShow.map((item, index) => (
                    <tr key={index} className={`border-b border-slate-700 ${index % 2 === 0 ? 'bg-slate-700' : 'bg-slate-600'}`}>
                        <td className="px-7 py-4 text-center">{item.ad_name.split('_').slice(0, 2).join('_')}</td>
                        <td className="px-7 py-4 text-center">{item.ad_id}</td>
                        {columns.map((column) => (
                        <td key={column.key} className="px-7 py-4 text-center">{getFormattedValue(item, column)}</td>
                        ))}
                    </tr>
                    ))}
                    </tbody>
                </table>
                </div>

          </div>
        </div>
    );
};

const enrichEditorsWithData = (editors, profiles) => {
    // Create a normalized mapping if names can't be matched directly
    const profileMapping = profiles.reduce((acc, profile) => {
      // Normalize the names or use IDs to create a key-value pair
      const key = profile.name.split(' ')[0]; // Simplified example, adjust as needed
      acc[key] = profile;
      return acc;
    }, {});
  
    return Object.entries(editors).map(([editorName, editorDetails]) => {
      const profile = profileMapping[editorName] || {};
      // Combine editorDetails with the corresponding profile information
      return {
        ...editorDetails,
        profileName: profile.name, // Full name from the profile
        imageUrl: profile.image_url,
        bio: profile.bio,
        // Any other fields you need
      };
    });
  };


const VideoEditorsFeature = ({ Icon, decodedInfoData, editorProfiles }) => {
    // Assuming decodedInfoData structure and displaying relevant data
    console.log('Video Editors decodedInfoData', decodedInfoData);


      const enrichedData = enrichEditorsWithData(decodedInfoData.editors, editorProfiles);


    // You can include any state or effect hooks here if needed

    if (!decodedInfoData) {
        return <> <Loader /> </> // Or any other loading state representation
    }

    console.log('EDITORS', decodedInfoData.editors)
    console.log('Enriched', enrichedData);
    // Assuming decodedInfoData has similar structure or adjust accordingly
    const dataToShow = enrichedData; // Adjust based on how you want to filter or select data

    // Formatter Functions
    const dollarFormat = (value) => value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value) : '$0.00';
    const percentFormat = (value) => value !== undefined ? `${(value).toFixed(2)}%` : '0.00%';
    const numberFormat = (value) => value ? new Intl.NumberFormat('en-US').format(value) : '0';
    const thousandsFormat = (value) => value ? new Intl.NumberFormat('en-US', { notation: "compact" }).format(value) : '0';
        const roasFormat = (value) => {
      // Check if the value is null or undefined first
      if (value === null || value === undefined) {
        return 'N/A'; // or '0.00' if you prefer a default value
      }
      return `${value.toFixed(2)}`; // Assuming value is a number
    };
    // Modify the getFormattedValue function to handle undefined values
    const getFormattedValue = (item, column) => {
        const value = item[column.key];
        if (value === undefined) {
            return 'N/A'; // or any placeholder you prefer
        }
        return column.formatter ? column.formatter(value) : value;
    };



    
    const columns = [
        { key: 'total_spend', label: 'Spend', formatter: dollarFormat },
        { key: 'total_revenue', label: 'Revenue', formatter: dollarFormat },
        { key: 'costPer3SecVideoViews', label: 'Cost per 3 Sec', formatter: dollarFormat },
        { key: 'cpa', label: 'CPA', formatter: dollarFormat },
        { key: 'cpc', label: 'CPC', formatter: dollarFormat },
        { key: 'ctr', label: 'CTR', formatter: percentFormat },
        { key: 'cvr', label: 'CVR', formatter: percentFormat },
        { key: 'epc', label: 'EPC', formatter: dollarFormat },
        { key: 'order_count', label: 'Order Count', formatter: numberFormat },
        { key: 'profit', label: 'Profit', formatter: dollarFormat },
        { key: 'roas', label: 'ROAS', formatter: roasFormat },
        { key: 'totalThruPlays', label: 'ThruPlay', formatter: numberFormat },
        { key: 'thumbStopRatio', label: 'TSR', formatter: percentFormat },
        { key: 'total3sec', label: 'Views', formatter: numberFormat },
        { key: 'total_impressions', label: 'Impressions', formatter: numberFormat },
        { key: 'unique_clicks', label: 'Clicks', formatter: numberFormat },
        { key: 'videoHoldRate', label: 'Video Hold Rate', formatter: percentFormat },
        { key: 'aov', label: 'AOV', formatter: dollarFormat },
    ];

 
    const sortedData = [...dataToShow].sort((a, b) => {
        const profitA = parseFloat(a.customIndex);
        const profitB = parseFloat(b.customIndex);
        return profitB - profitA; // For descending order
      });

    return (
        <div className="w-full px-0 py-8 md:px-8 lg:py-10">
          <div className="relative w-full bg-slate-800 rounded-xl shadow-xl">
            <div className="flex w-full gap-1.5 rounded-t-xl bg-slate-900 p-3">
              <div className="h-3 w-3 rounded-full bg-red-500" />
              <div className="h-3 w-3 rounded-full bg-yellow-500" />
              <div className="h-3 w-3 rounded-full bg-green-500" />
            </div>
    
            <div className="overflow-auto p-2">
                <table className="w-full text-sm text-left text-slate-200">
                    <thead className="text-xs text-slate-400 uppercase bg-slate-700">
                    <tr>
                        <th scope="col" className="px-7 py-3 text-center">Rank</th>
                        <th scope="col" className="px-7 py-3 text-center">Image</th>
                        <th scope="col" className="px-10 py-3 text-center">Name</th>
                        {columns.map((column) => (
                        <th key={column.key} scope="col" className="px-7 py-3 text-center">{column.label}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {sortedData
                        .filter(editorDetails => editorDetails.name !== 'Unknown' && editorDetails.name !== 'Unknown Editor')
                        .map((editorDetails, index) => {
                        return (
                        <tr key={index} className={`border-b border-slate-700 ${index % 2 === 0 ? 'bg-slate-700' : 'bg-slate-600'}`}>
                            <td className="px-7 py-4 text-center">{index + 1}</td>
                            <td className="px-7 py-4 text-center flex items-center justify-center">
                            <img src={editorDetails.imageUrl} alt={editorDetails.profileName} style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }} />
                            </td>
                            <td className="px-10 py-4 text-center">
                            <span 
                                className="block overflow-hidden text-ellipsis whitespace-nowrap" 
                                title={editorDetails.profileName || editorDetails.name}>
                                {editorDetails.profileName || editorDetails.name}
                            </span>
                            </td>
                            {columns.map((column) => (
                            <td key={column.key} className="px-7 py-4 text-center">{getFormattedValue(editorDetails, column)}</td>
                            ))}
                        </tr>
                        );
                    })}
                    </tbody>


                </table>
            </div>
          </div>
        </div>
    );
};


export default TabsFeatures;


const FEATURES = [
    {
      title: "Top 10 Ads",
      Icon: RiVipCrownLine,
      Feature: (props) => <ExampleFeature Icon={RiVipCrownLine} {...props} />,
    },
    {
      title: "Winners",
      Icon: FaExclamation,
      Feature: (props) => <WinnersFeature Icon={FaExclamation} {...props} />,
    },
    {
      title: "Video Editors",
      Icon: MdOndemandVideo,
      Feature: (props) => <VideoEditorsFeature Icon={MdOndemandVideo} {...props} />,
    },
  ];
