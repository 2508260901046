import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";
import Youtube from "./Youtube";
import moment from "moment";
import io from 'socket.io-client';

const YT = () => {
    const timezone = 'America/Los_Angeles';
    const todayLA = moment().tz(timezone).startOf('day').toDate();
    const [isClickEnabled, setIsClickEnabled] = useState(true); // State for the checkbox
    const [startDate, setStartDate] = useState(todayLA);
    const [endDate, setEndDate] = useState(todayLA);
    const [userRole, setUserRole] = useState('');
    const [fetchVersion, setFetchVersion] = useState(0); // or use a timestamp if more appropriate
    const [prevFbData, setPrevFbData] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [effectiveStatus, setEffectiveStatus] = useState({
        ads: [],
        campaigns: [],
        adsets: []
    });

    const handleResetSelection = () => {
      setSelectedItems([]); // Reset the selectedItems to an empty array
    };
    // States for Dashboard data
    const [dashboardData, setDashboardData] = useState({
        todaysSales: null,
        ordersCount: null,
        averageOrderValue: null,
        largestOrder: null,
        aggregatedData: {},
        isUpdating: false
    });

    // States for Fb data
    const [fbData, setFbData] = useState({
        adsData: [],
        campaignsData: [],
        adsetsData: [],
        adaccountsData: [],
        isLoading: true,
        isInitialLoad: true
    });

    useEffect(() => {
        const role = localStorage.getItem('userRole');
        setUserRole(role);
    }, []);

    useEffect(() => {
        // Connect to WebSocket server
        const socket = io('https://roasbooster.com');

        socket.on('data-update', (data) => {
            // Check if selected date is today before updating state
            if (isDateToday(moment(startDate)) && isDateToday(moment(endDate))) {

                setFbData({
                    adsData: data.fbData.ads,
                    campaignsData: data.fbData.campaigns,
                    adsetsData: data.fbData.adsets,
                    adaccountsData: data.fbData.adaccounts,
                    isLoading: false,
                    totalProfit: data.fbData.totalProfit,
                });
                setPrevFbData(fbData);
            }
        });

        

        
        socket.on('data-error', (error) => {
            console.error('Error receiving data:', error.message);
        });

        // Clean up on unmount or when dependencies change
        return () => {
            socket.off('data-update');
            socket.off('data-error');
            socket.close();
        };
    }, [startDate, endDate]);

    const fetchData = async () => {
        // Formatted dates
        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];
    
        if (isDateToday(moment(startDate)) && isDateToday(moment(endDate))) {
            // Existing fetching logic for when the selected dates are today
    
            // Logic to fetch today's data for Dashboard
            try {
                setDashboardData(prevState => ({ ...prevState, isUpdating: true }));
                const responseDashboard = await fetch(`/api/orders-by-date?start=${formattedStartDate}&end=${formattedEndDate}`);
                const dataDashboard = await responseDashboard.json();
                setDashboardData({
                    todaysSales: dataDashboard.revenue,
                    ordersCount: dataDashboard.count,
                    averageOrderValue: dataDashboard.revenue / dataDashboard.count,
                    largestOrder: dataDashboard.largestOrder,
                    aggregatedData: dataDashboard.aggregatedData,
                    isUpdating: false
                });
            } catch (error) {
                console.error('Error fetching Dashboard data:', error);
                setDashboardData(prevState => ({ ...prevState, isUpdating: false }));
            }
    
                
                try {
                    setFbData(prevState => ({ ...prevState, isLoading: true }));
                    const timezone = 'America/Los_Angeles';
                    const formattedStartDateFb = new Date(startDate).toLocaleDateString('en-US', { timeZone: timezone });
                    const formattedEndDateFb = new Date(endDate).toLocaleDateString('en-US', { timeZone: timezone });
                
                    const responseFb = await axios.get(`/api/facebook-data?startDate=${formattedStartDateFb}&endDate=${formattedEndDateFb}`);
                    const databaseData = responseFb.data.databaseData; // Extract databaseData from response
                    const effectiveStatus = responseFb.data.databaseData.effectiveStatus; // Extract effectiveStatus
                
                    // Create lookup objects for effectiveStatus
                    const adsStatusLookup = effectiveStatus.ads.reduce((acc, item) => ({ ...acc, [item.id]: item.effective_status }), {});
                    const campaignsStatusLookup = effectiveStatus.campaigns.reduce((acc, item) => ({ ...acc, [item.id]: item.status }), {});
                    const adsetsStatusLookup = effectiveStatus.adsets.reduce((acc, item) => ({ ...acc, [item.id]: item.status }), {});
                
                    // Merge effectiveStatus with database data
                    const adsDataWithStatus = databaseData.ads.map(item => ({ ...item, effective_status: adsStatusLookup[item.ad_id] }));
                    const campaignsDataWithStatus = databaseData.campaigns.map(item => ({ ...item, status: campaignsStatusLookup[item.campaign_id] }));
                    const adsetsDataWithStatus = databaseData.adsets.map(item => ({ ...item, status: adsetsStatusLookup[item.adset_id] }));
                    console.log("Ads Status Lookup:", adsStatusLookup);
                    console.log("Campaigns Status Lookup:", campaignsStatusLookup);
                    console.log("Adsets Status Lookup:", adsetsStatusLookup);
                    
                    

                    setFbData({
                        adsData: adsDataWithStatus,
                        campaignsData: campaignsDataWithStatus,
                        adsetsData: adsetsDataWithStatus,
                        adaccountsData: databaseData.adaccounts, // Assuming adaccounts do not have effective status
                        isLoading: false,
                        totalProfit: databaseData.totalProfit,
                    });
                    setFetchVersion(prevVersion => prevVersion + 1);
                    setPrevFbData(fbData);

                setFetchVersion(prevVersion => prevVersion + 1);
                setPrevFbData(fbData);
            } catch (error) {
                console.error('Error fetching Facebook data:', error);
                setFbData(prevState => ({ ...prevState, isLoading: false }));
            }

            
            
        } else {
            // Fetching logic for when the selected dates are NOT today
            try {
                // Start updating state to show loading
                setDashboardData(prevState => ({ ...prevState, isUpdating: true }));
                setFbData(prevState => ({ ...prevState, isLoading: true }));
    
                // Fetch summary data for the specified date range
                const responseSummary = await axios.get(`/api/summary?startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
                const { adsSummary, adsetsSummary, effectiveStatus, campaignsSummary, accountsSummary, dailyAdsData, dailyAdsetsData, dailyCampaignsData, dailyAccountsData } = responseSummary.data;
    
                const responseDashboard = await axios.get(`/api/dashboard-summary?startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
                const dashboardSummaryData = responseDashboard.data;

                // Update state with the fetched data
                setDashboardData({
                    todaysSales: dashboardSummaryData.revenue,
                    ordersCount: dashboardSummaryData.count,
                    averageOrderValue: dashboardSummaryData.averageOrderValue,
                    largestOrder: dashboardSummaryData.largestOrder,
                    aggregatedData: dashboardSummaryData.aggregatedData,
                    isUpdating: false
                });
    
                setFbData({
                    adsData: adsSummary,
                    campaignsData: campaignsSummary,
                    adsetsData: adsetsSummary,
                    adaccountsData: accountsSummary,
                    isLoading: false,

                    dailyAdsData,
                    dailyAdsetsData,
                    dailyCampaignsData,
                    dailyAccountsData
                });
            } catch (error) {
                console.error('Error fetching data:', error);
                // Update state to reflect the error/fetching completion
                setDashboardData(prevState => ({ ...prevState, isUpdating: false }));
                setFbData(prevState => ({ ...prevState, isLoading: false }));
            }
        }
    };
    
    const isDateToday = (date) => {
        const today = moment().tz(timezone).startOf('day');
        return date.isSame(today, 'day');
    };

    return (
        <div>
            <Header userRole={userRole} />
            <Youtube data={fbData} />
            <footer className="bg-white py-4">
                <div className="max-w-6xl mx-auto px-6 flex justify-center">
                    <p className="text-gray-600">Made by Felix for rybízci at 10FC</p>
                </div>
            </footer>
        </div>
    );
};

export default YT;
