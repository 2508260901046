import { motion } from "framer-motion";
import { useRef, useState, useContext, useEffect } from "react";
import ReportContext from './ReportContext'; // Ensure correct path


const ShuffleCards = ({ decodedInfoData, editorProfiles }) => {
    const { selectedEditor, setSelectedEditor } = useContext(ReportContext);

    useEffect(() => {
      // Automatically set the first editor as selected if none is selected yet
      if (!selectedEditor && editorProfiles.length > 0) {
        setSelectedEditor(editorProfiles[0]);
      }
    }, [editorProfiles, selectedEditor, setSelectedEditor]);

    useEffect(() => {
      // Update the order state to ensure the selected editor is first
      const initialOrder = initializeOrder();
      setOrder(initialOrder);
    }, [editorProfiles, selectedEditor]);

    const initializeOrder = () => {
        if (selectedEditor) {
            const selectedIndex = editorProfiles.findIndex(profile => profile.id === selectedEditor.id);
            if (selectedIndex !== -1) {
                let initialOrder = editorProfiles.map((_, index) => index);
                initialOrder.splice(selectedIndex, 1);
                initialOrder = [selectedIndex, ...initialOrder];
                return initialOrder;
            }
        }
        return editorProfiles.map((_, index) => index);
    };

    const [order, setOrder] = useState(() => initializeOrder());

    const handleShuffle = () => {
      const orderCopy = [...order];
      orderCopy.unshift(orderCopy.pop()); // Move the last element to the front
      setOrder(orderCopy);
      // Optionally, update the selectedEditor based on the new first element
      const newSelectedEditor = editorProfiles[orderCopy[0]];
      setSelectedEditor(newSelectedEditor);
    };

    useEffect(() => {
        // Update the order when selectedEditor changes
        setOrder(initializeOrder());
    }, [editorProfiles, selectedEditor]);

    // Filter the order array to include only the first three elements
    const visibleOrder = order.slice(0, 3);
  
    // Enhance editor profiles with decodedInfoData based on first names
    const enhancedProfiles = editorProfiles.map(profile => {
      // Extract first name
      const firstName = profile.name.split(' ')[0];
      const editorData = decodedInfoData.editors[firstName];
      if (editorData) {
 
        return { ...profile, total_spend: editorData.total_spend.toFixed(2), roas: editorData.roas.toFixed(2) }; // Adding total_spend and ROAS to each profile
      }
      return profile; // In case there's no matching editorData, return the original profile
    });


    return (
      <div className="grid place-content-center overflow-hidden px-8 py-24 text-slate-50">
        <div className="relative -ml-[100px] h-[450px] w-[350px] md:-ml-[175px]">
          {enhancedProfiles.map((editor, index) => (
            visibleOrder.includes(index) && (
              <Card
                key={editor.id}
                imgUrl={editor.image_url}
                spend={`Spend: $${editor.total_spend || 'N/A'}`}
                roas={`ROAS: ${editor.roas || 'N/A'}`}
                author={editor.name}
                handleShuffle={handleShuffle}
                position={visibleOrder.indexOf(index) !== -1 ? visibleOrder.indexOf(index) : "back"}
              />
            )
          ))}
        </div>
      </div>
    );
  };
  
    

const Card = ({ handleShuffle, spend, roas, position, imgUrl, author }) => {
  const mousePosRef = useRef(0);

  const onDragStart = (e) => {
    mousePosRef.current = e.clientX;
  };

  const onDragEnd = (e) => {
    const diff = mousePosRef.current - e.clientX;
    if (diff > 150) {
      handleShuffle();
    }
    mousePosRef.current = 0;
  };

  // Calculate position dynamically based on the card's index in the order array
  const positions = ['front', 'middle', 'back'];
  const x = `${position * 20}%`; // Adjusted to dynamically position cards
  const rotateZ = position === 0 ? "-3deg" : position === 1 ? "0deg" : "3deg";
  const zIndex = `${3 - position}`; // Ensure front card has highest z-index

  const draggable = position === 0; // Only the front card is draggable

  return (
    <motion.div
      style={{
        zIndex,
      }}
      animate={{ rotate: rotateZ, x }}
      drag
      dragElastic={0.35}
      dragListener={draggable}
      dragConstraints={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      transition={{
        duration: 0.35,
      }}
      className={`absolute left-0 top-0 grid h-[450px] w-[350px] select-none place-content-center space-y-6 rounded-2xl border-2 border-slate-700 bg-slate-800 p-6 shadow-xl backdrop-blur-md ${
        draggable ? "cursor-grab active:cursor-grabbing" : ""
      }`}
    >
      <img
        src={imgUrl}
        alt={`Image of ${author}`}
        className="pointer-events-none mx-auto h-32 w-32 rounded-full border-2 border-slate-700 bg-slate-200 object-cover"
      />
    <div className="text-center">
        <span className="block text-lg font-semibold text-slate-300">
          {spend}
        </span>
        <span className="block text-lg font-semibold text-slate-300">
          {roas}
        </span>
      </div>
      <span className="text-center text-xl font-bold text-indigo-400">
        {author}
      </span>
    </motion.div>
  );
};

export default ShuffleCards;
