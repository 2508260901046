import React from 'react';

const ReportContext = React.createContext({
  data: {}, // Existing data structure
  setWeek: () => {}, // Existing function to update the week
  selectedEditor: null, // Add this line to track the selected editor
  setSelectedEditor: () => {}, // And a function to update the selected editor
});

export default ReportContext;
