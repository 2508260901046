import React, { useState } from 'react';
import axios from 'axios';

function AddProfileModal({ setProfiles, onClose }) {
  const [formData, setFormData] = useState({
    name: '',
    bio: '',
    image: null,
    additional_info: '',
    facebook_ads_short: ''
  });

  const handleChange = (e) => {
    if (e.target.name === 'image') {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const data = new FormData();
    data.append('name', formData.name);
    data.append('bio', formData.bio);
    data.append('image', formData.image);
    data.append('additional_info', JSON.stringify({
        info: formData.additional_info, // Assuming additional_info needs to be an object
      }));
    data.append('facebook_ads_short', formData.facebook_ads_short);

    try {
      const response = await axios.post(`${API_BASE_URL}/editor-profiles`, data);
      setProfiles(prev => [...prev, response.data]);
      onClose(); // Close the modal after successful operation
    } catch (error) {
      console.error('Error adding profile:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <form className="bg-white p-5 rounded-lg" onSubmit={handleSubmit}>
        <h2 className="text-lg font-bold mb-6">Add New Profile</h2>
        <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} className="border p-2 mb-4 w-full" />
        <textarea name="bio" placeholder="Bio" value={formData.bio} onChange={handleChange} className="border p-2 mb-4 w-full"></textarea>
        <input type="file" name="image" onChange={handleChange} className="border p-2 mb-4 w-full" />
        <input type="text" name="facebook_ads_short" placeholder="Facebook Ads Short" value={formData.facebook_ads_short} onChange={handleChange} className="border p-2 mb-4 w-full" />
        <button type="submit" className="bg-blue-500 text-white p-2 rounded">Add Profile</button>
        <button type="button" onClick={onClose} className="bg-red-500 text-white p-2 rounded ml-4">Cancel</button>
      </form>
    </div>
  );
}

export default AddProfileModal;
