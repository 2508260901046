import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faCopy } from '@fortawesome/free-solid-svg-icons';
import Header from './Header';

const QueryForm = () => {
  const [source, setSource] = useState('');
  const [columnOptions, setColumnOptions] = useState([]);
  const [dataOptions, setDataOptions] = useState([]);
  const [rows, setRows] = useState([{ 
    source: '', 
    data: '', 
    column: '', 
    condition: 'contains', 
    value: '', 
    excludeAngleCodes: false, // New flag for excluding angle codes
    visibleCells: [true, true, true, true, true], 
    andOr: 'and' 
  }]);
  const [queryName, setQueryName] = useState('');
  const [savedQueries, setSavedQueries] = useState([]);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const role = localStorage.getItem('userRole');
    setUserRole(role);
  }, []);

  useEffect(() => {
    if (source) {
      if (source === 'Shopify') {
        setDataOptions(['Revenue', 'Cost', 'Order Count', 'New Subs Count', 'Returns']);
        setColumnOptions(['Vendor']);
      } else {
        setDataOptions(['Ad spend', 'Impressions', 'Clicks']);
        
        axios.get('/api/data-options')
          .then(response => {
            const lowerSource = source.toLowerCase();
            if (response.data[lowerSource]) {
              setColumnOptions(response.data[lowerSource]);
            } else {
              setColumnOptions([]);
            }
          })
          .catch(error => {
            console.error('Error fetching data options:', error);
            setColumnOptions([]);
          });
      }
    } else {
      setDataOptions([]);
      setColumnOptions([]);
    }
  }, [source]);

  useEffect(() => {
    fetchSavedQueries();
  }, []);

  const fetchSavedQueries = async () => {
    try {
      const response = await axios.get('/api/saved-queries');
      setSavedQueries(response.data);
    } catch (error) {
      console.error('Error fetching saved queries:', error);
    }
  };

  const handleSourceChange = (e) => {
    const selectedSource = e.target.value;
    setSource(selectedSource);
    setRows(rows.map((row, index) => {
      if (index === 0) {
        return { ...row, source: selectedSource };
      }
      return row;
    }));
  };

  const handleScanSheet = async () => {
    try {
      const response = await axios.post('/api/scan-sheet');
      console.log(response.data);
    } catch (error) {
      console.error('Error scanning sheet:', error);
    }
  };

  const handleAddRow = (index) => {
    const lastRow = rows[rows.length - 1];
    const newVisibleCells = [false, false, false, false, false];
    for (let i = index; i < newVisibleCells.length; i++) {
      newVisibleCells[i] = true;
    }
    setRows([...rows, { source: lastRow.source, data: lastRow.data, column: '', condition: 'contains', value: '', visibleCells: newVisibleCells, andOr: 'and' }]);
  };

  const handleToggleAndOr = (rowIndex) => {
    setRows(rows.map((row, index) => {
      if (index === rowIndex) {
        return { ...row, andOr: row.andOr === 'and' ? 'or' : 'and' };
      }
      return row;
    }));
  };

  const handleSubmit = async () => {
    const cleanedRows = rows.map((row, index) => {
      if (index === rows.length - 1 && rows.length > 1) {
        const { andOr, ...cleanedRow } = row;
        return cleanedRow;
      }
      return row;
    });
  
    if (source === 'Shopify') {
      for (let data of dataOptions) {
        const queryData = cleanedRows.map((row) => ({
          source: row.source,
          data,
          column: row.column,
          condition: row.condition,
          value: row.value,
          ...(row.andOr && { andOr: row.andOr }), // Conditionally add 'andOr' if it exists
        }));
  
        try {
          const response = await axios.post('/api/query', { queryData, name: `${queryName} (${data})` });
          console.log('Query result:', response.data.result);
          console.log('Query ID:', response.data.queryId);
        } catch (error) {
          console.error('Error sending query:', error);
        }
      }
    } else {
      const queryData = cleanedRows.map((row) => ({
        source: row.source,
        data: row.data,
        column: row.column,
        condition: row.condition,
        value: row.value,
        ...(row.andOr && { andOr: row.andOr }), // Conditionally add 'andOr' if it exists
      }));
  
      try {
        const response = await axios.post('/api/query', { queryData, name: queryName || 'My Query' });
        console.log('Query result:', response.data.result);
        console.log('Query ID:', response.data.queryId);
      } catch (error) {
        console.error('Error sending query:', error);
      }
    }
  
    fetchSavedQueries(); // Refresh saved queries
  };
  
  

  const handleDeleteQuery = async (id) => {
    try {
      await axios.delete(`/api/saved-queries/${id}`);
      fetchSavedQueries(); // Refresh saved queries
    } catch (error) {
      console.error('Error deleting query:', error);
    }
  };

  const handleEditQuery = (query) => {
    setSource(query.query_data[0].source);
    setRows(query.query_data.map((row) => ({
      ...row,
      visibleCells: [true, true, true, true, true]
    })));
  };

  const handleDuplicateQuery = async (id) => {
    try {
      const response = await axios.post(`/api/duplicate-query/${id}`);
      console.log('Duplicated query ID:', response.data.newQueryId);
      fetchSavedQueries(); // Refresh saved queries
    } catch (error) {
      console.error('Error duplicating query:', error);
    }
  };

  const formatQuery = (query) => {
    return query.query_data.map((row, index) => {
      return `Row ${index + 1}: Source ${row.source}, Data ${row.data}, Column ${row.column}, Condition ${row.condition}, Value ${row.value}` +
             (index < query.query_data.length - 1 ? `, And/Or: ${row.andOr}` : '');
    }).join('\n');
  };

  const handleConditionChange = (index, condition) => {
    setRows(rows.map((row, i) => i === index ? { ...row, condition } : row));
  };
  


  return (
    <div>
      <Header userRole={userRole} />
      <div className="container mx-auto p-4 border rounded-lg">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Query Name</label>
          <input
            type="text"
            value={queryName}
            onChange={(e) => setQueryName(e.target.value)}
            className="mt-1 p-2 border rounded-lg w-full"
            placeholder="Enter query name"
          />
        </div>
        {rows.map((row, rowIndex) => (
          <div key={rowIndex}>
            <div className="text-center mb-2">
              <strong>Row {rowIndex + 1}</strong>
            </div>
            <div className="grid grid-cols-5 gap-2 my-4">
              <div className="relative">
                {row.visibleCells[0] && (
                  <select
                    name={`source-${rowIndex}`}
                    value={row.source}
                    onChange={handleSourceChange}
                    className="border p-2 rounded-lg w-full text-center"
                  >
                    <option value="" disabled>Select Source</option>
                    {['Shopify', 'LiveIntent', 'RevContent', 'Mediago', 'Taboola', 'Outbrain'].map((src) => (
                      <option key={src} value={src}>{src}</option>
                    ))}
                  </select>
                )}
                {rowIndex === rows.length - 1 && (
                  <button
                    onClick={() => handleAddRow(0)}
                    className="absolute bottom-[-1.5rem] left-1/2 transform -translate-x-1/2 w-6 h-6 bg-blue-500 text-white rounded-full flex items-center justify-center mx-auto"
                  >+</button>
                )}
              </div>
              <div className="relative">
                {row.visibleCells[1] && (
                  <select
                    name={`data-${rowIndex}`}
                    value={row.data}
                    disabled={!source}
                    onChange={(e) => setRows(rows.map((r, i) => i === rowIndex ? { ...r, data: e.target.value } : r))}
                    className="border p-2 rounded-lg w-full text-center"
                  >
                    <option value="" disabled>Select Data</option>
                    {dataOptions.map((data) => (
                      <option key={data} value={data}>{data}</option>
                    ))}
                  </select>
                )}
                {rowIndex === rows.length - 1 && (
                  <button
                    onClick={() => handleAddRow(1)}
                    className="absolute bottom-[-1.5rem] left-1/2 transform -translate-x-1/2 w-6 h-6 bg-blue-500 text-white rounded-full flex items-center justify-center mx-auto"
                  >+</button>
                )}
              </div>
              <div className="relative">
                {row.visibleCells[2] && (
                  <select
                    name={`column-${rowIndex}`}
                    value={row.column}
                    disabled={!source}
                    onChange={(e) => setRows(rows.map((r, i) => i === rowIndex ? { ...r, column: e.target.value } : r))}
                    className="border p-2 rounded-lg w-full text-center"
                  >
                    <option value="" disabled>Select Column</option>
                    {columnOptions.map((col) => (
                      <option key={col} value={col}>{col}</option>
                    ))}
                  </select>
                )}
                {rowIndex === rows.length - 1 && (
                  <button
                    onClick={() => handleAddRow(2)}
                    className="absolute bottom-[-1.5rem] left-1/2 transform -translate-x-1/2 w-6 h-6 bg-blue-500 text-white rounded-full flex items-center justify-center mx-auto"
                  >+</button>
                )}
              </div>
              <div className="relative">
                {row.visibleCells[3] && (
                  <select
                    name={`condition-${rowIndex}`}
                    value={row.condition}
                    onChange={(e) => handleConditionChange(rowIndex, e.target.value)}
                    className="border p-2 rounded-lg w-full text-center"
                  >
                    <option value="contains">Contains</option>
                    <option value="is equal to">Is Equal To</option>
                    <option value="doesn't include any angle code">Doesn't Include Any Angle Code</option>
                  </select>
                )}
                {rowIndex === rows.length - 1 && (
                  <button
                    onClick={() => handleAddRow(3)}
                    className="absolute bottom-[-1.5rem] left-1/2 transform -translate-x-1/2 w-6 h-6 bg-blue-500 text-white rounded-full flex items-center justify-center mx-auto"
                  >+</button>
                )}
              </div>
              <div className="relative">
                {row.visibleCells[4] && (
                  <input
                    name={`value-${rowIndex}`}
                    type="text"
                    value={row.value}
                    onChange={(e) => setRows(rows.map((r, i) => i === rowIndex ? { ...r, value: e.target.value } : r))}
                    placeholder="Value"
                    className="border p-2 rounded-lg w-full text-center"
                  />
                )}
                {rowIndex === rows.length - 1 && (
                  <button
                    onClick={() => handleAddRow(4)}
                    className="absolute bottom-[-1.5rem] left-1/2 transform -translate-x-1/2 w-6 h-6 bg-blue-500 text-white rounded-full flex items-center justify-center mx-auto"
                  >+</button>
                )}
              </div>
            </div>
            {rowIndex < rows.length - 1 && (
              <div className="text-center my-2">
                <button
                  onClick={() => handleToggleAndOr(rowIndex)}
                  className="text-blue-500"
                >
                  {row.andOr}
                </button>
              </div>
            )}
          </div>
        ))}
        <button
          onClick={handleSubmit}
          className="mt-10 w-32 h-12 bg-green-500 text-white rounded-lg flex items-center justify-center mx-auto"
        >Submit</button>
        <button
          onClick={handleScanSheet}
          className="mt-4 w-32 h-12 bg-blue-500 text-white rounded-lg flex items-center justify-center mx-auto"
        >Scan Sheet</button>
        
        <div className="mt-8">
          <h2 className="text-lg font-bold mb-4">Saved Queries</h2>
          {savedQueries.map((query) => (
            <div key={query.id} className="border p-2 rounded-lg mb-4">
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="font-semibold">{query.name}</h3>
                  <h3 className="font-bold">Query ID: {query.id}</h3>
                  <pre className="text-xs whitespace-pre-wrap">{formatQuery(query)}</pre>
                </div>
                <div className="flex items-center">
                  <button
                    onClick={() => handleEditQuery(query)}
                    className="mr-2 p-1 text-white rounded"
                  >
                    <FontAwesomeIcon icon={faEdit} className="text-yellow-500" />
                  </button>
                  <button
                    onClick={() => handleDuplicateQuery(query.id)}
                    className="p-1 text-white rounded"
                  >
                    <FontAwesomeIcon icon={faCopy} className="text-blue-500" />
                  </button>
                  <button
                    onClick={() => handleDeleteQuery(query.id)}
                    className="mr-2 p-1 text-white rounded"
                  >
                    <FontAwesomeIcon icon={faTrash} className="text-red-500" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QueryForm;
