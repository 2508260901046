"use client";
import React, { useEffect, useState } from "react";
import { AnimatedTooltip} from './profilerows'


function AnimatedTooltipPreview() {
    // Initialize state for editor profiles
    const [editorProfiles, setEditorProfiles] = useState([]);
  
    // Fetch editor profiles and update state
    useEffect(() => {
      const fetchEditorProfiles = async () => {
        try {
    const response = await fetch('https://roasbooster.com/api/editor-profiles');
          const data = await response.json();
          setEditorProfiles(data.map(profile => ({
            id: profile.id,
            name: profile.name,
            image: profile.image_url,
            // Add other properties as needed
          })));
        } catch (error) {
          console.error('Failed to fetch editor profiles:', error);
        }
      };
    
      fetchEditorProfiles();
    }, []);
  
    // Pass editorProfiles as items prop to AnimatedTooltip
    return (
      <div className="flex flex-row items-center justify-center mt-20 w-full">
        <AnimatedTooltip items={editorProfiles} />
      </div>
    );
  }
  
  export default AnimatedTooltipPreview;
