import React from 'react';

// SummaryCard Component
function SummaryCard({ title, value }) {
  return (
    <div className="bg-white border overflow-hidden rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <dt className="text-sm font-medium text-gray-500 truncate">{title}</dt>
        <dd className="mt-1 text-3xl font-semibold text-gray-900">{value ?? 'N/A'}</dd>
      </div>
    </div>
  );
}

function formatCurrency(value) {
  // Check if the value is null or undefined before formatting
  if (value == null) {
    return 'N/A'; // Return 'N/A' if the value is null or undefined
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
}

function formatPercentage(value) {
  // Ensure the value is a number and not null or zero before calling toFixed
  if (typeof value !== 'number' || value == null) {
    return 'N/A';
  }
  return `${value.toFixed(2)}%`;
}

// Component to calculate and display summary cards
const VendorSummary = ({ vendorData }) => {
  console.log('vendorData', vendorData);
  // Use optional chaining and nullish coalescing to handle null or undefined values
  const totalSales = vendorData?.totalRevenue ?? null;
  const totalProfit = vendorData?.profit ?? null;
  const averageROAS = parseFloat(vendorData?.roas) ?? null;
  const CVR = parseFloat(vendorData?.CVR) ?? null;
  
  const totalSpend = vendorData?.totalSpend ?? null;


  return (
    <div className="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <SummaryCard title="Facebook Total Sales (VENDOR)" value={formatCurrency(totalSales)} />
      <SummaryCard title="Facebook Total Profit (VENDOR)" value={formatCurrency(totalProfit)} />
      <SummaryCard title="Facebook ROAS (VENDOR)" value={formatPercentage(averageROAS)} />
      <SummaryCard title="Facebook Total Spend (VENDOR)" value={formatCurrency(totalSpend)} />
      <SummaryCard title="Facebook CVR (VENDOR)" value={formatPercentage(CVR)} />
    </div>
  );
};

export default VendorSummary;
