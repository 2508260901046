import React, { useState, useEffect, useMemo, useRef } from 'react'; // Import useRef

const DailyDataBreakdown = ({ dailyData, type }) => {
    const [showAccountId, setShowAccountId] = useState(false); 
    const accountNames = {
        '798134188164544': '10FC_03_US',
        '489501699514603': '10FC_05_US',
        '1826497317736494': '10FC_06_US'
    };

    const formatValue = (key, value) => {
        // Handle null values
        if (value === null) {
            return 'N/A'; // Placeholder for null values
        }


        if (key === 'account_id') {
          return showAccountId ? (
              <span className="hover:underline cursor-pointer" onClick={() => setShowAccountId(false)}>
                  {value}
              </span>
          ) : (
              <span className="hover:underline cursor-pointer" onClick={() => setShowAccountId(true)}>
                  {accountNames[value] || value}
              </span>
          );
      }
        const unformattedFields = ['account_id', 'campaign_id', 'adset_id', 'ad_id'];
        if (unformattedFields.includes(key)) {
            return value;
        }

        // Convert string to number if it's a numeric string
        let numericValue = (typeof value === 'string' && !isNaN(value)) ? parseFloat(value) : value;

        const dateFields = ['created_at', 'date']; // Add other date fields if needed
        if (dateFields.includes(key)) {
            // Format the date as YYYY-MM-DD
            const date = new Date(value);
            date.setDate(date.getDate()); // Add one day to the date
            return date.toISOString().split('T')[0];
        }
        
        // Check if numericValue is actually a number
        if (typeof numericValue !== 'number') {
            return value; // Return original value if it's not a number
        }
        // Define the formatter for currency
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    
        // Define the formatter for percentages
        const percentageFormatter = new Intl.NumberFormat('en-US', {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    
        // Define which keys correspond to which types of data
        const currencyFields = ['total_spend', 'total_revenue', 'profit', 'cpa', 'aov', 'epc', 'cpc', 'cpm'];
        const percentageFields = ['ctr', 'cvr', 'profit_margin'];
        const wholeNumberFields = ['total_impressions', 'unique_clicks', 'order_count'];
        
    if (currencyFields.includes(key)) {
        return currencyFormatter.format(numericValue);
    } else if (percentageFields.includes(key)) {
        // Convert the decimal to a percentage and format
        return percentageFormatter.format(numericValue / 100);
    } else if (wholeNumberFields.includes(key)) {
        // Format whole numbers without decimal places
        return Math.round(numericValue).toLocaleString();
    } else {
        // For other numerical values, limit to 2 decimal places
        return numericValue.toFixed(2);
    }
    
};

const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'descending' });

const requestSort = (key) => {
  let direction = 'ascending';
  if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    direction = 'descending';
  }
  setSortConfig({ key, direction });
};

const sortedData = useMemo(() => {
  let sortableData = [...dailyData];
  if (sortConfig.key !== null) {
    sortableData.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  }
  return sortableData;
}, [dailyData, sortConfig]);



const getColumnConfig = (type) => {
  switch (type) {
    case 'account':
      return [
        { key: 'created_at', label: 'Date' },
        { key: 'total_spend', label: 'Spend' },
        { key: 'roas', label: 'ROAS' },
        { key: 'profit', label: 'Profit' },
        { key: 'profit_margin', label: 'Profit %' },
        { key: 'cpa', label: 'CPA' },
        { key: 'aov', label: 'AOV' },
        { key: 'cvr', label: 'CVR' },
        { key: 'epc', label: 'EPC' },
        { key: 'cpc', label: 'CPC' },
        { key: 'ctr', label: 'CTR' },
        { key: 'cpm', label: 'CPM' },
        { key: 'unique_clicks', label: 'Clicks' },
        { key: 'order_count', label: 'PUR' },
        { key: 'total_revenue', label: 'Revenue' }
        // Add other columns specific to 'account'
      ];
    case 'campaign':
      return [
        { key: 'created_at', label: 'Date' },
        { key: 'total_spend', label: 'Spend' },
        { key: 'roas', label: 'ROAS' },
        { key: 'profit', label: 'Profit' },
        { key: 'profit_margin', label: 'Profit %' },
        { key: 'cpa', label: 'CPA' },
        { key: 'aov', label: 'AOV' },
        { key: 'cvr', label: 'CVR' },
        { key: 'epc', label: 'EPC' },
        { key: 'cpc', label: 'CPC' },
        { key: 'ctr', label: 'CTR' },
        { key: 'cpm', label: 'CPM' },
        { key: 'unique_clicks', label: 'Clicks' },
        { key: 'order_count', label: 'PUR' },
        { key: 'total_revenue', label: 'Revenue' },
        { key: 'campaign_id', label: 'Campaign ID' },
        { key: 'account_id', label: 'Acc Name' },

        // Add other columns specific to 'campaign'
      ];
    case 'adset':
      return [
        { key: 'created_at', label: 'Date' },
        { key: 'total_spend', label: 'Spend' },
        { key: 'roas', label: 'ROAS' },
        { key: 'profit', label: 'Profit' },
        { key: 'profit_margin', label: 'Profit %' },
        { key: 'cpa', label: 'CPA' },
        { key: 'aov', label: 'AOV' },
        { key: 'cvr', label: 'CVR' },
        { key: 'epc', label: 'EPC' },
        { key: 'cpc', label: 'CPC' },
        { key: 'ctr', label: 'CTR' },
        { key: 'cpm', label: 'CPM' },
        { key: 'unique_clicks', label: 'Clicks' },
        { key: 'order_count', label: 'PUR' },
        { key: 'total_revenue', label: 'Revenue' },
        { key: 'adset_id', label: 'Adset ID' },
        { key: 'account_id', label: 'Acc Name' },

        // Add other columns specific to 'adset'
      ];
    case 'ads':
      return [
        { key: 'created_at', label: 'Date' },
        { key: 'ad_name', label: 'Ads Name' },
        { key: 'total_spend', label: 'Spend' },
        { key: 'roas', label: 'ROAS' },
        { key: 'profit', label: 'Profit' },
        { key: 'profit_margin', label: 'Profit %' },
        { key: 'cpa', label: 'CPA' },
        { key: 'aov', label: 'AOV' },
        { key: 'cvr', label: 'CVR' },
        { key: 'epc', label: 'EPC' },
        { key: 'cpc', label: 'CPC' },
        { key: 'ctr', label: 'CTR' },
        { key: 'cpm', label: 'CPM' },
        { key: 'unique_clicks', label: 'Clicks' },
        { key: 'order_count', label: 'PUR' },
        { key: 'total_revenue', label: 'Revenue' },
        { key: 'ad_id', label: 'Ads ID' },

        // Add other columns specific to 'ads'
      ];
    default:
      return []; // Default case
  }
};



const calculatePercentiles = (dailyData, field) => {
  const values = dailyData.map(item => parseFloat(item[field])).filter(item => !isNaN(item));
  values.sort((a, b) => a - b);
  
  const percentiles = {
    min: Math.min(...values),
    max: Math.max(...values)
  };
  
  return percentiles;
};

// Calculate percentiles for each field
const roasPercentiles = useMemo(() => calculatePercentiles(dailyData, 'roas'), [dailyData]);
const cpcPercentiles = useMemo(() => calculatePercentiles(dailyData, 'cpc'), [dailyData]);

const getColorForValueLog = (value, percentiles, isLowerBetter = false) => {
    // Ensure percentiles are passed as a parameter
    const logValue = Math.log(value + 1); // Adding 1 to avoid log(0)
    const normalizedValue = (logValue - Math.log(percentiles.min + 1)) / (Math.log(percentiles.max + 1) - Math.log(percentiles.min + 1));
    
    // Check if lower values are better, then invert the color scale
    if (isLowerBetter) {
      if (normalizedValue <= 0.2) return '#008000'; // Green
      if (normalizedValue <= 0.4) return '#9ACD32'; // Light Green
      if (normalizedValue <= 0.6) return '#FFFF00'; // Yellow
      if (normalizedValue <= 0.8) return '#f1807e'; // Light Red
      return '#FF4500'; // Red
    } else {
      if (normalizedValue <= 0.2) return '#FF4500'; // Red
      if (normalizedValue <= 0.4) return '#f1807e'; // Light Red
      if (normalizedValue <= 0.6) return '#FFFF00'; // Yellow
      if (normalizedValue <= 0.8) return '#9ACD32'; // Light Green
      return '#008000'; // Green
    }
  };
  
  const columns = useMemo(() => getColumnConfig(type), [type, roasPercentiles /* Add other percentiles here too */]);


      return (
        <div className="daily-data-breakdown overflow-x-auto px-5 py-3">
          <table className="w-full text-sm text-left text-white dark:text-white border-3 border-white-800">
            <thead className="bg-black dark:bg-gray-800 shadow text-center sticky top-0 z-10">
              <tr>
                {columns.map(({ key, label }) => (
                  <th key={key} className="py-3 px-6 cursor-pointer" onClick={() => requestSort(key)}>
                    {label}
                    {sortConfig.key === key && (
                      <span>{sortConfig.direction === 'ascending' ? ' ↑' : ' ↓'}</span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
            {sortedData.map((item, index) => (
            <tr key={index} className={`${index % 2 === 0 ? 'bg-black dark:bg-gray-200' : 'bg-gray-100 dark:bg-gray-200'}`}>
              {columns.map(({ key }) => {
                let cellStyle = {};
                const val = parseFloat(item[key]);

                // Apply color formatting for 'roas' and 'cpc' fields
                if (key === 'roas') {
                  cellStyle.backgroundColor = getColorForValueLog(val, roasPercentiles);
                  cellStyle.color = 'black'; // Set text color to black for better readability
                } else if (key === 'cpc') {
                  cellStyle.backgroundColor = getColorForValueLog(val, cpcPercentiles, true);
                  cellStyle.color = 'black'; // Set text color to black for better readability
                }

                return (
                  <td key={key} style={cellStyle} className={`py-4 text-center px-3 ${index % 2 === 0 ? 'dark:bg-gray-700' : 'bg-gray-50 dark:bg-gray-800'}`}>
                    {formatValue(key, item[key])}
                  </td>
                );
              })}
            </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    };
    
    export default DailyDataBreakdown;
