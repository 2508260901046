import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import logo from './10fc black.png';
import NativeFb from './NativeFb';
import QueryForm from './QueryForm';

const Header = () => {
  const navigate = useNavigate();
  const userRole = localStorage.getItem('userRole');

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <header className="bg-transparent py-4">
      <nav className="max-w-6xl mx-auto px-6 text-sm flex flex-col md:flex-row justify-between items-center">
        {/* Logo */}
        <div className="mb-4 md:mb-0">
          <Link to="/" className="text-gray-900 hover:text-blue-600 font-medium">
            <img src={logo} alt="Dashboard Logo" className="h-6 mx-auto md:mx-0" />
          </Link>
        </div>

        {/* Links and Logout Button */}
        <div className="flex flex-row justify-between items-center space-x-6 w-full md:w-auto">
          {/* Conditionally render links based on role or specific user */}
          {(userRole === 'admin' || userRole === 'user' || userRole === 'zainab') && (
            <>
              <Link to="/stats" className="text-gray-900 hover:text-blue-600 font-medium">Statistics</Link>
            </>
          )}
          {/* Admin-specific links */}
          {userRole === 'admin' && (
            <>

              <Link to="/weekly" className="text-gray-900 hover:text-blue-600 font-medium">Weekly FB</Link>
              <Link to="/nativefb" className="text-gray-900 hover:text-blue-600 font-medium">Native+FB</Link>
              <Link to="/native" className="text-gray-900 hover:text-blue-600 font-medium">Native</Link>
              <Link to="/users" className="text-gray-900 hover:text-blue-600 font-medium">Create User</Link>
              <Link to="/nativesheet" className="text-gray-900 hover:text-blue-600 font-medium">Sheet</Link>
            </>
          )}
          {(userRole === 'admin' || userRole === 'user' || userRole === 'zainab') && (
            <>
              <Link to="/videoeditors" className="text-gray-900 hover:text-blue-600 font-medium">Video Editors</Link>
            </>
          )}
          {/* Additional link for user 'zainab' except for manage and create users */}
          {(userRole === 'zainab' || userRole === 'admin') && (
            <>
              <Link to="/manage-editors" className="text-gray-900 hover:text-blue-600 font-medium">Manage Editors</Link>

            </>

          )}
          {(userRole === 'jeremy' || userRole === 'admin') && (
            <>
              <Link to="/jeremy" className="text-gray-900 hover:text-blue-600 font-medium">Jeremy Stats</Link>
            </>
          )}
          <button
            onClick={handleLogout}
            className="text-blue-600 hover:text-white border border-blue-600 hover:bg-blue-600 rounded-full px-5 py-2 transition-colors duration-300"
          >
            Log Out
          </button>
        </div>
      </nav>
    </header>
  );
};


export default Header;
