import React, { useState, useEffect } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarController,
    BarElement
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, BarController, BarElement, PointElement, LineElement, ChartDataLabels, Title, Tooltip, Legend);

function throttle(func, limit) {
    let inThrottle;
    return function() {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
            func.apply(context, args);
            inThrottle = true;
            setTimeout(() => inThrottle = false, limit);
        }
    }
}

const Statistics = ({ data, groupData, currentSortMethod }) => {
    const [selectedAds, setSelectedAds] = useState({});
    const [groupSelections, setGroupSelections] = useState({});
    const [activeSection, setActiveSection] = useState('');
    const [isSmoothScrolling, setIsSmoothScrolling] = useState(false);

    const [selectedMetric, setSelectedMetric] = useState('profit');

    const [selectedGroupMetrics, setSelectedGroupMetrics] = useState('profit');
    
    const [selectedColumnMetric, setSelectedColumnMetric] = useState({
        total_impressions: false,
        total_spend: false,
        unique_clicks: false,
        order_count: false,
        total_revenue: true,
        roas: false,
        epc: false,
        cpa: false,
        cpc: false,
        ctr: false,
        cpm: false,
        aov: false,
        cvr: false,
        profit: false,
    });

    data.sort((a, b) => {
        if (a.ad_name.includes('Unknown') && !b.ad_name.includes('Unknown')) {
            return 1; // a should come after b
        } else if (!a.ad_name.includes('Unknown') && b.ad_name.includes('Unknown')) {
            return -1; // a should come before b
        }
        return 0; // no sorting based on the 'Unknown' condition
    });

    useEffect(() => {
        const initialSelections = data.reduce((acc, ad, index) => {
            acc[ad.ad_id] = index < 1; // Initially, the first three ads are selected
            return acc;
        }, {});
        setSelectedAds(initialSelections);
    }, [data]);

    useEffect(() => {
        const initialGroupSelections = Object.keys(groupData).reduce((acc, groupName) => {
            const groupItems = groupData[groupName];
    
            // Sort the groupItems by customIndex in descending order before filtering
            const sortedItems = Object.entries(groupItems).sort((a, b) => {
                const customIndexA = a[1].customIndex || 0; // Fallback to 0 if undefined
                const customIndexB = b[1].customIndex || 0; // Fallback to 0 if undefined
                return customIndexB - customIndexA; // Sort by customIndex first
            });
    
            const selections = sortedItems.reduce((selectionAcc, [itemKey, itemValue], index) => {
                // Check if the item's name or identifier contains "unknown" or "Unknown"
                // and select the first two items based on the sorted order
                if (!itemValue.name.includes('unknown') && !itemValue.name.includes('Unknown')) {
                    selectionAcc[itemKey] = index < 2; // Select the first two items that don't contain "unknown"
                }
                return selectionAcc;
            }, {});
    
            acc[groupName] = selections;
            return acc;
        }, {});
    
        setGroupSelections(initialGroupSelections);
    }, [groupData]);


    
    const handleCheckboxChange = (adId) => {
        setSelectedAds(prev => ({
            ...prev,
            [adId]: !prev[adId],
        }));
    };

    const handleMetricCheckboxChange = (metricKey) => {
        setSelectedMetric((prev) => (
            prev === metricKey ? null : metricKey // Toggle off if the same metric is selected again, or change to the new metric
        ));
    };
    

    const handleGroupMetricCheckboxChange = (groupName, groupMetricKey) => {
        setSelectedGroupMetrics(prev => ({
            ...prev,
            [groupName]: {
                // Ensure only the clicked metric is selected by setting it to true
                // and effectively deselecting any other metrics.
                [groupMetricKey]: !(prev[groupName] && prev[groupName][groupMetricKey])
            }
        }));
    };
    

    useEffect(() => {
        const initialGroupMetrics = Object.keys(groupData).reduce((acc, groupName) => {
            acc[groupName] = groupmetrics.reduce((metricsAcc, metric) => {
                // Set ROAS to true by default, and false for all other metrics
                metricsAcc[metric.key] = metric.key === 'profit';
                return metricsAcc;
            }, {});
            return acc;
        }, {});
    
        setSelectedGroupMetrics(initialGroupMetrics);
    }, [groupData]);
    
    useEffect(() => {
        const handleScroll = () => {
            if (isSmoothScrolling) return; // Skip if we're smooth scrolling
            
            const sections = ['top-10-ads', 'products', 'editors', 'scripts', 'scroll_stoppers'];
            let currentSection = sections[0];
    
            for (const section of sections) {
                const el = document.getElementById(section);
                if (el && window.scrollY >= el.offsetTop - window.innerHeight / 3) {
                    currentSection = section;
                }
            }
    
            setActiveSection(currentSection);
        };
    
        const throttledScrollHandler = throttle(handleScroll, 100);
    
        window.addEventListener('scroll', throttledScrollHandler);
        return () => window.removeEventListener('scroll', throttledScrollHandler);
    }, [isSmoothScrolling]); // Depend on isSmoothScrolling to re-attach the event listener

    
    // Implement smooth scrolling for anchor links
    useEffect(() => {
        const smoothScroll = e => {
            e.preventDefault();
            const targetId = e.currentTarget.getAttribute("href") === "#" ? "header" : e.currentTarget.getAttribute("href");
            const targetPosition = document.querySelector(targetId).offsetTop;
            window.scrollTo({
                top: targetPosition - 10,
                behavior: "smooth"
            });
        };
    
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener("click", smoothScroll);
        });
    
        return () => {
            document.querySelectorAll('a[href^="#"]').forEach(anchor => {
                anchor.removeEventListener("click", smoothScroll);
            });
        };
    }, []);
    
    const handleSelectAllAdsToggle = () => {
        const allCurrentlySelected = Object.keys(selectedAds).length === data.length && Object.values(selectedAds).every(Boolean);
        const newSelections = {};
    
        data.forEach(ad => {
            newSelections[ad.ad_id] = !allCurrentlySelected;
        });
    
        setSelectedAds(newSelections);
    };
    
    const formatSectionName = (section) => {
        return section
            .replace(/-/g, ' ') // Replace all dashes with spaces
            .replace(/_/g, ' ') // Replace all underscores with spaces
            .split(' ') // Split the string into an array of words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' '); // Join the words back into a single string
    };
    const TableOfContents = ({ onClick, activeSection }) => (
        <nav className="fixed top-40 left-10 h-full w-64 bg-transparent p-5" style={{ zIndex: 1000 }}>
            <ul className="space-y-4">
                {['top-10-ads', 'products', 'editors', 'scripts', 'scroll_stoppers', 'product_offers'].map(section => (
                    <li key={section} className={`transition-transform duration-500 ease-in-out ${activeSection === section ? 'scale-110' : 'scale-100'}`}>
                        <a href={`#${section}`} onClick={onClick} className={`block hover:text-blue-700 text-lg font-medium transition-all duration-300 ease-in-out transform ${activeSection === section ? 'text-blue-700 font-bold scale-110' : 'text-blue-500 scale-100'}`}>
                            {formatSectionName(section)}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
    
    
    
    
const handleClick = (event) => {
    event.preventDefault();
    setIsSmoothScrolling(true); // Temporarily disable scroll event handling
    const targetId = event.target.getAttribute('href').slice(1);

    // Set the active section based on the clicked link
    setActiveSection(targetId);

    const targetElement = document.getElementById(targetId);
    if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });

        // Re-enable scroll event handling after a delay
        setTimeout(() => setIsSmoothScrolling(false), 1000); // Adjust delay as needed based on scroll duration
    }
};

      



    const metrics = [
        { key: 'total_impressions', label: 'Total Impressions' },
        { key: 'total_spend', label: 'Total Spend' },
        { key: 'profit', label: 'Profit'},
        { key: 'unique_clicks', label: 'Unique Clicks' },
        { key: 'order_count', label: 'Order Count' },
        { key: 'total_revenue', label: 'Total Revenue' },
        { key: 'roas', label: 'ROAS' },
        { key: 'epc', label: 'EPC' },
        { key: 'cpa', label: 'CPA' },
        { key: 'cpc', label: 'CPC' },
        { key: 'ctr', label: 'CTR' },
        { key: 'cpm', label: 'CPM' },
        { key: 'aov', label: 'AOV' },
        { key: 'cvr', label: 'CVR' },
        { key: 'videHoldRate', label: 'VHR'},
        { key: 'costPer3SecVideoViews', label: 'Cost 3 Sec'},
        { key: 'thumbStopRatio', label: 'TSR'},

    ];
    const monetaryMetrics = new Set(['total_spend', 'spend', 'profit', 'revenue', 'total_revenue', 'cpa', 'cpc', 'cpm', 'aov', 'costPer3SecVideoViews']);
    const percentageMetrics = new Set(['ctr', 'cvr', 'videHoldRate', 'thumbStopRatio']);

    
    const novideometrics = [
        { key: 'total_impressions', label: 'Total Impressions' },
        { key: 'total_spend', label: 'Total Spend' },
        { key: 'profit', label: 'Profit'},
        { key: 'unique_clicks', label: 'Unique Clicks' },
        { key: 'order_count', label: 'Order Count' },
        { key: 'total_revenue', label: 'Total Revenue' },
        { key: 'roas', label: 'ROAS' },
        { key: 'epc', label: 'EPC' },
        { key: 'cpa', label: 'CPA' },
        { key: 'cpc', label: 'CPC' },
        { key: 'ctr', label: 'CTR' },
        { key: 'cpm', label: 'CPM' },
        { key: 'aov', label: 'AOV' },
        { key: 'cvr', label: 'CVR' },

    ];

    const groupmetrics = [
        { key: 'aov', label: 'AOV' },
        { key: 'clicks', label: 'Clicks' },
        { key: 'cpa', label: 'CPA' },
        { key: 'cpc', label: 'CPC' },
        { key: 'cpm', label: 'CPM' },
        { key: 'ctr', label: 'CTR' },
        { key: 'cvr', label: 'CVR' },
        { key: 'epc', label: 'EPC' },
        { key: 'impressions', label: 'Impressions' },
        { key: 'orders', label: 'Orders' },
        { key: 'revenue', label: 'Revenue' },
        { key: 'roas', label: 'ROAS' },
        { key: 'spend', label: 'Spend' },
        { key: 'profit', label: 'Profit'},

    ]

    function formatMetric(value, metricKey) {
        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) return 'N/A';
    
        if (monetaryMetrics.has(metricKey)) {
            // Format as currency
            return `$${parsedValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        } else if (percentageMetrics.has(metricKey)) {
            // Format as percentage
            return `${(parsedValue).toFixed(2)}%`; // Assuming the value is already in decimal form (e.g., 0.03 for 3%)
        } else {
            // For large numbers like impressions, no decimal places
            return parsedValue.toLocaleString();
        }
    }
    
    


    const labels = [...new Set(data.flatMap(ad => Object.keys(ad.dailyStats)))].sort();
    const filteredData = data.filter(ad => selectedAds[ad.ad_id]);


    const roasDatasets = filteredData.map(ad => {
        const rank = data.findIndex(item => item.ad_id === ad.ad_id) + 1; // Assuming unique ad_id for each ad
        const shortName = ad.ad_name.split('_')[0]; // Adjust based on how you'd like to shorten the name
        const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        return {
            label: ad.ad_name,
            tooltipLabel: `Rank: ${rank} - ${shortName}`, // Custom property for tooltip
            data: labels.map(label => ad.dailyStats[label] ? ad.dailyStats[label].roas : 0),
            borderColor: randomColor,
            backgroundColor: randomColor,
            borderWidth: 4,
            tension: 0.1,
        };
    });
    
    const metricsDatasets = selectedMetric ? metrics.filter(metric => metric.key === selectedMetric).flatMap(metric => {
        return filteredData.map(ad => {
            const rank = data.findIndex(item => item.ad_id === ad.ad_id) + 1;
            const shortName = ad.ad_name.split('_')[0];
            const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
            return {
                label: `${ad.ad_name} - ${metric.label}`,
                tooltipLabel: `Rank: ${rank} - ${shortName}`, // Custom property for tooltip
                data: labels.map(label => ad.dailyStats[label] && ad.dailyStats[label][metric.key] !== undefined ? ad.dailyStats[label][metric.key] : 0),
                borderColor: randomColor,
                backgroundColor: randomColor,
                borderWidth: 4,
                fill: false,
                tension: 0.1,
            };
        });
    }) : [];
    
    
   

    const roasChartData = {
        labels,
        datasets: roasDatasets,
    };

    const metricsChartData = {
        labels,
        datasets: metricsDatasets,
    };

    
    function extractMetricKeyFromLabel(label) {
        // First, ensure the label is a string
        if (typeof label !== 'string') {
            return null; // or handle as appropriate for your application
        }
    
        const parts = label.split(' - ');
        if (parts.length > 1) {
            // Assuming the metric key is always after the first ' - '
            const metricPart = parts[1];
            return metricPart.toLowerCase().replace(/\s/g, ''); // Removing spaces to match keys exactly
        }
        return null; // Return null if the label format is unexpected
    }
    

    
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: { beginAtZero: true },
            x: { 
                title: { 
                    display: true, 
                    text: 'Date',
                    font: {
                        size: 16, // Adjust the X-axis title font size if needed
                    },
                },
                ticks: {
                    font: {
                        size: 14, // Increase the font size of the X-axis values (ticks) here
                    },
                },
            }
        },
        plugins: {
            datalabels: {
                align: 'end',
                anchor: 'end',
                display: true,
                formatter: function(value, context) {
                    // Extract metric key from the dataset label
                    const metricKey = extractMetricKeyFromLabel(context.dataset.label);
                    // Determine if the metric should be formatted as currency
                    const isMonetary = metricKey && monetaryMetrics.has(metricKey);
                    // Parse the value to a number
                    const parsedValue = parseFloat(value);
                    // Check if the value is a number
                    if (!isNaN(parsedValue)) {
                        if (isMonetary) {
                            // Format as currency
                            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parsedValue);
                        } else {
                            // Format large numbers with separators
                            return new Intl.NumberFormat('en-US').format(parsedValue);
                        }
                    }
                    return 'N/A';
                },
                color: '#444',
                font: {
                    size: 16,
                },
            },
            
            legend: {
                display: false,
            },
            tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';
                        const labelParts = label.split(' - ');
                        const metricKey = labelParts.length > 1 ? labelParts[1].toLowerCase() : null;
                        if (metricKey && monetaryMetrics.has(metricKey)) {
                            const value = typeof context.parsed.y === 'string' ? parseFloat(context.parsed.y) : context.parsed.y;
                            label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                        } else {
                            const value = context.parsed.y;
                            label += value.toFixed(1);
                        }
                        return label;
                    }
                },
                mode: 'index',
                intersect: false,
                titleFont: {
                    size: 16,
                },
                bodyFont: {
                    size: 14,
                },
            },
            
            title: {
                display: true,
                text: '',
                font: { size: 20 },
            },
        },
    };
    
    

    const ColumnOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: { beginAtZero: true },
            x: { 
                title: { 
                    display: true, 
                    text: 'Item',
                    padding: { // Add padding around the title
                        bottom: 20 // Optional: adjust bottom padding if needed
                    }
                } 
            }
        },
        plugins: {
            datalabels: {
                align: 'end',
                anchor: 'end',
                display: true,
                formatter: function(value, context) {
                    const labelParts = context.dataset.label.split(' - ');
                    const metricKey = labelParts.length > 1 ? labelParts[1].toLowerCase() : null;
                    if (metricKey && monetaryMetrics.has(metricKey)) {
                        const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
                        return !isNaN(parsedValue) ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parsedValue) : 'N/A';
                    } else {
                        const parsedValue = parseFloat(value);
                        return !isNaN(parsedValue) ? parsedValue.toFixed(1) : 'N/A';
                    }
                },
                color: '#444', // Optional: change the text color of data labels
            },
            legend: {
                display: false, // This hides the legend
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';
                        const labelParts = label.split(' - ');
                        const metricKey = labelParts.length > 1 ? labelParts[1].toLowerCase() : null;
                        if (metricKey && monetaryMetrics.has(metricKey)) {
                            const value = typeof context.parsed.y === 'string' ? parseFloat(context.parsed.y) : context.parsed.y;
                            label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                        } else {
                            const value = context.parsed.y;
                            label += value.toFixed(1);
                        }
                        return label;
                    }
                },
                mode: 'index',
                intersect: false,
            },
            title: { display: true, text: '', font: {size: 20} }, // Keep your dynamic title setup here
        },
        // Additional configuration for showing values above dots could go here if using datalabels plugin
    };

        // Then, in your `options.plugins.tooltip.callbacks.label` function, use `dataset.tooltipLabel` instead of `dataset.label`:
        options.plugins.tooltip.callbacks.label = function(context) {
            let label = context.dataset.tooltipLabel || ''; // Use tooltipLabel here
            if (label) {
                label += ': ';
            }
            if (context.parsed.y !== null) {
                label += context.parsed.y.toFixed(1); // Format as a number with one decimal place, without currency
            }
            return label;
        };
    

    const getColumnGraphData = (groupName) => {
        const groupItems = groupData[groupName];
        const labels = Object.keys(groupItems).map(key => groupItems[key].name);
    
        const datasets = metrics.map(metric => {
            return {
                label: metric.label,
                data: labels.map(label => {
                    const item = Object.values(groupItems).find(item => item.name === label);
                    return item && item[metric.key] !== undefined ? item[metric.key] : 0;
                }),
                backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                hidden: !selectedColumnMetric[metric.key], // Control visibility based on the selectedColumnMetric state
            };
        });
    
        return { labels, datasets };
    };
    
    const handleMetricSelectionChange = (selectedKey) => {
        // Update the selectedColumnMetric state to only true for the selected metric
        const updatedSelection = Object.keys(selectedColumnMetric).reduce((acc, key) => {
            acc[key] = key === selectedKey;
            return acc;
        }, {});
    
        setSelectedColumnMetric(updatedSelection);
    };
    

    const handleGroupSelectionChange = (groupName, editorName) => {
        setGroupSelections(prev => ({
            ...prev,
            [groupName]: {
                ...prev[groupName],
                [editorName]: !prev[groupName]?.[editorName],
            },
        }));
    };

    
      
    
    const renderGroupTable = (groupName, groupData) => {
        // Determine the sorting key based on the currentSortMethod prop
        const sortKey = currentSortMethod === 'top10ByProfit' ? 'profit' : 'customIndex';

        // Now sort based on the sortKey
        const editors = Object.keys(groupData).filter(editor => {
            const name = groupData[editor].name;
            return !name.includes('Unknown Product') && !name.includes('Unknown') && !name.includes('Unknown Editor') && !name.includes('No combination found');
        }).sort((a, b) => {
            if (sortKey === 'profit') {
                return (groupData[b].profit || 0) - (groupData[a].profit || 0);
            } else {
                const customIndexA = groupData[a].customIndex || 0;
                const customIndexB = groupData[b].customIndex || 0;
                return customIndexB - customIndexA;
            }
        });
        const sectionId = groupName.toLowerCase().replace(/ /g, '-'); // Example transformation
    return (
        <div id={sectionId} key={groupName} className="my-8 pt-4">
            <div className="flex items-center justify-center my-8">
                <div className="flex-grow h-0.5 bg-gray-400"></div>
                <h3 className="text-center text-xl font-bold uppercase mx-4">{groupName}</h3>
                <div className="flex-grow h-0.5 bg-gray-400"></div>
            </div>          
            <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 dark:text-white">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-white">
                        <tr>
                            <th scope="col" className="py-3 px-6">Select</th>
                            
                            <th scope="col" className="py-3 px-6">Item</th>
                            {novideometrics.map(metric => (
                                <th key={metric.key} scope="col" className="py-3 px-6">{metric.label}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {editors.map(editor => (
                            <tr key={editor} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <td className="py-4 px-6">
                                    <input
                                        type="checkbox"
                                        checked={groupSelections[groupName]?.[editor] || false}
                                        onChange={() => handleGroupSelectionChange(groupName, editor)}
                                    />
                                </td>
                                <td className="py-4 px-6"><strong>{groupData[editor].name}</strong></td>
                                {novideometrics.map(metric => (
    <td key={metric.key} className="py-4 px-6">
        {typeof groupData[editor][metric.key] === 'number'
            ? formatMetric(groupData[editor][metric.key], metric.key)
            : 'N/A'}
    </td>
))}

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const transformGroupDataToChartData = (groupName) => {
    // Filter groupItems based on selections stored in groupSelections
    const filteredGroupItems = Object.keys(groupData[groupName]).reduce((acc, editor) => {
        if (groupSelections[groupName]?.[editor]) {
            acc[editor] = groupData[groupName][editor];
        }
        return acc;
    }, {});

    const labels = [...new Set(Object.values(filteredGroupItems).flatMap(item => Object.keys(item.dailyStats)))].sort();
    const selectedMetricsForGroup = selectedGroupMetrics[groupName] || {};
    
    // Generate datasets
    const datasets = groupmetrics.filter(metric => selectedMetricsForGroup[metric.key]).flatMap(metric => {
        return Object.values(filteredGroupItems).map(item => {
            const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`; // Generate a random color for each dataset
            return {
                label: `${item.name} - ${metric.label}`,
                data: labels.map(label => item.dailyStats[label] && item.dailyStats[label][metric.key] !== undefined ? item.dailyStats[label][metric.key] : 0),
                borderColor: randomColor,
                pointBackgroundColor: randomColor, // Ensure that the point's background color matches the line
                backgroundColor: 'rgba(0, 0, 0, 0)', // Make the background color fully transparent
                borderWidth: 4,
                fill: false,
                tension: 0.1
            };
        });
    });

    return { labels, datasets };
};


const prepareROASData = (groupData) => {
    const labels = [...new Set(Object.values(groupData).flatMap(item => Object.keys(item.dailyStats)))].sort();
    const datasets = Object.values(groupData).map(item => {
        const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`; // Generate a random color for each dataset
        
        // Ensure that the randomColor has 6 digits to avoid invalid color codes
        const borderColor = randomColor.length === 7 ? randomColor : `${randomColor}0`.substring(0, 7);
        
        return {
            label: item.name,
            data: labels.map(label => item.dailyStats[label] ? item.dailyStats[label].roas : 0),
            borderColor: borderColor, // Set border color
            backgroundColor: borderColor, // Set background color same as border color for tooltip consistency
            borderWidth: 4, // Set border width
            tension: 0.1,
            fill: false,
        };
    });

    return { labels, datasets };
};

    return (
    <div>
        <TableOfContents onClick={handleClick} activeSection={activeSection} />
        <div id="top-10-ads" className="w-2/3 mx-auto">  
            <div className="text-center text-xl uppercase font-bold my-4">
                <h3>Top 10 Ads</h3>         {/* Section 1 */}

            </div>   
            <div className="overflow-x-auto relative shadow-md sm:rounded-lg mb-5">
                <table className="w-full text-sm text-left text-white dark:text-white">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="py-3 px-6">
                                <div className="flex justify-center">
                                    <input
                                        type="checkbox"
                                        className="cursor-pointer"
                                        checked={Object.keys(selectedAds).length === data.length && Object.values(selectedAds).every(Boolean)}
                                        onChange={handleSelectAllAdsToggle}
                                    />
                                </div>
                            </th>
                            <th scope="col" className="py-3 px-6">Rank</th>
                            <th scope="col" className="py-3 px-6">Ad Name</th>
                            {metrics.map(metric => (
                                <th key={metric.key} scope="col" className="py-3 px-6">{metric.label}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.slice(0, 10).map((ad, index) => (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={ad.ad_id}>
                                <td className="py-4 px-6">
                                    <input
                                        type="checkbox"
                                        checked={selectedAds[ad.ad_id] || false}
                                        onChange={() => handleCheckboxChange(ad.ad_id)}
                                    />
                                </td>
                                <td className="py-4 px-6">{index + 1}</td>
                                <td className="py-4 px-6">
                                <div className="max-w-xs overflow-x-auto whitespace-nowrap hide-scrollbar">
                                            {ad.ad_name}
                                        </div>
                                    </td>                                
                                    {metrics.map(metric => (
    <td key={metric.key} className="py-4 px-6">
        {typeof ad[metric.key] === 'number'
            ? formatMetric(ad[metric.key], metric.key)
            : 'N/A'}
    </td>
))}

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="chart-container" style={{ height: '500px' }}>
            <Line data={roasChartData} options={{ ...options, plugins: { ...options.plugins, title: { ...options.plugins.title, text: 'ROAS Over Time' }}}}/>
        </div>

        {/* Metrics Selection and Chart - Side by Side for the second graph */}
        <div className="flex flex-col md:flex-row mt-8">
            <div className="w-full md:w-1/4 p-4 bg-white shadow-md rounded-lg border border-gray-200">
                <div className="mb-4"><strong>Select Metrics:</strong></div>
                {metrics.map(metric => (
    <div key={metric.key} className="mb-2">
        <label className="inline-flex items-center">
            <input
                type="checkbox"
                className="form-checkbox text-indigo-600"
                checked={selectedMetric === metric.key}
                onChange={() => handleMetricCheckboxChange(metric.key)}
            />
            <span className="ml-2">{metric.label}</span>
        </label>
    </div>
))}

            </div>
            <div className="w-full md:w-3/4 p-4">
                <div className="chart-container shadow-md rounded-lg border border-gray-200 p-4" style={{ height: '500px' }}>
                    <Line data={metricsChartData} options={{ ...options, plugins: { ...options.plugins, title: { ...options.plugins.title, text: 'Selected Metrics Over Time' }}}} />
                </div>
            </div>
        </div>
        {Object.entries(groupData).map(([groupName, groupItems]) => {
            // Prepare data for the ROAS and Metrics graphs
            const filteredGroupItems = Object.keys(groupItems).reduce((acc, editor) => {
                if (groupSelections[groupName]?.[editor]) {
                    acc[editor] = groupItems[editor];
                }
                return acc;
            }, {});
        
            const { labels, datasets } = transformGroupDataToChartData(groupName, filteredGroupItems);
            const GrouproasChartData = prepareROASData(Object.keys(groupItems).reduce((acc, editor) => {
                if (groupSelections[groupName]?.[editor]) {
                    acc[editor] = groupItems[editor];
                }
                return acc;
            }, {}));
            
            return (
            <React.Fragment key={groupName}>
                {/* Table for the current group */}
                {renderGroupTable(groupName, groupItems)}
                {/* ROAS Graph for the current group */}
                <div className="chart-container mt-8" style={{ height: '500px' }}>
                    <Line data={GrouproasChartData} options={{ ...options, plugins: { ...options.plugins, title: { ...options.plugins.title, text: `${groupName} - ROAS Over Time` }}}}/>
                </div>

                {/* Metrics Selection, Metrics Graph, and Revenue Column Graph for the current group */}
                <div className="flex flex-col md:flex-row mt-8">
                    {/* Selection Area for Metrics */}
                    <div className="w-full md:w-1/4 p-4 bg-white shadow-md rounded-lg border border-gray-200">
                        <div className="mb-4"><strong>Select Metrics:</strong></div>
                        {groupmetrics.map(groupMetric => (
                            <div key={groupMetric.key} className="mb-2">
                                <label className="inline-flex items-center">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox text-indigo-600"
                                        checked={selectedGroupMetrics[groupName]?.[groupMetric.key] || false}
                                        onChange={() => handleGroupMetricCheckboxChange(groupName, groupMetric.key)}
                                    />
                                    <span className="ml-2">{groupMetric.label}</span>
                                </label>
                            </div>
                        ))}
                    </div>
                    {/* Line Graph for Selected Metrics */}
                    <div className="w-full md:w-3/8 p-4">
                        <div className="chart-container shadow-md rounded-lg border border-gray-200 p-4" style={{ height: '500px' }}>
                            <Line data={{ labels, datasets }} options={{ ...options, plugins: { ...options.plugins, title: { ...options.plugins.title, text: `${groupName} - Selected Metrics Over Time` }}}} />
                        </div>
                    </div>
                    {/* Column Graph for Revenue */}
                    <div className="w-full md:w-3/8 p-4">
                        <div className="chart-container shadow-md rounded-lg border border-gray-200 p-4" style={{ height: '500px' }}>
                        <select onChange={(e) => handleMetricSelectionChange(e.target.value)} value={Object.keys(selectedColumnMetric).find(key => selectedColumnMetric[key])}>
                            {metrics.map(metric => (
                                <option key={metric.key} value={metric.key}>{metric.label}</option>
                            ))}
                        </select>
                            <Bar data={getColumnGraphData(groupName, selectedColumnMetric)} options={ColumnOptions} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
            );
        })}
    </div>
    </div>
);
};

export default Statistics;

