import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import './Stats.css'
import Statistics from './Statistics';
import Header from './Header';
import DateSelectorTop10 from './DatesTop10';
import Spinner from './spinner';
import io from 'socket.io-client';
import localForage from 'localforage'; // Import localForage

const socket = io('https://roasbooster.com');

const Stats = () => {
    const [startDate, setStartDate] = useState(moment().subtract(7, 'days').toDate());
    const [endDate, setEndDate] = useState(new Date());
    const [allData, setAllData] = useState({});
    const [groupData, setGroupData] = useState({}); 
    const [selectedDataset, setSelectedDataset] = useState('top10ByCustomIndex'); // State for selecting the dataset
    const [adsData, setAdsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [userRole, setUserRole] = useState('');

    const toggleDataset = () => {
        setSelectedDataset(prevDataset =>
            prevDataset === 'top10ByCustomIndex' ? 'top10ByProfit' : 'top10ByCustomIndex'
        );
    };


    useEffect(() => {
        // Retrieve allData from IndexedDB when component mounts
        localForage.getItem('allPeriodsData').then(data => {
            if (data) {
                setAllData(data);
                updateAdsData(startDate, endDate, data);
            }
        }).catch(err => console.error('Failed to load data from IndexedDB:', err));

        const role = localStorage.getItem('userRole'); // Assuming userRole still fits in localStorage
        setUserRole(role);
    }, []);
    

    useEffect(() => {
        socket.on('allPeriodsData', (data) => {
            console.log('Received data from server:', data);
            localForage.setItem('allPeriodsData', data) // Save to IndexedDB
                .then(() => {
                    setAllData(data);
                    updateAdsData(startDate, endDate, data);
                })
                .catch(err => console.error('Failed to save data to IndexedDB:', err));
        });

        return () => socket.off('allPeriodsData');
    }, [startDate, endDate]);
    
    useEffect(() => {
        setIsLoading(true);
        updateAdsData(startDate, endDate, allData);
    }, [startDate, endDate, allData, selectedDataset]); // Include selectedDataset here
    

    useEffect(() => {
        const role = localStorage.getItem('userRole');
        setUserRole(role);
    }, []);

    const updateAdsData = (start, end, data) => {
        const days = moment(end).diff(moment(start), 'days') + 1;
        const periodKey = findKeyForDays(days); // e.g., '7days', '14days', etc.
        const relevantData = data[periodKey];
    
        if (relevantData) {
            // Access the dataset based on the selectedDataset state
            const dataset = relevantData[selectedDataset] || [];
            setAdsData(dataset);
        } else {
            setAdsData([]); // Set to an empty array if there's no relevant data
        }
        setIsLoading(false);
    };

    useEffect(() => {
        // This effect listens for changes in allData, startDate, or endDate and updates groupData accordingly
        updateGroupData(allData);
    }, [allData, startDate, endDate]);

    const updateGroupData = (data) => {
        const days = moment(endDate).diff(moment(startDate), 'days') + 1;
        const key = findKeyForDays(days) + 'Decoded'; // Use the modified key to access decoded data
        const relevantGroupData = data[key];
        if (relevantGroupData) {
            setGroupData(relevantGroupData);
        }
    };

    const findKeyForDays = (days) => {
        if (days <= 7) return '7days';
        if (days <= 14) return '14days';
        if (days <= 30) return '30days';
        return '90days';
    };



    return (
        <div>
            <Header userRole={userRole} />
            <div className="container mx-auto px-6 py-4">
                <div className="sticky top-0 pt-2 pb-2 bg-white z-20">
                    <div className="flex flex-wrap justify-between md:justify-end max-w-6xl gap-3 mb-4">
                        <DateSelectorTop10 setStartDate={setStartDate} setEndDate={setEndDate} />
                        <div className="flex items-center gap-2">
                    <span className="text-sm md:text-base font-medium text-gray-600">Custom Index</span>
                    <div className="toggle-switch">
                        <input
                            type="checkbox"
                            id="toggleDataset"
                            className="toggle-checkbox"
                            checked={selectedDataset === 'top10ByProfit'}
                            onChange={toggleDataset}
                        />
                        <label htmlFor="toggleDataset" className="toggle-label"></label>
                        </div>
                        <span className="text-sm md:text-base font-medium text-gray-600">Profit</span>
                    </div>
                    </div>
                </div>
            </div>
    
            <>
            {isLoading ? <Spinner /> :     <Statistics data={adsData} groupData={groupData} currentSortMethod={selectedDataset} />}
            </>
    
            <footer className="bg-white py-4">
                <div className="max-w-6xl mx-auto px-6 flex justify-center">
                    <p className="text-gray-600">Made by Felix for rybízci at 10FC</p>
                </div>
            </footer>
        </div>
    );
    
};

export default Stats;
