import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import Header from './Header';
import Spinner from './spinner'; // Import the Spinner component
import io from 'socket.io-client';
import Leaderboard from './leaderboard';
import LZString from 'lz-string';
import { BackgroundGradientAnimation } from './gradient';
import DateSelectorEditorTop10 from './DatesEditorTop10';
import Example from "./BubbleText";
import { useEditors } from './EditorsDataContext'; // Import the context hook



const VideoEditors = () => {
    const {
        startDate, setStartDate,
        endDate, setEndDate,
        editorsGroupData, editorProfiles,
        isLoading, userRole, updateGroupDataByDate, // Destructure the new method from context
    } = useEditors();
  
    // Call the context method on date change
    useEffect(() => {
      updateGroupDataByDate(startDate, endDate);
    }, [startDate, endDate, updateGroupDataByDate]);

    return (
        <>
      {/* <BackgroundGradientAnimation className="fixed inset-0" interactive={false}> */}

        <div>
            <Header userRole={userRole} />
            <div className="container mx-auto px-6 py-4">
            <Example/>
                <div className="sticky top-0 pt-2 pb-2 bg-transparent z-20">
                    <div className="flex flex-wrap justify-between md:justify-end max-w-6xl gap-3 mt-4 mb-4">
                        <DateSelectorEditorTop10 setStartDate={setStartDate} setEndDate={setEndDate} />
                    </div>
                </div>

            </div>
   
            <>
            {isLoading || !editorsGroupData || !editorProfiles.length ? (
                <Spinner />
                ) : (
                <Leaderboard data={editorsGroupData} editorProfiles={editorProfiles} />
                )}
            </>

            <footer className="bg-transparent py-4">
                <div className="max-w-6xl mx-auto px-6 flex justify-center">
                    <p className="text-gray-600">Made by Felix for rybízci at 10FC</p>
                </div>
            </footer>
        </div>
        {/* </BackgroundGradientAnimation> */}
        </>
    );
};

export default VideoEditors;
