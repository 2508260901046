import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddProfileModal from './AddProfileModal';
import EditProfileModal from './EditProfileModal';
import Spinner from './spinner';
import { FaEdit, FaTrashAlt } from 'react-icons/fa'; // Import icons
import Header from './Header';

function EditorsManage() {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [userRole, setUserRole] = useState('');

    const openAddModal = () => setShowAddModal(true);
    const closeAddModal = () => setShowAddModal(false);

    const openEditModal = (profile) => {
    setSelectedProfile(profile);
    setShowEditModal(true);
    };

    const closeEditModal = () => {
    setSelectedProfile(null);
    setShowEditModal(false);
    };

  // Fetch profiles from the backend
  useEffect(() => {
    setLoading(true);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    axios.get(`${API_BASE_URL}/editor-profiles`)
      .then(response => {
        setProfiles(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const role = localStorage.getItem('userRole');
    setUserRole(role);
}, []);


  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`/api/editor-profiles/${id}`);
      setProfiles(profiles.filter(profile => profile.id !== id));
      setLoading(false);
    } catch (error) {
      console.error('Failed to delete the profile', error);
      setLoading(false);
    }
  };
  if (loading) {
    return <Spinner />; // Use the Loader component when loading
  }

  return (
    <div>
    <Header userRole={userRole} />
    <div className="container mx-auto px-4 py-2">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold">Editor Profiles</h1>
        <button onClick={openAddModal} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
          Add New Profile
        </button>
      </div>
      {showAddModal && <AddProfileModal setProfiles={setProfiles} onClose={closeAddModal} />}
      {showEditModal && selectedProfile && <EditProfileModal profile={selectedProfile} setProfiles={setProfiles} onClose={closeEditModal} />}
      <table className="table-auto w-full text-left whitespace-no-wrap">
        <thead>
          <tr className="bg-gray-100 border-b">
            <th className="px-4 py-3">Name</th>
            <th className="px-4 py-3">Bio</th>
            <th className="px-4 py-3">Image</th>
            <th className="px-4 py-3">Facebook Ads</th>
            <th className="px-4 py-3">Actions</th>
          </tr>
        </thead>
        <tbody>
          {profiles.map((profile) => (
            <tr key={profile.id} className="bg-white border-b">
              <td className="px-4 py-3">{profile.name}</td>
              <td className="px-4 py-3">{profile.bio}</td>
              <td className="px-4 py-3">
                <img src={profile.image_url} alt="Profile" style={{ width: 40, height: 40, borderRadius: '50%' }} />
              </td>
              <td className="px-4 py-3">{profile.facebook_ads_short}</td>
              <td className="px-4 py-3 flex items-center">
                <FaEdit onClick={() => openEditModal(profile)} className="text-blue-500 hover:text-blue-700 cursor-pointer mx-2" />
                <FaTrashAlt onClick={() => handleDelete(profile.id)} className="text-red-500 hover:text-red-700 cursor-pointer mx-2" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
}


export default EditorsManage;
