// components/ui/BackgroundGradientAnimation.js
import { useEffect, useRef, useState } from "react";
import { cn } from "./cn";
export const BackgroundGradientAnimation = ({
  gradientBackgroundStart = "rgb(108, 0, 162)",
  gradientBackgroundEnd = "rgb(0, 17, 82)",
  firstColor = "18, 113, 255",
  secondColor = "221, 74, 255",
  thirdColor = "100, 220, 255",
  fourthColor = "200, 113, 100",
  fifthColor = "180, 180, 255",
  pointerColor = "140, 100, 255",
  size = "100%",
  blendingValue = "hard-light",
  children,
  className,
  interactive = true,
  containerClassName,
}) => {
    const interactiveRef = useRef(null);
    const animationFrameId = useRef(null);
  
    const curX = useRef(0);
    const curY = useRef(0);
    const [tgX, setTgX] = useState(0);
    const [tgY, setTgY] = useState(0);
  
  
    useEffect(() => {
        // Setting CSS custom properties
        const setCustomProperties = () => {
          document.body.style.setProperty("--gradient-background-start", gradientBackgroundStart);
          document.body.style.setProperty("--gradient-background-end", gradientBackgroundEnd);
          document.body.style.setProperty("--first-color", firstColor);
          document.body.style.setProperty("--second-color", secondColor);
          document.body.style.setProperty("--third-color", thirdColor);
          document.body.style.setProperty("--fourth-color", fourthColor);
          document.body.style.setProperty("--fifth-color", fifthColor);
          document.body.style.setProperty("--pointer-color", pointerColor);
          document.body.style.setProperty("--size", size);
          document.body.style.setProperty("--blending-value", blendingValue);
        };
        setCustomProperties();
      }, [gradientBackgroundStart, gradientBackgroundEnd, firstColor, secondColor, thirdColor, fourthColor, fifthColor, pointerColor, size, blendingValue]);
    
      useEffect(() => {
        const move = () => {
          // Update positions
          curX.current += (tgX - curX.current) / 20;
          curY.current += (tgY - curY.current) / 20;
    
          if (interactiveRef.current) {
            interactiveRef.current.style.transform = `translate(${Math.round(curX.current)}px, ${Math.round(curY.current)}px)`;
          }
    
          animationFrameId.current = requestAnimationFrame(move);
        };
    
        if (interactive) {
          animationFrameId.current = requestAnimationFrame(move);
        }
    
        // Cleanup function to cancel the animation frame when the component unmounts
        return () => {
          if (animationFrameId.current) {
            cancelAnimationFrame(animationFrameId.current);
          }
        };
      }, [tgX, tgY, interactive]);

  const handleMouseMove = (event) => {
    if (interactiveRef.current) {
      const rect = interactiveRef.current.getBoundingClientRect();
      setTgX(event.clientX - rect.left);
      setTgY(event.clientY - rect.top);
    }
  };

  const [isSafari, setIsSafari] = useState(false);
  useEffect(() => {
    setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
  }, []);
  return (
    <div
      className={cn(
         "h-screen w-screen fixed overflow-hidden top-0 left-0 bg-[linear-gradient(40deg,var(--gradient-background-start),var(--gradient-background-end))]",
        containerClassName
      )}
    >
      <svg className="hidden">
        <defs>
          <filter id="blurMe">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8"
              result="goo"
            />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
        </defs>
      </svg>
      <div className={cn("", className)}>{children}</div>
      <div
        className={cn(
          "gradients-container pointer-events-none h-full w-full blur-lg",
          isSafari ? "blur-2xl" : "[filter:url(#blurMe)_blur(40px)]"
        )}
      >
        <div
          className={cn(
            `absolute z-[-1] [background:radial-gradient(circle_at_center,_var(--first-color)_0,_var(--first-color)_50%)_no-repeat]`,
            `[mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size))] left-[calc(50%-var(--size))]`,
            `[transform-origin:center_center]`,
            `animate-first`,
            `opacity-60`
          )}
        ></div>
        <div
          className={cn(
            `absolute z-[-1] [background:radial-gradient(circle_at_center,_rgba(var(--second-color),_0.8)_0,_rgba(var(--second-color),_0)_50%)_no-repeat]`,
            `[mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size))] left-[calc(50%-var(--size))]`,
            `[transform-origin:calc(50%-200px)]`,
            `animate-second`,
            `opacity-60`
          )}
        ></div>
        <div
          className={cn(
            `absolute z-[-1] [background:radial-gradient(circle_at_center,_rgba(var(--third-color),_0.8)_0,_rgba(var(--third-color),_0)_50%)_no-repeat]`,
            `[mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size))] right-[calc(50%-var(--size))]`,
            `[transform-origin:calc(50%+200px)]`,
            `animate-third`,
            `opacity-60`
          )}
        ></div>
        <div
          className={cn(
            `absolute z-[-1] [background:radial-gradient(circle_at_center,_rgba(var(--fourth-color),_0.8)_0,_rgba(var(--fourth-color),_0)_50%)_no-repeat]`,
            `[mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size))] left-[calc(50%-var(--size))]`,
            `[transform-origin:calc(50%-100px)]`,
            `animate-fourth`,
            `opacity-40`
          )}
        ></div>
        <div
          className={cn(
            `absolute z-[-1] [background:radial-gradient(circle_at_center,_rgba(var(--fifth-color),_0.8)_0,_rgba(var(--fifth-color),_0)_50%)_no-repeat]`,
            `[mix-blend-mode:var(--blending-value)] w-[200%] h-[200%] top-[-50%] left-[-50%]`, // Increase size for broader coverage
            `[transform-origin:calc(50%-200px)_calc(50%+200px)]`,
            `animate-fifth`,
            `opacity-60`
          )}
        ></div>
 
        {interactive && (
          <div
            ref={interactiveRef}
            onMouseMove={handleMouseMove}
            className={cn(
              `absolute [background:radial-gradient(circle_at_center,_rgba(var(--pointer-color),_0.8)_0,_rgba(var(--pointer-color),_0)_50%)_no-repeat]`,
              `[mix-blend-mode:var(--blending-value)] w-[200%] h-[200%] top-[-50%] left-[-50%]`, // Increase size for broader coverage
              `opacity-10`
            )}
          ></div>
        )}
      </div>
    </div>
  );
};
