import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dashboard from './Dashboard';
import Fb from './fb';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Header from './Header';
import DateSelector from './DatePicker';
import { set } from 'date-fns';
import Spinner from './spinner'; // Import the Spinner component
import io from 'socket.io-client';
import Checkbox from './Checkbox'; // Import the Checkbox component
import _ from 'lodash'; // Import Lodash for deep comparison

const Together = () => {
    const timezone = 'America/Los_Angeles';
    const todayLA = moment().tz(timezone).startOf('day').toDate();
    const [isClickEnabled, setIsClickEnabled] = useState(true); // State for the checkbox
    const [startDate, setStartDate] = useState(todayLA);
    const [endDate, setEndDate] = useState(todayLA);
    const [userRole, setUserRole] = useState('');
    const [fetchVersion, setFetchVersion] = useState(0); // or use a timestamp if more appropriate
    const [prevFbData, setPrevFbData] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [effectiveStatus, setEffectiveStatus] = useState({
        ads: [],
        campaigns: [],
        adsets: []
    });
    const [fetchDataCallCount, setFetchDataCallCount] = useState(0);
    const [hasFetchedTodayData, setHasFetchedTodayData] = useState(false);
    const [offers, setOffers] = useState({}); // State to store fetched metrics

useEffect(() => {
    // Reset flag when startDate or endDate changes
    setHasFetchedTodayData(false);
}, [startDate, endDate]);

        
    const isDateToday = (date) => {
        const today = moment().tz(timezone).startOf('day');
        return date.isSame(today, 'day');
    };


    const handleResetSelection = () => {
      setSelectedItems([]); // Reset the selectedItems to an empty array
    };
    // States for Dashboard data
    const [dashboardData, setDashboardData] = useState({
        todaysSales: null,
        ordersCount: null,
        averageOrderValue: null,
        largestOrder: null,
        aggregatedData: {},
        isUpdating: false
    });
    const [fbData, setFbData] = useState({
        adsData: [],
        campaignsData: [],
        adsetsData: [],
        adaccountsData: [],
        isLoading: true,
        isInitialLoad: true
    });

    const [vendorData, setVendorData] = useState({
        profit: null,
        totalRevenue: null,
        totalSpend: null,
        roas: null,
        CVR: null
    });

    const updateStateIfDifferent = (setState, newData) => {
        setState(currentData => !_.isEqual(currentData, newData) ? newData : currentData);
    };

    useEffect(() => {
        // This function is defined to fetch the initial or updated vendor summary for today.
        const fetchInitialVendorData = async () => {
            const formattedToday = new Date().toLocaleDateString();
    
            try {
                const response = await axios.get(`/api/vendor-summary?startDate=${formattedToday}&endDate=${formattedToday}`);
                setVendorData({
                    profit: response.data.profit,
                    totalRevenue: response.data.totalRevenue,
                    totalSpend: response.data.totalSpend,
                    roas: response.data.roas,
                    CVR: response.data.CVR
                });
            } catch (error) {
                console.error('Error fetching initial vendor data:', error);
            }
        };
    
        // Invoke the fetch function immediately if today is the selected date range on component mount.
        // This check is to ensure it aligns with your conditional rendering logic elsewhere.
        if (isDateToday(moment(startDate)) && isDateToday(moment(endDate))) {
            fetchInitialVendorData();
        }
    
        // Optionally, you could add other dependencies in this array if you want this effect
        // to re-run when those dependencies change (e.g., userRole, etc.).
    }, [startDate, endDate]); 

    useEffect(() => {
        const role = localStorage.getItem('userRole');
        setUserRole(role);
    }, []);

    useEffect(() => {
        // Connect to WebSocket server
        const socket = io('https://roasbooster.com');
    
        const updateStateIfDifferent = (setState, newData) => {
            setState(currentData => !_.isEqual(currentData, newData) ? newData : currentData);
        };
    
        socket.on('data-update', (data) => {
            // Check if selected date is today before updating state
            if (isDateToday(moment(startDate)) && isDateToday(moment(endDate))) {
                updateStateIfDifferent(setDashboardData, {
                    todaysSales: data.dashboardData.revenue,
                    ordersCount: data.dashboardData.count,
                    averageOrderValue: data.dashboardData.revenue / data.dashboardData.count,
                    largestOrder: data.dashboardData.largestOrder,
                    aggregatedData: data.dashboardData.aggregatedData,
                    isUpdating: false
                });
    
                updateStateIfDifferent(setFbData, {
                    adsData: data.fbData.ads,
                    campaignsData: data.fbData.campaigns,
                    adsetsData: data.fbData.adsets,
                    adaccountsData: data.fbData.adaccounts,
                    isLoading: false,
                    totalProfit: data.fbData.totalProfit,
                });
            }
        });
    
        socket.on('effective-status-update', (statusData) => {
            console.log('Effective Status updated:', statusData);
            // Directly updating effectiveStatus without deep comparison since it's likely always new data
            setEffectiveStatus(statusData);
        });
    
        socket.on('data-error', (error) => {
            console.error('Error receiving data:', error.message);
        });
    
        // Clean up on unmount or when dependencies change
        return () => {
            socket.off('data-update');
            socket.off('effective-status-update');
            socket.off('data-error');
            socket.close();
        };
    }, [startDate, endDate, setDashboardData, setFbData]);

    const fetchOffersData = async () => {
        const formattedDate = moment().tz(timezone).format('YYYY-MM-DD');
        // Always hitting the /api/offers-main endpoint
        try {
            const response = await axios.get('/api/offers-main', {
                params: {
                    startDate: formattedDate,
                    endDate: formattedDate
                }
            });
            setOffers(response.data);
        } catch (error) {
            console.error('Error fetching todays offers metrics:', error);
        }
    };

    // Effect that runs once on component mount to fetch today's data.
    useEffect(() => {
        fetchOffersData();
    }, []);


    const fetchData = async () => {
        setFetchDataCallCount(count => count + 1); // Increment the call counter
        console.log(`fetchData has been called ${fetchDataCallCount + 1} times`);
    
        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];

        const formattedStartDateFb = new Date(startDate).toLocaleDateString();
        const formattedEndDateFb = new Date(endDate).toLocaleDateString();
        const endpoint = isDateToday(moment(startDate)) && isDateToday(moment(endDate)) ? '/api/offers-main' : '/api/offers-main-historic';
      
        try {
            const offersResponse = await axios.get(endpoint, { params: { startDate: formattedStartDate, endDate: formattedEndDate } });
            setOffers(offersResponse.data); // Assuming the response data is directly usable
        } catch (error) {
            console.error('Error fetching offers metrics:', error);
        }

        if (isDateToday(moment(startDate)) && isDateToday(moment(endDate))) {
            // Start updating state to show loading if needed
            // Example: setDashboardData(prevState => ({ ...prevState, isUpdating: true }));
    
            try {

                const responseVendor = await axios.get(`/api/vendor-summary?startDate=${formattedStartDateFb}&endDate=${formattedEndDateFb}`);
    
                // Assuming the API call is successful and data is returned
                setVendorData({
                    profit: responseVendor.data.profit,
                    totalRevenue: responseVendor.data.totalRevenue,
                    totalSpend: responseVendor.data.totalSpend,
                    roas: responseVendor.data.roas,
                    CVR: responseVendor.data.CVR
                });
    
                // Reset the isUpdating flag or similar if you have it here
                // Example: setDashboardData(prevState => ({ ...prevState, isUpdating: false }));
    
            } catch (error) {
                console.error('Error fetching Dashboard data:', error);
                setDashboardData(prevState => ({ ...prevState, isUpdating: false }));
            }
    } else {
        // Always reset the flag when fetching data for dates that are not today
        setHasFetchedTodayData(false);
            // Fetching logic for when the selected dates are NOT today
            try {
                // Start updating state to show loading
                setDashboardData(prevState => ({ ...prevState, isUpdating: true }));
                setFbData(prevState => ({ ...prevState, isLoading: true }));
            
                // Fetch summary data for the specified date range
                const responseSummary = await axios.get(`/api/summary?startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
                const { adsSummary, adsetsSummary, effectiveStatus, campaignsSummary, accountsSummary, dailyAdsData, dailyAdsetsData, dailyCampaignsData, dailyAccountsData } = responseSummary.data;

                const responseDashboard = await fetch(`/api/orders-by-date?start=${formattedStartDate}&end=${formattedEndDate}`);
                const dataDashboard = await responseDashboard.json();
                setDashboardData({
                    todaysSales: dataDashboard.revenue,
                    ordersCount: dataDashboard.count,
                    averageOrderValue: dataDashboard.revenue / dataDashboard.count,
                    largestOrder: dataDashboard.largestOrder,
                    aggregatedData: dataDashboard.aggregatedData,
                    isUpdating: false
                });

                const responseVendor = await axios.get(`/api/vendor-summary-historical?startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
                setVendorData({
                    profit: responseVendor.data.profit,
                    totalRevenue: responseVendor.data.totalRevenue,
                    totalSpend: responseVendor.data.totalSpend,
                    roas: responseVendor.data.roas,
                    CVR: responseVendor.data.CVR
                });
    
                setFbData({
                    adsData: adsSummary,
                    campaignsData: campaignsSummary,
                    adsetsData: adsetsSummary,
                    adaccountsData: accountsSummary,
                    isLoading: false,

                    dailyAdsData,
                    dailyAdsetsData,
                    dailyCampaignsData,
                    dailyAccountsData
                });
            } catch (error) {
                console.error('Error fetching data:', error);
                // Update state to reflect the error/fetching completion
                setDashboardData(prevState => ({ ...prevState, isUpdating: false }));
                setFbData(prevState => ({ ...prevState, isLoading: false }));
            }
        }
    };




    return (
        <div>
        <Header userRole={userRole} />
        <div className="container mx-auto px-6 py-4">
        <div className="sticky top-0 pt-2 pb-2 bg-white z-20">
            <div className="flex flex-wrap justify-between md:justify-end max-w-6xl gap-3 mb-4">
                <DateSelector setStartDate={setStartDate}     setEndDate={setEndDate} />
             <div className="flex gap-2">
                <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    className="p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-300 focus:border-blue-300 w-full md:w-auto"
                    dateFormat="yyyy-MM-dd"
                />
                <DatePicker
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    className="p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-300 focus:border-blue-300 w-full md:w-auto"
                    dateFormat="yyyy-MM-dd"
                />
                </div>
                <button
                    onClick={fetchData}
                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow transition duration-300 ease-in-out"
                >
                    Refresh Data
                </button>
            </div>

            </div>
                {dashboardData.isUpdating || fbData.isLoading ? (
                    <Spinner /> // Or replace with your loading indicator
                ) : null}
                </div>
                {!(dashboardData.isUpdating || fbData.isLoading) && (
                <>
                    <Fb data={fbData} vendorData={vendorData}   offersData={offers}  prevData={prevFbData} effectiveStatus={effectiveStatus} />
                </>
                )}

            <footer className="bg-white py-4">
            <div className="max-w-6xl mx-auto px-6 flex justify-center">
                <p className="text-gray-600">Made by Felix for rybízci at 10FC</p>
            </div>
        </footer>
        </div>
    );
};

export default Together;
