import React, { useState, useEffect, useCallback } from 'react';
import ReportContext from './ReportContext';
import axios from 'axios';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from './Loader';

const calculatePreviousWeek = () => {
    const startOfLastWeek = moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
    const endOfLastWeek = moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
    return { start: startOfLastWeek, end: endOfLastWeek };
};

const ReportProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const startParam = searchParams.get('start');
    const endParam = searchParams.get('end');

    const initialWeek = startParam && endParam ? { start: startParam, end: endParam } : calculatePreviousWeek();
    
    const [week, setWeek] = useState(initialWeek);
    const [selectedEditor, setSelectedEditor] = useState(null);
    const [data, setData] = useState({
        weekStart: '',
        weekEnd: '',
        adsInfoData: [],
        customPeriodData: {},
        decodedInfoData: {},
        summaryTotals: { total_spend: 0, total_revenue: 0, total_roas: 0 },
        totalWinnerCount: 0,
        editorProfiles: []
    });
    
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Use the environment variable
            const response = await axios.get(`${API_BASE_URL}/fetch-week-data?date=${week.start}`);
            const totalWinnerCount = (response.data.adsInfoData || []).reduce((acc, item) => acc + (Number(item.winner_count) || 0), 0);
            
            setData(prevState => ({
                ...prevState,
                ...response.data,
                totalWinnerCount,
            }));
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    }, [week.start]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSetWeek = (newWeek) => {
        setWeek(newWeek);
        navigate(`/report?start=${newWeek.start}&end=${newWeek.end}`);
    };

    if (isLoading) return <Loader />;
    if (error) return <div>Error: {error}</div>;

    return (
        <ReportContext.Provider value={{ data, setWeek: handleSetWeek, selectedEditor, setSelectedEditor, selectedWeek: week.start }}>
            {children}
        </ReportContext.Provider>
    );
};

export default ReportProvider;
