import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Spinner from './spinner';
function EditProfileModal({ profile, setProfiles, onClose }) {
    const [formData, setFormData] = useState({
      name: profile.name || '',
      bio: profile.bio || '',
      image: null,
      image_url: profile.image_url || '',
      facebook_ads_short: profile.facebook_ads_short || ''
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setFormData({
        name: profile.name || '',
        bio: profile.bio || '',
        image: null,
        image_url: profile.image_url || '',
        facebook_ads_short: profile.facebook_ads_short || ''
      });
    }, [profile]);
  
    const handleChange = (e) => {
      const { name, value, files } = e.target;
      if (name === 'image') {
        setFormData({ ...formData, image: files[0] });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const data = new FormData();
      data.append('name', formData.name);
      data.append('bio', formData.bio);
      if (formData.image) {
        data.append('image', formData.image);
      } else {
        data.append('image_url', formData.image_url);
      }
      data.append('facebook_ads_short', formData.facebook_ads_short);

      try {
        const response = await axios.put(`${API_BASE_URL}/editor-profiles/${profile.id}`, data);
        setProfiles(prev => prev.map(p => p.id === profile.id ? response.data : p));
        setLoading(false);
        onClose();
      } catch (error) {
        console.error('Error updating profile:', error);
        setLoading(false);
      }
    };

    if (loading) {
      return <Spinner />;
    }
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <form className="bg-white p-5 rounded-lg" onSubmit={handleSubmit}>
        <h2 className="text-lg font-bold mb-6">Edit Profile</h2>
        <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} className="border p-2 mb-4 w-full" />
        <textarea name="bio" placeholder="Bio" value={formData.bio} onChange={handleChange} className="border p-2 mb-4 w-full"></textarea>
        <input type="file" name="image" onChange={handleChange} className="border p-2 mb-4 w-full" />
        <input type="text" name="facebook_ads_short" placeholder="Facebook Ads Short" value={formData.facebook_ads_short} onChange={handleChange} className="border p-2 mb-4 w-full" />
        <button type="submit" className="bg-blue-500 text-white p-2 rounded">Update Profile</button>
        <button type="button" onClick={onClose} className="bg-red-500 text-white p-2 rounded ml-4">Cancel</button>
      </form>
    </div>
  );
}

export default EditProfileModal;
